/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const MobiLogoIt = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--mobi-logo_it ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 144 22"
    {...props}
  >
    <title>{'mobiliar-it copy'}</title>
    <desc>{'Created with Sketch.'}</desc>
    <g
      id="mobiliar-it-copy"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M125.507764,22 L121,22 L121,7.29079477 L124.658385,7.29079477 L125.294513,8.73101483 C125.875776,7.77070439 126.895445,7 129.254141,7 L130,7 L129.431159,10.6393555 C129.431159,10.6393555 128.881988,10.5951469 128.50207,10.5951469 C127.161491,10.5951469 126.158903,11.2990471 125.507764,12.3851066 L125.507764,22"
        id="mobi-i-Fill-1"
        fill="#DB3642"
      />
      <path
        d="M57.9984606,22 C52.832356,22 51,17.698645 51,14.0145385 C51,8.10629683 54.6149392,6 57.9984606,6 C61.3840346,6 65,8.10629683 65,14.0145385 C65,19.9018651 61.3840346,22 57.9984606,22 M57.9984606,9.9019289 C56.4272834,9.9019289 55.5611347,11.3613901 55.5611347,14.0145385 C55.5611347,16.6488124 56.4272834,18.0985812 57.9984606,18.0985812 C59.5958071,18.0985812 60.437839,16.6855412 60.437839,14.0145385 C60.437839,11.3246612 59.5958071,9.9019289 57.9984606,9.9019289"
        id="mobi-i-Fill-2"
        fill="#DB3642"
      />
      <path
        d="M72.6122263,22 C69.4053102,22 67.7789561,20.8430904 67.0846906,20.3488618 L67,20.2896758 L67,0.60400092 L71.2891826,0 L71.2891826,7.61375029 C71.9184531,6.9525868 73.047004,6.13359393 74.7959624,6.13359393 C78.1023407,6.13359393 80,8.75902506 80,13.3340538 C80,19.0831915 77.5129536,22 72.6122263,22 M71.2891826,17.8579903 C71.6018483,18.0461715 72.0425347,18.2196827 72.8431558,18.2196827 C74.0352246,18.2196827 75.6221877,17.770476 75.6221877,13.8727983 C75.6221877,11.2676017 74.8461859,10.0029432 73.2528218,10.0029432 C72.511287,10.0029432 71.8509961,10.322143 71.2891826,10.9559899 L71.2891826,17.8579903"
        id="mobi-i-Fill-3"
        fill="#DB3642"
      />
      <path
        d="M83.0494994,22 L87.9493756,22 L87.9493756,6.86870218 L83.0494994,6.86870218 L83.0494994,22 Z M85.4997188,5.48830663 C83.9348633,5.48830663 83,4.67027573 83,3.29948029 L83,3.16103652 C83,1.78771474 83.9095511,1 85.4997188,1 C87.0870739,1 88,1.78771474 88,3.16103652 L88,3.29948029 C88,4.67027573 87.0640117,5.48830663 85.4997188,5.48830663 L85.4997188,5.48830663 Z"
        id="mobi-i-Fill-4"
        fill="#DB3642"
      />
      <polyline
        id="mobi-i-Fill-5"
        fill="#DB3642"
        points="91 22 95 22 95 0 91 0.625867832 91 22"
      />
      <path
        d="M98.0517494,22 L102.950501,22 L102.950501,6.86870218 L98.0517494,6.86870218 L98.0517494,22 Z M100.499156,5.48830663 C98.9359883,5.48830663 98,4.67027573 98,3.29948029 L98,3.16103652 C98,1.78771474 98.9118011,1 100.499156,1 C102.087074,1 103,1.78771474 103,3.16103652 L103,3.29948029 C103,4.67027573 102.066262,5.48830663 100.499156,5.48830663 L100.499156,5.48830663 Z"
        id="mobi-i-Fill-6"
        fill="#DB3642"
      />
      <path
        d="M117.906971,7.22939582 C117.286618,6.89271481 115.636586,6 112.778652,6 C107.834432,6 105,8.99697115 105,14.2257293 C105,19.2381954 107.000603,22 110.635569,22 C111.822907,22 113.11992,21.6235294 113.802456,20.7185717 C113.899401,20.5900207 113.959625,20.474733 113.959625,20.474733 L114.424278,21.6281205 L118,21.6281205 L118,7.27938785 L117.906971,7.22939582 Z M113.715792,17.2364738 C113.28933,17.8073011 112.652329,18.0985812 111.822907,18.0985812 C110.671801,18.0985812 109.351286,17.6399809 109.351286,14.0742229 C109.351286,11.2659971 110.356973,9.84020405 112.344356,9.84020405 C112.906444,9.84020405 113.336823,9.91366172 113.715792,10.0743504 L113.715792,17.2364738 L113.715792,17.2364738 Z"
        id="mobi-i-Fill-7"
        fill="#DB3642"
      />
      <path
        d="M136.946685,9.75369709 C138.356051,9.75369709 139.233912,10.5237124 139.371185,12.3691994 L134.283789,12.3691994 C134.510948,10.4482407 135.535853,9.75369709 136.946685,9.75369709 Z M140.67552,17.3850076 C139.864585,17.9612443 138.739535,18.2534421 137.675061,18.2534421 C135.70146,18.2534421 134.481149,17.4793473 134.303818,15.27231 L142.971053,15.27231 C142.971053,15.27231 142.991082,14.0591535 142.997921,13.654768 C143.082923,8.57572667 140.553392,6 136.848493,6 C132.47384,6 130,9.75573687 130,13.7756247 C130,18.5568587 132.519761,22 137.253961,22 C140.13278,22 141.819623,20.8954615 142.703835,20.0040796 L140.812793,17.2600714 L140.67552,17.3850076 L140.67552,17.3850076 Z"
        id="mobi-i-Fill-9"
        fill="#DB3642"
      />
      <polyline
        id="mobi-i-Fill-10"
        fill="#DB3642"
        points="43.0638158 1 38.0151738 14.7750463 32.9928993 1 28.3442482 1 27 22 31.3615929 22 32.022772 10.611214 36.3505348 22 39.6494652 22 43.9468804 10.611214 44.6080595 22 49 22 47.6557518 1 43.0638158 1"
      />
      <path
        d="M2.63106886,19.1892832 L4.6972494,0.143455282 L4.6330995,0.12684467 C4.40234447,0.063925687 3.74700018,0 3.44424958,0 C2.20463356,0 1.22115562,0.393117807 0.595809489,0.739423891 C0.318903452,1.04244171 0.0595347979,2.05367561 0.0595347979,2.38437779 L0.0595347979,2.47447777 L2.10310135,2.22682865 L0,19.6146155 C0,20.1859199 0.474893853,21.2872538 1.01670666,21.9713089 L1.03932066,22 L1.0734724,22 C1.92449695,22 3.5873177,21.6486604 4.49510799,21.2781934 L4.51956803,21.2686297 L4.5320288,21.2449722 C4.76832195,20.8271902 5,19.9422976 5,19.4585764 L5,19.3689798 L2.74552335,19.6216624 C2.6924497,19.4812273 2.63199188,19.2431418 2.63106886,19.1892832"
        id="mobi-i-Fill-11"
        fill="#DB3642"
      />
      <path
        d="M12.4772569,19.6983169 C10.7874867,19.6983169 9.89462303,18.4016513 9.89462303,15.9475389 C9.89462303,14.171229 10.312319,11.688155 11.2477733,10.1460781 C12.140637,8.69647507 13.5604955,7.83016831 15.0470623,7.83016831 C15.7705897,7.83016831 16.537221,7.99885678 17.0205989,8.26255954 L15.7356962,17.4489679 C14.9998534,18.8152429 13.7200821,19.6983169 12.4772569,19.6983169 Z M18.497416,19.5992379 C18.4373786,19.4188631 18.3665653,19.0972372 18.365539,19.0377898 L19.8957226,7.45773261 L19.8546714,7.42978723 C19.0921453,6.89628453 17.4562548,6 15.078877,6 C12.0585346,6 10.1650478,7.70974913 9.11003189,9.14563353 C7.7686838,10.99765 7,13.6031756 7,16.2935535 C7,21.2576691 9.61701426,22 11.1764469,22 C12.5573067,22 14.4646483,21.3877421 15.6925925,19.6749444 L15.7392882,19.6749444 C15.8747572,20.4172753 16.2154822,21.3648777 16.5613386,21.958336 L16.5864824,22 L16.6336913,22 C17.4993586,22 19.4657112,21.6565259 20.4411905,21.2703715 L20.4658212,21.2617339 L20.4812154,21.2378533 C20.7377854,20.8227374 21,19.9289933 21,19.4661162 L21,19.3771991 L18.497416,19.5992379 L18.497416,19.5992379 Z"
        id="mobi-i-Fill-13"
        fill="#DB3642"
      />
    </g>
  </svg>
);

MobiLogoIt.displayName = 'MobiLogoIt';

export default MobiLogoIt;
