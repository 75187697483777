import { ofType } from 'redux-observable';
import { map, delay, mergeMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  SHOW_LOADING_SCREEN,
  SHOW_LOADING_SCREEN_AUTO_HIDE,
  ALLOW_HIDING,
  HIDE_LOADING_SCREEN_DELAYED,
  allowHiding,
  startHideLoadingScreen,
} from './actions';

export const showLoadingScreenEpic = (action$) =>
  action$.pipe(
    ofType(SHOW_LOADING_SCREEN_AUTO_HIDE),
    mergeMap(({ disallowanceTimeout, timeout }) =>
      of({}).pipe(
        delay(disallowanceTimeout),
        map(() => allowHiding(timeout)),
      ),
    ),
  );

export const allowHidingEpic = (action$) =>
  action$.pipe(
    ofType(ALLOW_HIDING),
    mergeMap(({ timeout }) =>
      of({}).pipe(
        delay(timeout),
        map(() => startHideLoadingScreen()),
      ),
    ),
  );

export const hideDelayedEpic = (action$) =>
  action$.pipe(
    ofType(HIDE_LOADING_SCREEN_DELAYED),
    mergeMap(({ delayTime }) =>
      of({}).pipe(
        delay(delayTime),
        map(() => startHideLoadingScreen()),
        takeUntil(
          action$.pipe(
            ofType(SHOW_LOADING_SCREEN, SHOW_LOADING_SCREEN_AUTO_HIDE),
          ),
        ),
      ),
    ),
  );
