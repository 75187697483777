import {
  SELECT_PREMIUM_ID,
  DESELECT_PREMIUM_ID,
  CLEAR_SELECTED_PREMIUM_IDS,
} from './actions';

export interface Premium {
  selectedPremiumIds: number[];
}

const initialState = {
  selectedPremiumIds: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PREMIUM_ID: {
      return {
        selectedPremiumIds: [...state.selectedPremiumIds, action.premiumId],
      };
    }
    case DESELECT_PREMIUM_ID: {
      return {
        selectedPremiumIds: state.selectedPremiumIds.filter(
          (id) => id !== action.premiumId,
        ),
      };
    }
    case CLEAR_SELECTED_PREMIUM_IDS: {
      return {
        selectedPremiumIds: [],
      };
    }
    default:
      return state;
  }
};

export default reducer;
