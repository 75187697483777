import { castArray } from '@scout24ch/fs24-utils';
import { PARAM_IDS, PARAM_USER_ID } from 'utils/queryparam-const';
import { IS_AUTO_INSURANCE } from 'utils/const';

export const getQueryParams = (router) => router.query || {};

export const getParamFromQuery = (
  query: Record<string, any>,
  queryKey: keyof typeof query,
) => {
  if (queryKey === PARAM_IDS && String(query[queryKey]).includes('/')) {
    return query[queryKey].split('/');
  }
  return query[queryKey];
};

export const parseIntArrayFromParam = (param) => {
  if (param) {
    return castArray(param).map((i) => parseInt(i, 10));
  }
  return param;
};

export const getQueryParam = (router, queryKey) => {
  // Newer versions of next.js return null for router on server side.
  if (!router) {
    return '';
  }
  const queryParams = getQueryParams(router);

  return getParamFromQuery(queryParams, queryKey);
};

export const getAutoScout24UserIdQueryParam = (router) => {
  const userId = getQueryParam(router, PARAM_USER_ID);
  if (userId) {
    const userIdAsNumber = Number(userId);
    if (Number.isNaN(userIdAsNumber)) {
      return null;
    }

    return userIdAsNumber;
  }

  return null;
};

export const languageToPortalLandingPageMapping = IS_AUTO_INSURANCE
  ? {
      de: '/de/autoversicherung',
      fr: '/fr/assurance-voiture',
      it: '/it/assicurazione-auto',
    }
  : {
      de: '/de/motorradversicherung',
      fr: '/fr/assurance-moto',
      it: '/it/assicurazione-moto',
    };
