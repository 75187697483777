import { getValidDateOrTomorrow } from 'utils/dateUtils';
import {
  SAVE_INQUIRY,
  SAVE_INQUIRY_FULFILLED,
  SAVE_INQUIRY_FAILED,
  FETCH_INQUIRY_FULFILLED,
  FETCH_INQUIRY_FAILED,
  FETCH_PREVIOUS_INQUIRY_FULFILLED,
  FETCH_PREVIOUS_INQUIRY,
  FETCH_PREVIOUS_INQUIRY_ABORTED,
} from './actions';

export const getInquiryPayload = ({ payload }) => {
  const inquiry = payload;

  inquiry.insuranceOptions.insuranceStartDate = getValidDateOrTomorrow(
    inquiry.insuranceOptions.insuranceStartDate,
  );
  return {
    fetchingInsuranceInquiry: false,
    fetchingInsuranceInquiryFailed: false,
    insuranceInquiry: inquiry,
    error: null,
    fetchPreviousInquiry: false,
    skipNextPageView: false,
  };
};

export interface SaveInsuranceInquiryState {
  savingInsuranceInquiry: boolean;
  savingInsuranceInquiryFailed: boolean;
  insuranceInquiryId: string | null;
  error: unknown | null;
}

const initialSaveInsuranceInquiryState: SaveInsuranceInquiryState = {
  savingInsuranceInquiry: false,
  savingInsuranceInquiryFailed: false,
  insuranceInquiryId: null,
  error: null,
};

export const saveInsuranceInquiryReducer = (
  state: SaveInsuranceInquiryState = initialSaveInsuranceInquiryState,
  action,
): SaveInsuranceInquiryState => {
  switch (action.type) {
    case SAVE_INQUIRY:
      return {
        savingInsuranceInquiry: true,
        savingInsuranceInquiryFailed: false,
        insuranceInquiryId: null,
        error: null,
      };
    case SAVE_INQUIRY_FULFILLED:
      return {
        savingInsuranceInquiry: false,
        savingInsuranceInquiryFailed: false,
        insuranceInquiryId: action.payload,
        error: null,
      };
    case SAVE_INQUIRY_FAILED:
      return {
        savingInsuranceInquiry: false,
        savingInsuranceInquiryFailed: true,
        insuranceInquiryId: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export interface InsuranceInquiry {
  key: string;
  insuranceOptions?: {
    insuranceStartDate?: string;
  };
}

export interface FetchInsuranceInquiryState {
  fetchingInsuranceInquiry: boolean;
  fetchingInsuranceInquiryFailed: boolean;
  insuranceInquiry: InsuranceInquiry | null;
  error: unknown | null;
  fetchPreviousInquiry: boolean;
  skipNextPageView: boolean;
}

const initialFetchInsuranceInquiryState: FetchInsuranceInquiryState = {
  fetchingInsuranceInquiry: false,
  fetchingInsuranceInquiryFailed: false,
  insuranceInquiry: null,
  error: null,
  fetchPreviousInquiry: false,
  skipNextPageView: false,
};

export const fetchInsuranceInquiryReducer = (
  state: FetchInsuranceInquiryState = initialFetchInsuranceInquiryState,
  action,
): FetchInsuranceInquiryState => {
  switch (action.type) {
    case FETCH_PREVIOUS_INQUIRY:
      return {
        fetchingInsuranceInquiry: true,
        fetchingInsuranceInquiryFailed: false,
        insuranceInquiry: null,
        error: null,
        fetchPreviousInquiry: true,
        skipNextPageView: false,
      };
    case FETCH_INQUIRY_FULFILLED: {
      return getInquiryPayload(action);
    }
    case FETCH_INQUIRY_FAILED:
      return {
        fetchingInsuranceInquiry: false,
        fetchingInsuranceInquiryFailed: true,
        insuranceInquiry: null,
        error: action.payload,
        fetchPreviousInquiry: false,
        skipNextPageView: false,
      };
    case FETCH_PREVIOUS_INQUIRY_FULFILLED:
      return {
        ...getInquiryPayload(action),
        fetchPreviousInquiry: true,
      };
    case FETCH_PREVIOUS_INQUIRY_ABORTED:
      return initialFetchInsuranceInquiryState;
    case SAVE_INQUIRY_FULFILLED:
      return {
        ...state,
        fetchPreviousInquiry: false,
        skipNextPageView: false,
      };
    default:
      return state;
  }
};
