import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { showAlert } from 'components/Alert/state/actions';
import { SUCCESS, ERROR } from 'components/Alert/state/types';
import { NS_PREMIUM_COMMON } from 'utils/namespace-const';
import AlertMessage from 'components/Alert/AlertMessage';
import { NoResultsSendEmailAction, SUBSCRIBE } from './actions';
import { subscribe } from '../../../api/insuranceInquiries';

const ALERT_SUCCESS = AlertMessage.createTranslatedMessage(
  `${NS_PREMIUM_COMMON}.alert.success-subscribe`,
);
const ALERT_ERROR = AlertMessage.createTranslatedMessage(
  `${NS_PREMIUM_COMMON}.alert.error`,
);

const onSucceeded = (email) =>
  showAlert(SUCCESS, ALERT_SUCCESS, AlertMessage.createEmailMessage(email));

const onFailed = () => showAlert(ERROR, ALERT_ERROR);

const epic = (action$) =>
  action$.pipe(
    ofType(SUBSCRIBE),
    mergeMap((action: NoResultsSendEmailAction) =>
      from(subscribe(action.inquiryKey, action.email)).pipe(
        map(() => onSucceeded(action.email)),
        catchError(() => of(onFailed())),
      ),
    ),
  );

export default epic;
