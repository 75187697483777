import {
  SHOW_LOADING_SCREEN,
  SHOW_LOADING_SCREEN_AUTO_HIDE,
  ALLOW_HIDING,
  HIDE_LOADING_SCREEN,
} from './actions';

export interface LoadingScreenState {
  loadingScreenVisible?: boolean;
  canHide?: boolean;
  loadingScreenText?: string;
  isLogoLoader?: boolean;
}

const initialState = {
  loadingScreenVisible: false,
  canHide: true,
  loadingScreenText: '',
  isLogoLoader: false,
};

const reducer = (state = initialState, action): LoadingScreenState => {
  switch (action.type) {
    case SHOW_LOADING_SCREEN:
      return {
        ...state,
        loadingScreenVisible: true,
        canHide: true,
        loadingScreenText: action.text,
        isLogoLoader: action.isLogoLoader,
      };
    case SHOW_LOADING_SCREEN_AUTO_HIDE:
      return {
        ...state,
        loadingScreenVisible: true,
        canHide: false,
        loadingScreenText: action.text,
      };
    case ALLOW_HIDING:
      return {
        ...state,
        canHide: true,
      };
    case HIDE_LOADING_SCREEN:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
