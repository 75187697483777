export const ENTER_FULLSCREEN = 'ENTER_FULLSCREEN';
export const LEAVE_FULLSCREEN = 'LEAVE_FULLSCREEN';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const enterFullscreen = () => ({
  type: ENTER_FULLSCREEN,
});

export const leaveFullscreen = () => ({
  type: LEAVE_FULLSCREEN,
});

export const openModal = () => ({
  type: OPEN_MODAL,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
