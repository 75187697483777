export const ID_FIRST_REGISTRATION_MONTH = 'firstRegistrationMonth';
export const ID_FIRST_REGISTRATION_YEAR = 'firstRegistrationYear';

export const ID_LEASING = 'leasing';
export const ID_KM_PER_YEAR = 'kilometerPerYear';
export const ID_USAGE = 'usage';
export const ID_REGISTRATION_CANTON = 'registrationCanton';
export const ID_PRICE_OF_ACCESSORIES = 'priceOfAccessories';
export const ID_GARAGE = 'garage';
export const ID_VEHICLE_SEARCH_TEXT = 'vehicleSearchText';

export const ID_SEARCH_TYPE = 'searchType';
export const ID_SEARCH_TYPE_FLOW = 'searchTypeFlow';
export const ID_TYPE_CERTIFICATE = 'typeCertificate';
export const ID_LICENSE_PLATE_NUMBER = 'licensePlateNumber';

export const ID_PRIVATE_USAGE = 'privateUsage';
export const ID_COMMUTE_USAGE = 'commuteUsage';
export const ID_BUSINESS_USAGE = 'businessUsage';

export const ID_GARAGE_NONE = 'none';
export const ID_GARAGE_AT_HOME = 'garageAtHome';
export const ID_GARAGE_AT_WORK = 'garageAtWork';
export const ID_GARAGE_AT_HOME_AND_WORK = 'home-and-work';

export const VEHICLE_SELECTION = 'step:vehicle-selection';
export const COMMON = 'common:common';

export const ID_MAKE = 'make';
export const ID_YEAR_OF_PURCHASE = 'expectedPurchaseYear';
export const LBL_YEAR_OF_PURCHASE = `${VEHICLE_SELECTION}.yearOfPurchase`;
export const LBL_FIRST_REGISTRATION = `${VEHICLE_SELECTION}.firstRegistration`;
export const CHOOSE = `${COMMON}.choose`;
export const CHOOSE_SHORT = `${COMMON}.choose-short`;
