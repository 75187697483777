import axios, { AxiosRequestConfig } from 'axios';
import { APPLICATION_HOST } from '../../utils/const';
import { cloudFlareInterceptor } from '../utils/cloudFlareInterceptor';

export const apiBaseURL =
  process.env.NODE_ENV === 'production'
    ? `${APPLICATION_HOST ?? ''}/api/vehicle-search-service`
    : process.env.VEHICLE_SEARCH_SERVICE_API_URL;

const config: AxiosRequestConfig = {
  baseURL: apiBaseURL,
};

if (process.env.NODE_ENV === 'production') {
  config.withCredentials = true;
}

export const vehicleSearchService = axios.create(config);

vehicleSearchService.interceptors.request.use(cloudFlareInterceptor);
