import axios, { AxiosResponse } from 'axios';
import { APPLICATION_HOST } from '../../utils/const';
import { cloudFlareInterceptor } from '../utils/cloudFlareInterceptor';

export interface EmailValidation {
  isValid: boolean;
}

export const apiBaseURL =
  process.env.NODE_ENV === 'production'
    ? `${APPLICATION_HOST}/api/validation-service/api/v1`
    : process.env.VALIDATION_SERVICE_URL;

export const validationService = axios.create({
  baseURL: apiBaseURL,
});

validationService.interceptors.request.use(cloudFlareInterceptor);

/**
 * Retrieve city details for the given postal code.
 */
export const callEmailValidation = async (
  email: string,
): Promise<EmailValidation> => {
  const response: AxiosResponse = await validationService.post<EmailValidation>(
    '/emails',
    { email },
  );

  return response.data;
};
