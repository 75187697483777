import moment from 'moment';
import { validateDateIsInFuture } from '@scout24ch/fs24-forms';
import {
  convertDateValueToString,
  isDateValid,
} from '../../../../../utils/dateformat-const';
import { ValidationError } from '../../../../../utils/validation/types';

export const validateStartInsuranceDate = (
  value: string | Date,
): ValidationError[] => {
  if (!isDateValid(convertDateValueToString(value))) {
    return [{ type: 'insuranceStartDate-invalid' }];
  }

  const todayMoreHalfYear = moment().add(180, 'd').toDate();
  if (value > todayMoreHalfYear) {
    return [{ type: 'insuranceStartDate-too-big' }];
  }

  if (validateDateIsInFuture(convertDateValueToString(value)) !== undefined) {
    return [{ type: 'insuranceStartDate-too-small' }];
  }

  return [];
};
