import { SHOW_QUICK_ADJUST, HIDE_QUICK_ADJUST } from './actions';

export interface QuickAdjustState {
  show: boolean;
  inquiry: any;
}

const initialState = {
  show: false,
  inquiry: null,
};

const quickAdjustActionsReducer = (
  state = initialState,
  action,
): QuickAdjustState => {
  switch (action.type) {
    case SHOW_QUICK_ADJUST:
      return {
        show: true,
        inquiry: action.inquiry,
      };
    case HIDE_QUICK_ADJUST:
      return initialState;
    default:
      return state;
  }
};

export default quickAdjustActionsReducer;
