import { combineEpics } from 'redux-observable';
import {
  changingInputEpic,
  revalidateInputEpic,
  createLongInquiryEpic,
  requestChangeStepEpic,
  changeStepWithValidationEpic,
  saveLongInquiryEpic,
} from 'state/InquiryInput/epic';
import { fetchVehicleMakesEpic } from 'state/VehicleMakes/epic';
import {
  sendOfferRequestEpic,
  sendOfferRequestFailedEpic,
  sendOfferRequestFulfilledEpic,
  trySendOfferRequestEpic,
  changingOfferInputEpic,
  revalidateOfferInputEpic,
} from 'state/RequestOffer/epic';
import loadInsurancesEpic from 'state/InsuranceProviders/epic';
import inquirySubscriptionEpic from 'components/NoResultsSendMail/state/epic';
import {
  fetchInquiryFulfilledEpic,
  fetchPreviousInsuranceInquiryEpic,
  saveInsuranceInquiryEpic,
} from 'state/InsuranceInquiry/epic';
import {
  sharePremiumResultsEpic,
  sharePremiumDetailsEpic,
  sendEmailFulFilledEpic,
  sendEmailFailedEpic,
} from 'components/ShareButton/state/Email/epic';
import { PremiumLoaderEpics } from 'components/PremiumLoader/state/epic';
import {
  allowHidingEpic,
  hideDelayedEpic,
  showLoadingScreenEpic,
} from './LoadingScreen/epic';

export default combineEpics(
  saveInsuranceInquiryEpic,
  fetchPreviousInsuranceInquiryEpic,
  sendOfferRequestEpic,
  sendOfferRequestFulfilledEpic,
  trySendOfferRequestEpic,
  revalidateOfferInputEpic,
  changingOfferInputEpic,
  sendOfferRequestFailedEpic,
  showLoadingScreenEpic,
  allowHidingEpic,
  hideDelayedEpic,
  PremiumLoaderEpics.pollPremiumsEpic,
  PremiumLoaderEpics.stopLoadingPremiumsDelayedEpic,
  sharePremiumResultsEpic,
  sharePremiumDetailsEpic,
  sendEmailFulFilledEpic,
  sendEmailFailedEpic,
  loadInsurancesEpic,
  fetchVehicleMakesEpic,
  fetchInquiryFulfilledEpic,
  changingInputEpic,
  revalidateInputEpic,
  createLongInquiryEpic,
  requestChangeStepEpic,
  changeStepWithValidationEpic,
  saveLongInquiryEpic,
  inquirySubscriptionEpic,
);
