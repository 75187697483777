import { HIDE_ALERT } from 'components/Alert/state/actions';
import {
  SHARE_PREMIUM_RESULTS,
  SEND_EMAIL_FULFILLED,
  SEND_EMAIL_FAILED,
  SHARE_PREMIUM_DETAILS,
} from './actions';

export interface EmailState {
  sendingEmail: boolean;
  sendingEmailFailed: boolean;
  email: string | null;
  error: unknown | null;
}

const initialState: EmailState = {
  sendingEmail: false,
  sendingEmailFailed: false,
  email: null,
  error: null,
};

const reducer = (state: EmailState = initialState, action): EmailState => {
  switch (action.type) {
    case SHARE_PREMIUM_RESULTS:
    case SHARE_PREMIUM_DETAILS:
      return {
        sendingEmail: true,
        sendingEmailFailed: false,
        email: null,
        error: null,
      };
    case SEND_EMAIL_FULFILLED:
      return {
        sendingEmail: false,
        sendingEmailFailed: false,
        email: action.payload,
        error: null,
      };
    case SEND_EMAIL_FAILED:
      return {
        sendingEmail: false,
        sendingEmailFailed: true,
        email: null,
        error: action.payload,
      };
    case HIDE_ALERT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
