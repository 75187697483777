import { STORE_ERROR } from './actions';

export interface ErrorState {
  type: unknown | null;
  currentError: unknown | null;
}

const initialState: ErrorState = {
  type: null,
  currentError: null,
};

const errorReducer = (state: ErrorState = initialState, action): ErrorState => {
  switch (action.type) {
    case STORE_ERROR:
      return {
        type: action.type,
        currentError: action.error,
      };
    default:
      return state;
  }
};

export default errorReducer;
