function makePersonalDetails({ postCode, emailAddress }) {
  return {
    name: '',
    firstName: '',
    street: '',
    houseNumber: '',
    city: null,
    privatePhone: '',
    email: typeof emailAddress === 'string' ? emailAddress : '',
    postCode,
  };
}

// eslint-disable-next-line import/prefer-default-export
export { makePersonalDetails };
