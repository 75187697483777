import React from 'react';
import { NextPageContext } from 'next';
import '../polyfills';
import { CookieConsentProvider } from '@scout24ch/fs24-cmp';
import { DesignSystemProvider } from '@scout24ch/fs24-design-system';
import {
  configureWidgets,
  WidgetDataProvider,
  widgetManager,
  WidgetManagerConfig,
  WidgetManagerFactory,
  WidgetNamespaces,
} from '@scout24ch/fs24-widgets';
import { initSplitClient } from '@scout24ch/fs24-split';
import 'moment/locale/de-ch';
import 'moment/locale/en-gb';
import 'moment/locale/fr-ch';
import 'moment/locale/it';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import Scout24App from 'containers/App';
import { fetchLookUpDataFulfilled } from 'state/LookUpData/actions';
import { appWithTranslation } from 'utils/i18n/hocs/app-with-translation';
import { Scout24Request } from 'server/types';
import withReduxStore from 'state/withReduxStore';
import {
  captureException,
  init as initSentry,
  withProfiler,
} from 'utils/sentry';
import { compose } from 'utils/compose';
import { getStaticText, StaticTextKey } from 'utils/Text';
import { fetchLookupData } from 'api/lookups';
import { LanguageCode } from 'utils/language';
import { ErrorBoundary } from 'components/ErrorBounadry';
import { AuthProvider } from 'context/AuthProvider';
import { SplitTracking } from 'components/SplitTracking';
import { GoogleTagManager } from '../components/GoogleTagManager';
import { RootState } from '../state/reducers';
import { LookupData } from '../state/LookUpData/reducer';
import { CMS_API } from './../utils/const';

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../../msw');
}

initSentry();
initSplitClient();

configureWidgets({
  apiBaseURL: CMS_API,
});

export interface MyAppNextContext extends AppContext {
  req: Scout24Request;
  query: { [key: string]: string };
  ctx: NextPageContext & {
    reduxStore: Store<RootState>;
  };
}

function getWidgetData(locale: string): Promise<WidgetManagerFactory> {
  const requiredNamespaces = [WidgetNamespaces.FOOTER, WidgetNamespaces.HEADER];

  // temporary solution because CMS doesn't support English language
  const language = locale === 'en' ? 'de' : locale;
  const options: WidgetManagerConfig = {
    locale: language,
    onAPIError: captureException,
  };

  return widgetManager.fetchData(requiredNamespaces, options);
}

export interface VehicleInsuranceAppProps extends AppProps {
  reduxStore: Store;
  widgetData: WidgetManagerFactory;
  locale: LanguageCode;
  lookupData: LookupData;
}

function VehicleInsuranceApp(props: VehicleInsuranceAppProps) {
  const { Component, pageProps, reduxStore, locale, widgetData } = props;

  const title = getStaticText(StaticTextKey.META_TITLE);

  return (
    <DesignSystemProvider locale={locale as LanguageCode}>
      <ErrorBoundary>
        <Provider store={reduxStore}>
          <AuthProvider>
            <WidgetDataProvider value={widgetData}>
              <CookieConsentProvider>
                <GoogleTagManager>
                  <SplitTracking>
                    <div />
                    <Scout24App>
                      <Head>
                        <meta name="robots" content="noindex" />
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                        />
                        <title>{title}</title>
                      </Head>
                      <Component {...pageProps} />
                    </Scout24App>
                  </SplitTracking>
                </GoogleTagManager>
              </CookieConsentProvider>
            </WidgetDataProvider>
          </AuthProvider>
        </Provider>
      </ErrorBoundary>
    </DesignSystemProvider>
  );
}

VehicleInsuranceApp.getInitialProps = async (appContext: MyAppNextContext) => {
  const { locale = 'de', reduxStore } = appContext.ctx;

  const [appProps, widgetData, lookupData] = await Promise.all([
    App.getInitialProps(appContext),
    getWidgetData(locale),
    fetchLookupData(),
  ]);

  const splitCallPromises = [];

  if (splitCallPromises.length) {
    await Promise.all(splitCallPromises);
  }

  reduxStore.dispatch(fetchLookUpDataFulfilled(lookupData));

  return {
    ...appProps,
    widgetData,
    locale,
    lookupData,
  };
};

export default compose(
  appWithTranslation,
  withProfiler,
  withReduxStore,
)(VehicleInsuranceApp);
