// @ts-ignore
import { Engine } from 'json-rules-engine';

import { isEmpty, isNotDivisible } from 'utils/operators';
import {
  ValidationFailureFunction,
  ValidationSuccessFunction,
} from 'utils/validation/types';
import { UsageDetails } from '../types';
import { validateVehicle } from './VehicleValidationFactory';
import rules from './rules';
import { Vehicle } from '../../../../../state/InquiryInput/types';

const autoValidationEngine = new Engine();
autoValidationEngine.addOperator('empty', isEmpty);
autoValidationEngine.addOperator('notDivisible', isNotDivisible);

autoValidationEngine.addRule(rules.leasingEmpty);
autoValidationEngine.addRule(rules.kmPerYearEmpty);
autoValidationEngine.addRule(rules.mileageEmpty);
autoValidationEngine.addRule(rules.mileageLessThan);
autoValidationEngine.addRule(rules.kmPerYearLessThan);
autoValidationEngine.addRule(rules.kmPerYearNotDivisible);
autoValidationEngine.addRule(rules.usageEmpty);
autoValidationEngine.addRule(rules.registrationCantonEmpty);
autoValidationEngine.addRule(rules.registrationCantonIsNull);
autoValidationEngine.addRule(rules.priceOfAccessoriesEmpty);
autoValidationEngine.addRule(rules.priceOfAccessoriesLessThan);
autoValidationEngine.addRule(rules.garageEmpty);
autoValidationEngine.addRule(rules.firstRegistrationMonthEmpty);
autoValidationEngine.addRule(rules.firstRegistrationYearEmpty);
autoValidationEngine.addRule(rules.yearOfPurchaseEmpty);
autoValidationEngine.addRule(rules.yearOfPurchaseNotSelected);

const validateAutoVehicle = (
  usageDetails: UsageDetails,
  vehicle: Vehicle,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => {
  return validateVehicle(
    autoValidationEngine,
    usageDetails,
    vehicle,
    success,
    failure,
  );
};

export default validateAutoVehicle;
