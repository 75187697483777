export const START_ADJUSTING_DATA = 'START_ADJUSTING_DATA';
export const STOP_ADJUSTING_DATA = 'STOP_ADJUSTING_DATA';

interface StepOverride {
  name: string;
  index: number;
}

export const startAdjustingData = (stepOverride?: StepOverride) => ({
  type: START_ADJUSTING_DATA,
  isAdjustingData: true,
  stepOverride,
});

export const stopAdjustingData = () => ({
  type: STOP_ADJUSTING_DATA,
  isAdjustingData: false,
});
