import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import EvaluatedValidation from 'utils/EvaluatedValidation';

export const useValidation = (
  prefix: string,
  selector: (rootState: RootState) => any,
) => {
  const validation = useSelector(selector);
  return new EvaluatedValidation(validation, prefix);
};
