import {
  ValidationCode,
  ValidationError,
  ValidationResult,
  validateEmail as emailValidator,
} from '@scout24ch/fs24-forms';
import {
  callEmailValidation,
  EmailValidation,
} from '../../api/apiClients/validationService';
import { captureException } from '../sentry';

export const validateEmail = async (
  value: string,
): Promise<ValidationResult | null> => {
  try {
    /** Check email format before calling the service */
    if (!emailValidator(value)) {
      const response: EmailValidation = await callEmailValidation(value);
      if (response?.isValid) {
        return undefined;
      }
    }

    return new ValidationError(ValidationCode.FORMAT_MISMATCH);
  } catch (error) {
    captureException(error);
    return emailValidator(value);
  }
};

export const isEmailInvalid = async (factValue) =>
  (await validateEmail(factValue)) !== undefined;

export const isEmailValid = async (factValue) =>
  (await validateEmail(factValue)) === undefined;
