import React from 'react';
import { withRouter } from 'next/router';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { I18nextProvider, withSSR } from 'react-i18next';
import i18next, { i18n } from 'i18next';
import { getDisplayName } from 'utils/hoc';
import { createInstance } from '../createI18next';
import NextStaticProvider from '../components/NextStaticProvider';
import { captureException } from '../../sentry';
import {
  AppWithTranslationContext,
  AppWithTranslationProps,
  getLanguagesToLoad,
} from './appWithTranslation.utils';
import { ALL_NAMESPACES } from '../defaultConfig';

// This component is copied from https://github.com/isaachinman/next-i18next/blob/master/src/hocs/app-with-translation.tsx
// and slightly customized to Vehicle App.

export interface WrappedComponentProps {
  pageProps: {
    namespacesRequired?: string[];
  };
}

export function appWithTranslation(
  WrappedComponent: React.ComponentType & {
    getInitialProps: (ctx: AppWithTranslationContext) => any;
  },
) {
  const WrappedComponentWithSSR = withSSR()(WrappedComponent);
  const [i18n, config] = createInstance(i18next);

  const clientLoadNamespaces = (lng: string, namespaces: string[]) =>
    Promise.all(
      namespaces
        .filter((ns) => !i18n.hasResourceBundle(lng, ns))
        .map((ns) => i18n.reloadResources(lng, ns)),
    );

  function AppWithTranslation(props: AppWithTranslationProps) {
    const { locale, initialI18nStore, i18nServerInstance } = props;
    const i18nInstance = i18nServerInstance || i18n;
    return (
      <I18nextProvider i18n={i18nInstance}>
        <NextStaticProvider>
          <WrappedComponentWithSSR
            {...props}
            initialLanguage={locale}
            initialI18nStore={initialI18nStore}
          />
        </NextStaticProvider>
      </I18nextProvider>
    );
  }

  AppWithTranslation.getInitialProps = async (
    appContext: AppWithTranslationContext,
  ) => {
    let wrappedComponentProps: WrappedComponentProps = {
      pageProps: {},
    };

    if (WrappedComponent.getInitialProps) {
      wrappedComponentProps =
        await WrappedComponent.getInitialProps(appContext);
    }
    if (typeof wrappedComponentProps.pageProps === 'undefined') {
      captureException(
        new Error(
          'If you have a getInitialProps method in your custom _app.js file, you must explicitly return pageProps. For more information, see: https://github.com/zeit/next.js#custom-app',
        ),
      );
    }

    const { req, locale = 'de' } = appContext.ctx;
    let initialI18nStore: Record<string, any> = {};
    let i18nServerInstance: i18n | null = null;

    const namespacesRequired = ALL_NAMESPACES;

    // Perform data fetching, depending on environment
    if (req?.i18n) {
      const { fallbackLng } = config;

      // Initialise the store with only the initialLanguage and
      // necessary namespaces needed to render this specific tree
      const languagesToLoad = getLanguagesToLoad(
        locale,
        fallbackLng,
        (config as any).otherLanguages,
      );

      languagesToLoad.forEach((lng) => {
        initialI18nStore[lng] = {};
        namespacesRequired.forEach((ns) => {
          initialI18nStore[lng][ns] =
            ((req.i18n.services.resourceStore.data as any)[lng] || {})[ns] ||
            {};
        });
      });
    } else if (Array.isArray(i18n.languages) && i18n.languages.length > 0) {
      // Load newly-required translations if changing route client-side
      await clientLoadNamespaces(i18n.languages[0], namespacesRequired);

      initialI18nStore = (i18n as any).store.data;
    }

    // Step 3: Overwrite i18n.toJSON method to be able to serialize the instance
    if (req?.i18n) {
      // @ts-ignore
      req.i18n.toJSON = () => null;
      i18nServerInstance = req.i18n;
    }

    // `pageProps` will get serialized automatically by NextJs
    return {
      locale,
      initialI18nStore,
      i18nServerInstance,
      ...wrappedComponentProps,
    };
  };

  AppWithTranslation.displayName = `appWithTranslation(${getDisplayName(
    WrappedComponent,
  )})`;

  return hoistNonReactStatics(
    withRouter(AppWithTranslation),
    WrappedComponent,
    { getInitialProps: true },
  );
}
