import {
  Flex,
  fs24,
  keyframes,
  Text,
  usePrefersReducedMotion,
} from '@scout24ch/fs24-design-system';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';
import { InsuranceCompanies, MotoInsuranceCompanies } from 'types/insurances';
import { getVehicleData } from 'state/selectors';
import LogoRenderer from '../LogoRenderer';
import { getLng } from '../../utils/i18n';
import { IS_AUTO_INSURANCE } from '../../utils/const';

const logoList = IS_AUTO_INSURANCE
  ? Object.values(InsuranceCompanies)
  : Object.values(MotoInsuranceCompanies);

const slideInFromRight = keyframes`
    0% {
      transform: translateX(2000px) scaleX(2.5) scaleY(0.2);
      transform-origin: 0% 50%;
      filter: blur(10px);
      opacity: 0;
    }
    10% {
      transform: translateX(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
    20% {
      transform: translateX(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
    20% {
      transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
      filter: blur(10px);
      opacity: 0;
    }
  `;

export function LogoLoader(): React.ReactElement {
  const { t, i18n } = useTranslation();
  const { makeName } = useSelector(getVehicleData) ?? {};

  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${slideInFromRight} infinite`;

  return (
    <Flex
      id="logos-loader"
      alignItems="center"
      flexDirection="column"
      position="fixed"
      top={18}
      right={0}
      bottom={0}
      left={0}
      w="full"
      h="full"
      zIndex="modal"
      bg="white"
      px={[5, null, 0]}
    >
      <Text
        fontSize="xl"
        mt={[16, null, 32]}
        mb={16}
        maxW="container.sm"
        textAlign="center"
        sx={{
          hyphens: 'none',
        }}
      >
        {IS_AUTO_INSURANCE
          ? t(`${NS_STEP_INCIDENTS}.savingTitle`)
          : t(`${NS_STEP_INCIDENTS}.savingTitleMoto`)}
        <br />
        {makeName}
      </Text>

      <Flex flexDirection="column" alignItems="center">
        {logoList.map((logo, index) => (
          <fs24.div
            key={index}
            position="absolute"
            opacity="0"
            animation={animation}
            style={{
              animationDelay: `${index}s`,
              animationDuration: `${logoList.length}s`,
            }}
          >
            <LogoRenderer
              insurance={logo}
              svgWidth="123px"
              svgHeight="82px"
              language={getLng(i18n)}
            />
          </fs24.div>
        ))}
      </Flex>
    </Flex>
  );
}
