/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppContext } from 'next/app';
import React from 'react';
import { createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { createEpicMiddleware } from 'redux-observable';
import rootReducer from './reducers';
import rootEpic from './epics';

const isServer = typeof window === 'undefined';
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

export function initializeStore(
  state?: any,
  middleware = createEpicMiddleware(),
): Store {
  const store = createStore(
    rootReducer,
    state,
    composeWithDevTools(applyMiddleware(middleware)),
  );
  middleware.run(rootEpic);
  return store;
}

function getOrCreateStore(initialState?: any): Store {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  // @ts-ignore
  if (!window[__NEXT_REDUX_STORE__]) {
    // @ts-ignore
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  }
  // @ts-ignore
  return window[__NEXT_REDUX_STORE__];
}

interface AppProps {
  initialReduxState: any;
}

const withReduxStore = (MyApp: any) =>
  class AppWithRedux extends React.Component<AppProps, {}> {
    static async getInitialProps(appContext: AppContext): Promise<object> {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore();

      // Provide the store to getInitialProps of pages
      // @ts-ignore
      appContext.ctx.reduxStore = reduxStore; // eslint-disable-line

      let appProps = {};
      if (typeof MyApp.getInitialProps === 'function') {
        appProps = await MyApp.getInitialProps(appContext);
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      };
    }

    constructor(props: AppProps) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);
    }

    private reduxStore: Store;

    render() {
      return <MyApp {...this.props} reduxStore={this.reduxStore} />;
    }
  };

export default withReduxStore;
