import { NextRouter } from 'next/router';
import {
  PARAM_ADJUST,
  PARAM_DIRECT_DEAL,
  PARAM_ID,
  PARAM_IDS,
  PARAM_MAKE,
  PARAM_STEP,
} from 'utils/queryparam-const';
import { CMS_URL } from 'utils/const';
import { languageToPortalLandingPageMapping } from 'utils/routerUtil';
import { STEP_VEHICLE } from 'utils/namespace-const';
import { getGlobal } from 'utils/browserUtil';
import { STEP_PERSONAL_DATA } from 'containers/DirectDealPage/const';
import { PARAM_INQUIRY_KEY } from './queryparam-const';

const INQUIRY_ROUTE = 'inquiry';

export const scrollToTop = (): void => {
  if (typeof window !== 'undefined') {
    getGlobal().scrollTo(0, 0);
  }
};

export const navigateToRoute = (
  pathname: string,
  router: NextRouter,
  query = {},
): Promise<boolean> => {
  return router.push({
    pathname,
    query,
  });
};

export const goToInquiry = (
  router: NextRouter,
  step = STEP_VEHICLE.name,
): void => {
  const queryParams = { [PARAM_STEP]: step };
  navigateToRoute(`/${INQUIRY_ROUTE}`, router, queryParams);
};

export const goToError = (router: NextRouter): Promise<boolean> =>
  navigateToRoute('/error', router);

export const goToInquiryFirstStep = (
  router: NextRouter,
  vehicle?: { makeName: string },
): void => {
  const queryParams: Record<string, string> = {};

  if (vehicle) {
    queryParams[PARAM_MAKE] = vehicle.makeName;
  }

  queryParams[PARAM_STEP] = STEP_VEHICLE.name;
  navigateToRoute(`/${INQUIRY_ROUTE}`, router, queryParams);
};

export const goToPremiums = (inquiryId: string, router: NextRouter): void => {
  navigateToRoute('/premiums/[inquiryKey]', router, {
    [PARAM_INQUIRY_KEY]: inquiryId,
  });
};

export const goToPremiumDetail = (
  inquiryId: string,
  router: NextRouter,
  premiumIds: Array<string | number> = [],
  isDirectDeal = false,
  isCompare = false,
): void => {
  const params: any = {
    [PARAM_INQUIRY_KEY]: inquiryId,
    [PARAM_IDS]: premiumIds,
    [PARAM_DIRECT_DEAL]: isDirectDeal ? 1 : 0,
  };

  if (isCompare) {
    params.isCompare = 'true';
  }

  navigateToRoute('/premiums/[inquiryKey]/details', router, params);
};

export const goToOfferRequest = (
  inquiryId: string,
  premiumId: string | number,
  router: NextRouter,
  adjust = false,
): void => {
  const params = {
    [PARAM_INQUIRY_KEY]: inquiryId,
    [PARAM_ID]: premiumId,
  };
  if (adjust) {
    params[PARAM_ADJUST] = 1;
  }
  navigateToRoute('/premiums/[inquiryKey]/offer', router, params);
};

export const goToDirectDeal = (
  inquiryId: string,
  bundleId: string | number,
  router: NextRouter,
  bundleIds = null,
  step = STEP_PERSONAL_DATA.name,
): void => {
  navigateToRoute('/premiums/[inquiryKey]/direct-deal/[step]', router, {
    [PARAM_INQUIRY_KEY]: inquiryId,
    [PARAM_ID]: bundleId,
    [PARAM_STEP]: step,
    [PARAM_IDS]: bundleIds,
  });
};

export const goToOfferConfirmation = (
  inquiryId: string,
  bundleIds: string | number | number[],
  router: NextRouter,
): Promise<boolean> => {
  const queryParams = {
    [PARAM_INQUIRY_KEY]: inquiryId,
    [PARAM_ID]: bundleIds,
  };

  return navigateToRoute(
    '/premiums/[inquiryKey]/offer-confirmation',
    router,
    queryParams,
  );
};

export const extractLanguage = ({ query }: { query: any }): string => query.lng;

export const updateLanguagePath = (
  router: NextRouter,
  locale: string,
): void => {
  router.push(router.asPath, router.asPath, { locale });
};

export const redirectToUrl = (url: string): void => {
  getGlobal().location = url;
};

export const goToFinanceScout = (
  router: NextRouter,
  vehicleId?: string,
): void => {
  const lang = extractLanguage(router) || 'de';
  if (vehicleId) {
    redirectToUrl(
      `${CMS_URL}${languageToPortalLandingPageMapping[lang]}?vehicleId=${vehicleId}`,
    );
  } else {
    redirectToUrl(`${CMS_URL}${languageToPortalLandingPageMapping[lang]}`);
  }
};
