export enum InsuranceCompanies {
  HELVETIA = 'helvetia',
  BELSURA = 'belsura',
  SMILE = 'smile',
  GENERALI = 'generali',
  TCS = 'tcs',
  AXA = 'axa',
  MOBILIAR = 'mobiliar',
  VAUDOISE = 'vaudoise',
  ZURICH = 'zurich',
  BALOISE = 'baloise',
  ELVIA = 'elvia',
  ALLIANZ = 'allianz',
}

export enum MotoInsuranceCompanies {
  SMILE = 'smile',
  TCS = 'tcs',
  HELVETIA = 'helvetia',
  MOBILIAR = 'mobiliar',
  ZURICH = 'zurich',
  GENERALI = 'generali',
  BALOISE = 'baloise',
  ALLIANZ = 'allianz',
  AXA = 'axa',
}

export interface Premium {
  productBundleId: number;
  offerRequested: boolean;
  insuranceName: InsuranceCompanies;
  productName: string;
  responseId: number;
  premiumTotal: number;
  supportsOffer: boolean;
  deviations: Deviation[];
  supportsDirectDeal: boolean;
  customerRating: number;
  contractDurationInMonths: number;
  insuranceId: number;
  insuranceInquiryId: number;
  insuranceTypeId: number;
  isBestMatchingScore: boolean;
  isFinalResponse: boolean;
  isLowestPremium: boolean;
  isSpecialDiscount: boolean;
  matchingScore: number;
  modificationDate: string;
  productOrder: number;
  discount: number | null;
  cashback: number | null;
  premiumTotalWithDiscount: number;
  premiumTotalForSorting: number;
}

export interface PremiumSelection extends Premium {
  isSelected: boolean;
}

export type Deviation = {
  benefit: string;
  benefits: unknown;
  type: number;
  value: string | null;
};

export type ProductItem = {
  children: ProductItemChild[];
  description?: string;
  name: string;
  type: string;
};

export type ProductItemChild = {
  description?: string;
  icon?: string;
  insurances: { [key: string]: InsuranceItemValue };
  name: string;
  type: string;
};

export type InsuranceItemValue = {
  translateValue?: boolean;
  value?: number | string | boolean | string[];
  params?: Record<string, string | number>;
};

export type ProductBundle = {
  claimsHandlingRating: number;
  costBenefitRatioRating: number;
  customerRating: number;
  deviations: Deviation[];
  insuranceName: string;
  matchingScore: number;
  premiumTotal: number;
  productBundleId: number;
  productName: string;
  recommendationRating: number;
  supportsDirectDeal: boolean;
  supportsOffer: boolean;
  offerRequested: boolean;
  discount: number | null;
  cashback: number | null;
};

export interface InsuranceComparisonResponse {
  results: Premium[];
  completed: boolean;
  totalInsurances: number;
  completedInsurances: number;
}
