import { Engine } from 'json-rules-engine';

import {
  isArrayEmpty,
  isEmpty,
  isNotEmpty,
  isDateGreater,
} from 'utils/operators';

import rules from './rules';

const engine = new Engine();
engine.addOperator('empty', isEmpty);
engine.addOperator('isArrayEmpty', isArrayEmpty);

engine.addRule(rules.cancellationOrSpecialConditionsEmpty);
engine.addRule(rules.denialOrSpecialConditionsEmpty);
engine.addRule(rules.additionalQuestions);
engine.addRule(rules.dataProtectionAcceptedEmpty);

const mainDriverEngine = new Engine();
mainDriverEngine.addOperator('empty', isEmpty);
mainDriverEngine.addRule(rules.ageOfYoungestChildEmpty);

export const createClaimEngine = (index) => {
  const claimEngine = new Engine();
  claimEngine.addFact('today', new Date());

  claimEngine.addOperator('empty', isEmpty);
  claimEngine.addOperator('notEmpty', isNotEmpty);
  claimEngine.addOperator('greaterDate', isDateGreater);

  const claim = rules.claim(index);
  claimEngine.addRule(claim.typeEmpty);
  claimEngine.addRule(claim.amountEmpty);
  claimEngine.addRule(claim.amountGreaterThan);
  claimEngine.addRule(claim.causeEmpty);
  claimEngine.addRule(claim.selfInflictedEmpty);
  claimEngine.addRule(claim.causerEmpty);
  claimEngine.addRule(claim.dateEmpty);
  claimEngine.addRule(claim.dateInFuture);

  return claimEngine;
};

export const createInfringementEngine = (index) => {
  const infringementEngine = new Engine();
  infringementEngine.addFact('today', new Date());

  infringementEngine.addOperator('empty', isEmpty);
  infringementEngine.addOperator('notEmpty', isNotEmpty);
  infringementEngine.addOperator('greaterDate', isDateGreater);

  const infringement = rules.infringement(index);
  infringementEngine.addRule(infringement.reasonEmpty);
  infringementEngine.addRule(infringement.dateEmpty);
  infringementEngine.addRule(infringement.dateInFuture);
  return infringementEngine;
};

export const createSuspensionEngine = (index) => {
  const suspensionEngine = new Engine();
  suspensionEngine.addFact('today', new Date());

  suspensionEngine.addOperator('empty', isEmpty);
  suspensionEngine.addOperator('notEmpty', isNotEmpty);
  suspensionEngine.addOperator('greaterDate', isDateGreater);

  const suspension = rules.suspension(index);
  suspensionEngine.addRule(suspension.periodEmpty);
  suspensionEngine.addRule(suspension.dateEmpty);
  suspensionEngine.addRule(suspension.dateInFuture);
  return suspensionEngine;
};

export const getIncidentEngine = (inputs) => {
  const { incidents } = inputs;
  const mainEngine = engine.run(incidents);
  const driverEngine = mainDriverEngine.run(inputs);
  const claimEngines = incidents.claims.map((claim, index) =>
    createClaimEngine(index).run({ claim }),
  );
  const infringementEngines = incidents.infringements.map(
    (infringement, index) =>
      createInfringementEngine(index).run({ infringement }),
  );
  const suspensionEngines = incidents.suspensionOfDrivingLicense.map(
    (suspension, index) => createSuspensionEngine(index).run({ suspension }),
  );
  return [mainEngine].concat(
    claimEngines,
    infringementEngines,
    suspensionEngines,
    driverEngine,
  );
};

const validateIncidents = (inputs, success, failure) => {
  const engines = getIncidentEngine(inputs);

  return Promise.all(engines).then((p) => {
    const errors = p.flat();
    if (errors.length > 0) {
      return failure(errors);
    }
    return success();
  });
};

export default validateIncidents;
