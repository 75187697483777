import { FeatureFlagState } from './const';
import { ActionType, STORE_FEATURE_FLAGS } from './actions';

export const initialState: FeatureFlagState = {} as FeatureFlagState;

const reducer = (
  state: FeatureFlagState = initialState,
  action: ActionType,
): FeatureFlagState => {
  switch (action.type) {
    case STORE_FEATURE_FLAGS:
      return { ...state, ...action.values };
    default:
      return state;
  }
};

export default reducer;
