import moment from 'moment';
import { convertToE164Format, isE164PhoneNumber } from '@scout24ch/fs24-utils';
import { validateE164PhoneNumber } from '@scout24ch/fs24-forms';
import { fromInput } from './dateformat-const';
import { validateEmail } from './validation';

function isNullOrEmpty(value) {
  return value === undefined || value === null || value === '';
}

export const isArrayEmpty = (factValue) => !factValue || factValue.length === 0;

export const isEmpty = (factValue) => isNullOrEmpty(factValue);

export const isNotEmpty = (factValue) => !isNullOrEmpty(factValue);

export const isDateNotValid = (factValue) =>
  !(factValue instanceof Date || fromInput(factValue) instanceof Date);

export const isDateGreater = (factValue, jsonValue) =>
  moment(fromInput(factValue)).format('YYYY-MM-DD') >
  moment(fromInput(jsonValue)).format('YYYY-MM-DD');

export const isDateSmaller = (factValue, jsonValue) =>
  moment(fromInput(factValue)).format('YYYY-MM-DD') <
  moment(fromInput(jsonValue)).format('YYYY-MM-DD');

export const isDateLess18YearsSmaller = (factValue, jsonValue) =>
  moment(fromInput(factValue)).add(-18, 'y') < moment(fromInput(jsonValue));

export const isDateLess16YearsSmaller = (factValue, jsonValue) =>
  moment(fromInput(factValue)).add(-16, 'y') < moment(fromInput(jsonValue));

export const isNotDivisible = (factValue, jsonValue) =>
  !(factValue % jsonValue === 0);

export const isEmailInvalid = async (factValue) =>
  (await validateEmail(factValue)) !== undefined;

export const isPhoneInvalid = (factValue) => {
  if (factValue === null) return false;
  try {
    const validationResult = convertToE164Format(factValue);
    return !isE164PhoneNumber(validationResult);
  } catch (e) {
    return true;
  }
};

export const isSwissMobileNumberInvalid = (factValue) => {
  return validateE164PhoneNumber(factValue);
};

export const isEmptyOrNone = (factValue) =>
  isEmpty(factValue) || factValue.key === 0;

export const isLengthMismatch = (factValue, expectedLength) => {
  // fail validation if not a string
  if (typeof factValue !== 'string') {
    return true;
  }
  return factValue.length !== expectedLength;
};
