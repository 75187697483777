import {
  ENTER_FULLSCREEN,
  LEAVE_FULLSCREEN,
  CLOSE_MODAL,
  OPEN_MODAL,
} from './actions';

export interface MobileFullscreenState {
  mobileFullscreen?: boolean;
  modalOpen?: boolean;
}

const initialState = {
  mobileFullscreen: false,
  modalOpen: false,
};

const mobileFullscreenReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENTER_FULLSCREEN:
      return { ...state, mobileFullscreen: true };
    case LEAVE_FULLSCREEN:
      return { ...state, mobileFullscreen: false };
    case OPEN_MODAL:
      return { ...state, modalOpen: true };
    case CLOSE_MODAL:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export default mobileFullscreenReducer;
