import {
  ValidationFailureFunction,
  ValidationSuccessFunction,
} from '../../../../../utils/validation/types';
import InsuranceOptions from '../types';
import MotoInsuranceOptionsValidation from './motoInsuranceOptionsValidation';
import InsuranceOptionsValidation from './insuranceOptionsValidation';

type ValidationFunction = (
  insuranceOptions: InsuranceOptions,
  vehicle: any,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => void;

const createInsuranceOptionsValidation = (
  isAutoInsurance: boolean,
): ValidationFunction => {
  if (isAutoInsurance) {
    return InsuranceOptionsValidation;
  }
  return MotoInsuranceOptionsValidation;
};

export default createInsuranceOptionsValidation;
