export const ID_FIRST_NAME = 'firstName';
export const ID_NAME = 'name';
export const ID_STREET = 'street';
export const ID_HOUSE_NUMBER = 'houseNumber';
export const ID_PRIVATE_PHONE = 'privatePhone';
export const ID_EMAIL = 'email';
export const ID_CITY = 'city';
export const ID_POST_CODE = 'postCode';
export const ID_TERMS_CONDITIONS = 'termsAndConditions';
export const ID_DATA_CORRECT = 'dataCorrect';
export const ID_RECOGNISE_REDUCTION = 'recogniseReduction';
export const ID_READ_DATA_PROTECTION = 'readDataProtection';
export const ID_DATA_PROTECTION_ACCEPTED = 'dataProtectionAccepted';
export const ID_CANTON = 'canton';
export const ID_MANUAL_CITY = 'manualCity';
export const ID_MANUAL_CANTON = 'manualCanton';
export const ID_THANKYOU_TITLE = 'thankYouTitle';
export const ID_THANKYOU_TEXT = 'thankYouText';
export const ID_TYPE_SOMETHING = 'typeSomething';
export const ID_STREET_NUMBER = 'street-number';
export const ID_SELECTED_OFFERS = 'selectedOffers';

export const BALOISE_INSURANCE_ID = 9;
export const TCS_INSURANCE_ID = 11;
export const WEFOX_INSURANCE_ID = 14;
export const SMILE_INSURANCE_ID = 10;

export const STREET_LENGTH_LIMIT = 100;
export const HOUSE_NUMBER_LENGTH_LIMIT = 50;
