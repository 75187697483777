import { AlertType } from './reducer';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

export const showAlert = (alertType: AlertType, ...messages) => ({
  type: SHOW_ALERT,
  alertType,
  messages,
});

export const hideAlert = () => ({ type: HIDE_ALERT });
