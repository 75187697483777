import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _startsWith from 'lodash/startsWith';
import { isNil } from 'utils';
import { Vehicle } from '../state/InquiryInput/types';

const vehicleUtils = (models, transmissionTypeId, engineHp, bodyTypeId) => {
  let filteredModels = _filter(models, (m) => m.id > -1);
  if (
    !isNil(transmissionTypeId) &&
    _find(filteredModels, (m) => m.transmissionType.id === transmissionTypeId)
  ) {
    filteredModels = _filter(
      filteredModels,
      (m) => m.transmissionType.id === transmissionTypeId,
    );
  }
  if (
    !isNil(engineHp) &&
    _find(filteredModels, (m) => m.engineHorsePower === engineHp)
  ) {
    filteredModels = _filter(
      filteredModels,
      (m) => m.engineHorsePower === engineHp,
    );
  }
  if (
    !isNil(bodyTypeId) &&
    _find(filteredModels, (m) => m.bodyType.id === bodyTypeId)
  ) {
    filteredModels = _filter(
      filteredModels,
      (m) => m.bodyType.id === bodyTypeId,
    );
  }
  return filteredModels;
};

export const isVehicleValid = (vehicle) =>
  !isNil(vehicle) && !isNil(vehicle.eurotaxId) && vehicle.eurotaxId !== -1;

const isProviderIdValid = (vehicle) =>
  !isNil(vehicle) && !isNil(vehicle.providerId) && vehicle.providerId === 1;

export const isVehicleValidForLP = (vehicle) =>
  isVehicleValid(vehicle) && isProviderIdValid(vehicle);

export const getVehicleFullName = (vehicle: Vehicle) => {
  const { modelName, fullName, typeName } = vehicle;

  if (fullName) {
    return fullName;
  }

  if (typeName) {
    return typeName;
  }

  return modelName;
};

export default vehicleUtils;
