import { SearchFlow } from 'utils/types';
import {
  FETCH_VEHICLE_FULFILLED,
  FETCH_VEHICLE_FAILED,
  CLEAR_VEHICLE,
} from './actions';

export interface Vehicle {
  makeName?: string;
  typeName?: string;
  dateOfFirstRegistration?: string;
  eurotaxId?: number;
  searchType?: SearchFlow;
}

export interface VehicleDataState {
  fetchingVehicleFailed: Boolean;
  vehicle: Vehicle | null;
  error: unknown | null;
}

const initialState: VehicleDataState = {
  fetchingVehicleFailed: false,
  vehicle: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_FULFILLED:
      return {
        fetchingVehicleFailed: false,
        vehicle: {
          ...action.payload,
          // if there is a license plate number it means inquiry is done by license plate number otherwise set search type to by brand
          searchType: action.payload.licensePlateNumber
            ? SearchFlow.LICENSE_PLATE_NUMBER
            : SearchFlow.BRAND,
        },
        error: null,
      };
    case FETCH_VEHICLE_FAILED:
      return {
        fetchingVehicleFailed: true,
        vehicle: null,
        error: action.payload,
      };
    case CLEAR_VEHICLE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
