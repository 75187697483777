import logger from 'utils/logger';

export default function getCircularReplacer(): (
  key: string,
  value: any,
) => any {
  const seen = new WeakSet();
  return (key: string, value: any): any => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        logger.error(
          `circular json structure detected! key: ${key} , value: ${value}`,
        );
        return undefined;
      }
      seen.add(value);
    }
    return value;
  };
}
