import {
  generateEmailHash,
  ECommerceEventLabel,
  NOT_PROVIDED,
  GTMEcommerceEvent,
  GtmProduct,
  createGA4GTMECommerceEvent,
  GtmActionField,
} from '@scout24ch/fs24-gtm';
import { DisplayValues } from 'containers/DirectDealPage/model/DisplayValues';
import { Premium } from 'types/insurances';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { prependZeros } from '../premiumUtils';

export const EVENT_GTM = 's24.gtmEvent';
export const EVENT_SPLIT = 'splitTreatments';
export const EVENT_SPLIT_CATEGORY = 'splitInfo';
export const CAR_RESULTLIST_TYPE = 'Car_Insurance_Resultlist';
export const MOTO_RESULTLIST_TYPE = 'Moto_Insurance_Resultlist';
export const CAR_COMPARISON_CATEGORY = 'Car_Insurance_Comparison';
export const MOTO_COMPARISON_CATEGORY = 'Moto_Insurance_Comparison';

export const PAGEVIEW_CATEGORY = IS_AUTO_INSURANCE
  ? 'Car Insurance'
  : 'Moto Insurance';
export const INSURANCE_TYPE = IS_AUTO_INSURANCE
  ? ECommerceEventLabel.CAR_INSURANCE
  : ECommerceEventLabel.MOTO_INSURANCE;
export const RESULTLIST_TYPE = IS_AUTO_INSURANCE
  ? CAR_RESULTLIST_TYPE
  : MOTO_RESULTLIST_TYPE;
export const CATEGORY_INSURANCE_COMPARISON = IS_AUTO_INSURANCE
  ? CAR_COMPARISON_CATEGORY
  : MOTO_COMPARISON_CATEGORY;
export const TRANSACTION_TYPE_DIRECT_DEAL = 'direct_deal';
export const TRANSACTION_TYPE_REQUEST_OFFER = 'request_offer';

// InquiryPage
export const ACTION_GOTO_PREMIUMS = 'Compare_Insurance';
export const ACTION_SUBMIT_VEHICLESUM_AND_TERM = 'Submit_Vehiclesum_and_Term';
export const ACTION_SUBMIT_DRIVER_DATA = 'Submit_Driver_Data';
export const ACTION_SUBMIT_COVERAGE_DATA = 'Submit_Coverage_Data';
export const ACTION_SUBMIT_INCIDENTS_DATA = 'Submit_Incidents_Data';
export const ACTION_CAR_SEARCH = 'Car_Search';
export const ACTION_FIND_VEHICLE = 'Find_Vehicle';
export const EVENT_LABEL_CAR_SEARCH_LICENCE_PLATE = 'Licence_Plate';
export const EVENT_LABEL_CAR_SEARCH_MAKE = 'Make';
export const EVENT_CANCELLATION_SERVICE_VISIBLE =
  'Cancellation_Service_Visible';
export const EVENT_CANCELLATION_SERVICE_YES = 'Cancellation_Service_Yes';
export const EVENT_CANCELLATION_SERVICE_NO = 'Cancellation_Service_No';
export const EVENT_MOST_FREQUENT_KMS = 'Most_Frequent_Km';

// PremiumListPage
export const ACTION_ADJUST_MORE = 'Modify_Data';
export const ACTION_START_RECALCULATION = 'Start_Recalculation';
export const ACTION_SORT = 'Change_Sort_Order';
export const ACTION_SELECT_INSURANCE_TO_COMPARE = 'Select_Insurance_To_Compare';
export const ACTION_DESELECT_INSURANCE_TO_COMPARE =
  'Deselect_Insurance_To_Compare';
export const ACTION_SHOW_DETAILS = 'Show_Details';
export const ACTION_COMPARE_INSURANCES = 'Compare_Insurance';
export const ACTION_REQUEST_OFFER = 'Request_Offer';
export const ACTION_REQUEST_OFFER_BAR = 'Request_Offers_Bar';
export const ACTION_REQUEST_MULTIPLE_OFFERS_SENT =
  'Request_Multiple_Offers_Sent';
export const ACTION_MODIFY_VEHICLE = 'Modify_Vehicle';
export const ACTION_REMIND_ME = 'Remind_Me';
export const ACTION_REMIND_ME_DATE = 'Remind_Me_Date';
export const ACTION_VIEW_PRODUCT_LIST = 'view_product_list';
export const ACTION_VIEW_PRODUCT = 'view_product';
export const ACTION_SELECT_PRODUCT = 'select_product';
export const ACTION_ADVISORY_BANNER_OPEN = 'Advisory_Banner_Open';
export const ACTION_ADVISORY_BANNER_SENT = 'Advisory_Banner_Sent';
export const ACTION_GO_DO_DETAILS = 'All_Details_Open';
export const ACTION_COLLAPSE_CARD = 'Collapse_Offer_Card';
export const ACTION_EXPAND_CARD = 'Expand_Offer_Card';
export const ACTION_OPEN_SUMMARY = 'Summary_Open';
export const ACTION_CLOSE_SUMMARY = 'Summary_Close';
export const ACTION_MODIFY_SUMMARY = 'Summary_Modify';
export const ACTION_HIGHLIGHT_TOOLTIP_OPEN = 'Tooltip_Highlight_Open';
export const ACTION_COMPARE_TOP_OFFERS = 'Compare_Top_Offers';

// OfferPage
export const ACTION_REQUEST_OFFER_SENT = 'Request_Offer_Sent';
export const ACTION_PURCHASE_OFFER = 'purchase_offer';

// NavigationBar
export const ACTION_SHARE = 'Share_Results';
export const ACTION_SHARE_SEND_MAIL = 'Share_Email';

// Direct Deal
export const ACTION_GOTO_DIRECT_DEAL = 'Request_Insurance_Policy';
export const ACTION_CONFIRM_DIRECT_DEAL = 'Request_Insurance_Policy_Sent';
export const directDealProgressbarActionMappings = new Map([
  [0, 'Progressbar_Particulars'],
  [1, 'Progressbar_Vehicle'],
  [2, 'Progressbar_Summary'],
]);
export const ACTION_PURCHASE_DIRECT_DEAL = 'purchase_deal';

// Save Comparison CTA
export const EVENT_LABEL_POSITION_TOP_RIGHT = 'Top_Right';
export const EVENT_LABEL_POSITION_SIDEBAR_LEFT = 'Sidebar_Left';
export const EVENT_LABEL_POSITION_RESULTLIST_BOTTOM = 'Resultlist_Bottom';

// Confirmation Page
export const ACTION_EDIT_EMAIL = 'Modify_Email';
export const ACTION_REQUEST_ADDITIONAL_OFFER = 'Request_Additional_Offer';
export const ACTION_BACK_TO_RESULTS = 'Back_To_Results';
export const ACTION_SELECT_INSURANCE_TO_ORDER = 'Select_Insurance_To_Order';
export const ACTION_DESELECT_INSURANCE_TO_ORDER = 'Deselect_Insurance_To_Order';

// Direct Deal Confirmation
export const ACTION_GOTO_LANDING_PAGE = 'Back_To_Start';
export const ACTION_DOWNLOAD_WITHDRAWAL = 'Download_Cancellation_Letter';

// Parking Damage Hint
export const ACTION_PARKDAMAGE_UPSELLING_HINT = 'Parkdamage_Upselling_Hint';

// Virtual Page Paths
export const RESULT_LIST = '5-resultlist';
export const DIRECT_DEAL_PERSONAL_DATA = '9-direct-contactdata';

// Full casco upselling on offer page
export const REQUEST_FULL_CASCO_EVENT_ACTION = 'Request_Kasko_Upgrade';
export const REQUEST_FULL_CASCO_EVENT_LABEL = 'Add_Kasko';
export const REMOVE_FULL_CASCO_EVENT_LABEL = 'Remove';

export const createEvent = (eventAction: string, params) => ({
  event: EVENT_GTM,
  eventCategory: CATEGORY_INSURANCE_COMPARISON,
  eventAction,
  ...params,
});

export const createInsuranceProductEventLabel = (
  insurance: string,
  product: string,
) => `Insurance: ${insurance} # Product: ${product}`;
export const createEventLabelFromPremium = (
  premium: Premium | DisplayValues = {} as Premium | DisplayValues,
) =>
  createInsuranceProductEventLabel(premium.insuranceName, premium.productName);

export const createOfferSentEvent = (premium: Premium, email: string) => {
  const eventLabel = createEventLabelFromPremium(premium);
  const emailHashes = generateEmailHash(email);

  return createEvent(ACTION_REQUEST_OFFER_SENT, {
    eventLabel,
    ...emailHashes,
  });
};

export const createGTMECommerceEvent = (
  eventAction: string,
  gtmProducts: GtmProduct[],
  actionField?: GtmActionField,
): GTMEcommerceEvent => {
  const products = gtmProducts.map((product) => ({
    id: `${INSURANCE_TYPE}-${prependZeros(product.id, 3)}`,
    name: `${product.brand} | ${product.name}`,
    currency: 'CHF',
    position: product.position ?? NOT_PROVIDED,
    brand: product.brand,
    category: INSURANCE_TYPE,
    list_id: RESULTLIST_TYPE,
    list_name: RESULTLIST_TYPE,
    transaction_type: product.transaction_type ?? NOT_PROVIDED,
    quantity: 1,
    price: product.price,
    xup_options: product.xup_options,
  }));

  return createGA4GTMECommerceEvent(
    eventAction,
    INSURANCE_TYPE,
    products,
    actionField,
  );
};
