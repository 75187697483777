import { UpsellingProducts } from '../../types/upselling';

export const STEP_PERSONAL_DATA = { name: 'personal-data', index: 1 };
export const STEP_VEHICLE = { name: 'vehicle', index: 2 };
export const STEP_CONFIRM = { name: 'confirm', index: 3 };
export const STEP_RESULT = { name: 'result', index: 4 };

export const PROP_PERSONAL_DATA = 'personalData';
export const UP_SELLING_OPTIONS = 'upSellingOptions';

export const DIRECT_DEAL_STEPS = [
  STEP_PERSONAL_DATA,
  STEP_VEHICLE,
  STEP_CONFIRM,
  STEP_RESULT,
];

export enum SEND_STATUS {
  NOT_SENT = 0,
  SENDING,
  SUCCESS,
  FAILED,
}

/** Currently we support assistance, legal, legal single and legal family for upselling */
export const SUPPORTED_PRODUCTS = [
  UpsellingProducts.LEGAL,
  UpsellingProducts.LEGAL_SINGLE,
  UpsellingProducts.LEGAL_FAMILY,
  UpsellingProducts.ASSISTANCE,
];
