import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface NextStaticProviderProps extends WithTranslation {
  children: React.ReactElement;
}

export function NextStaticProvider(
  props: NextStaticProviderProps,
): React.ReactElement | null {
  const { children, tReady = true } = props;
  return tReady ? children : null;
}

export default withTranslation()(NextStaticProvider);
