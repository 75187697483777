import { isProd } from './const';
import { DEV_LANGUAGES, PROD_LANGUAGES } from './i18n/defaultConfig';

export type LanguageCode = 'de' | 'en' | 'fr' | 'it';

export enum Language {
  en = 1,
  de = 2,
  fr = 3,
  it = 4,
}

export function getLanguages(): readonly LanguageCode[] {
  if (isProd) {
    return PROD_LANGUAGES;
  }

  return DEV_LANGUAGES;
}
