import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const VaudoiseLogo = (props: IconProps) => (
  <svg
    className={`a-icon ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 144 96"
    fill="none"
    {...props}
  >
    <path
      d="M31.4783 33.4151C31.2935 32.0134 30.9379 30.9212 30.4345 30.1684C29.9334 29.4156 29.34 29 28.5041 29C27.3426 29 26.0818 29.8036 24.6086 31.8264C22.2509 35.0661 19.4892 40.9567 16.4942 48.062C15.2888 46.1108 12.668 43.2543 8.72857 43.2497C7.75642 43.2497 6.7173 43.4321 5.61815 43.8524C1.40858 45.4619 -0.00230632 49.3528 2.82908e-06 51.9714C2.82908e-06 56.4234 3.37135 60.1088 7.42621 60.1896C7.47009 60.1919 7.51396 60.1919 7.55783 60.1919C8.65468 60.1919 9.86698 59.8779 10.8207 59.3606C11.6635 58.9034 12.3747 58.2846 12.9266 57.6103C13.5824 56.8113 14.0373 56.0331 14.3352 55.4651C15.9908 59.4345 17.6072 62.4272 18.8195 63.9535C19.9141 65.3228 21.0848 66.0733 22.244 66.0733H22.2463C23.3431 66.0733 24.3869 65.3898 25.2713 64.0158C27.6751 60.2958 29.3354 54.6777 30.5361 49.2835C31.245 46.0923 31.723 41.6195 31.723 37.6985C31.7207 36.1445 31.6445 34.6805 31.4783 33.4151ZM9.46288 58.527C9.21118 58.5801 8.94332 58.6101 8.66391 58.6101C6.73809 58.6078 4.34581 57.2708 3.83549 54.5022C3.77314 54.1604 3.74082 53.8071 3.74082 53.4515C3.74313 51.4541 4.75684 49.3874 7.13988 48.6254C7.53012 48.5007 7.91113 48.443 8.2829 48.443C10.4674 48.443 12.4855 50.5882 13.7302 54.008C13.1183 55.4836 11.645 58.0629 9.46288 58.527ZM27.7582 52.6803C26.6706 54.3198 25.0704 55.2804 23.7703 55.2804C23.6872 55.2804 23.6041 55.278 23.5186 55.2688C20.3967 54.9917 18.0575 50.8029 17.2516 49.369C18.4362 46.4086 19.4061 44.5336 20.5168 42.9126C21.4589 41.5363 22.3802 40.5827 23.2 39.973C23.992 39.3842 24.7217 39.0863 25.3937 38.994C25.8301 38.9316 26.2342 38.9455 26.5967 39.0263C27.5389 39.2387 28.2916 39.9084 28.7812 40.6635C29.4508 41.6957 29.8134 43.0765 29.8134 44.4343C29.8134 48.1289 28.9428 50.8953 27.7582 52.6803Z"
      fill="#00923F"
    />
    <path
      d="M62.0167 56.9727L61.8504 52.2044V49.1263C61.8504 45.8611 59.1072 45.4316 57.1513 45.4316C55.1008 45.4316 53.048 46.5054 52.0735 47.1958L52.4314 48.1264C53.6714 47.7916 54.8399 47.6276 55.6989 47.6276C57.6547 47.6276 58.6315 48.2442 58.6315 49.8652V51.4239L56.5302 51.6617C53.9578 51.9711 51.1406 52.7793 51.1406 55.9152C51.1406 57.5847 52.3829 58.9494 54.385 58.9494C56.4586 58.9494 57.8187 57.7578 58.6754 56.7072C58.77 58.0904 59.2249 58.9263 60.9683 58.9263C61.8966 58.9263 63.3514 58.3051 64.0903 57.6147L63.9956 56.9704H62.0167V56.9727ZM58.6292 55.996C57.8648 56.3539 57.0313 56.6633 56.0291 56.6633C55.0269 56.6633 54.4081 55.9983 54.4081 54.9869C54.4081 53.7007 55.6458 52.9964 57.149 52.7609L58.6292 52.4976V55.996V55.996Z"
      fill="#00923F"
    />
    <path
      d="M122.755 48.29C122.755 47.3617 123.471 46.7637 124.757 46.7637C127.099 46.7637 127.614 48.0522 127.949 49.5069L128.736 49.5785L129.14 46.4081C127.736 45.6922 126.533 45.4082 124.997 45.4082C122.328 45.4082 119.968 47.0038 119.968 49.4862C119.968 53.9428 126.697 53.6334 126.697 55.8525C126.697 56.9493 125.785 57.5705 124.471 57.5705C122.621 57.5705 121.409 56.282 121.1 54.4231L120.171 54.4L119.957 57.9307C121.386 58.5726 122.744 58.9536 124.425 58.9536C126.882 58.9536 129.867 57.7898 129.867 54.6656C129.867 50.5322 122.755 50.8439 122.755 48.29Z"
      fill="#00923F"
    />
    <path
      d="M114.333 42.6151C115.395 42.6151 116.25 41.7561 116.25 40.6962C116.25 39.6363 115.393 38.7773 114.333 38.7773C113.273 38.7773 112.41 39.6363 112.41 40.6962C112.41 41.7561 113.273 42.6151 114.333 42.6151Z"
      fill="#00923F"
    />
    <path
      d="M143.998 51.6099C143.998 48.2224 142.377 45.3867 138.631 45.3867C134.671 45.3867 131.972 48.8366 131.972 52.6051C131.972 56.4822 134.341 58.9529 138.657 58.9529C140.876 58.9529 142.806 58.0247 143.831 56.8077L143.476 55.9995C142.044 56.3321 140.733 56.4891 139.848 56.4891C137.345 56.4891 135.583 55.4246 135.368 52.1825H144V51.6099H143.998ZM135.341 50.894C135.412 48.0815 136.558 46.793 138.084 46.793C139.846 46.793 140.516 48.4856 140.516 50.894H135.341Z"
      fill="#00923F"
    />
    <path
      d="M116.049 56.6158L116 52.2284V46.5064C116 45.6704 115.619 45.458 114.88 45.458C113.735 45.458 111.851 45.6958 110.562 45.9822V47.079H112.756V52.2284L112.686 56.4495C112.686 56.976 112.472 57.3801 112.042 57.5233L110.587 57.9759C110.587 58.2853 110.636 58.4285 110.682 58.7379H118.076C118.196 58.4285 118.219 58.1421 118.219 57.902L116.453 57.4748C116.192 57.4055 116.049 57.2369 116.049 56.6158Z"
      fill="#00923F"
    />
    <path
      d="M102.695 45.4316C99.0461 45.4316 96.1597 48.96 96.1597 52.4422C96.1597 56.9727 98.545 58.9494 102.48 58.9494C106.248 58.9494 109.084 55.421 109.084 51.9642C109.084 47.5076 106.936 45.4316 102.695 45.4316ZM102.858 57.4276C100.284 57.4276 99.6188 54.8045 99.6188 51.9896C99.6188 49.4149 100.261 46.9095 102.503 46.9095C105.036 46.9095 105.581 49.438 105.581 52.4422C105.579 54.9222 105.151 57.4276 102.858 57.4276Z"
      fill="#00923F"
    />
    <path
      d="M93.2668 56.9708L93.1698 52.0824V39.3243C93.1698 38.3707 92.835 38.1328 91.9298 38.1328C90.8076 38.1328 89.0203 38.3707 87.7318 38.6362V39.7561H89.9255V45.7391C89.3528 45.6421 88.7802 45.5729 87.875 45.5729C84.1318 45.5729 81.1484 48.3369 81.1484 52.7266C81.1484 57.7351 83.7254 58.9497 86.3001 58.9497C87.9212 58.9497 89.0896 58.0215 89.9001 57.114C89.9001 57.9961 90.2118 58.9497 91.4241 58.9497C93.1906 58.9497 94.3844 58.354 95.4097 57.7836L95.2434 56.9708H93.2668V56.9708ZM89.9255 52.0593L89.8539 56.0887C89.2605 56.2804 88.5192 56.5875 87.6579 56.5875C85.8475 56.5875 84.5844 55.2297 84.5844 52.2255C84.5844 48.7179 85.4919 47.0253 87.9466 47.0253C88.8795 47.0253 89.4267 47.2424 89.9255 47.4063V52.0593Z"
      fill="#00923F"
    />
    <path
      d="M77.9205 56.9737L77.8005 52.2538V46.7927C77.8005 45.8621 77.2047 45.458 76.2764 45.458C75.1311 45.458 73.7456 45.7674 72.5795 46.0999V47.0536H74.5584V52.2284L74.4868 55.8076C73.7456 56.1401 72.7735 56.5927 71.3857 56.5927C69.797 56.5927 69.5776 55.4358 69.5522 54.0526V46.7696C69.5522 45.8621 69.0257 45.458 68.0489 45.458C66.929 45.458 65.5689 45.7443 64.2573 46.0999V47.0536H66.2848V54.5399C66.2848 56.782 66.5457 58.9526 70.0764 58.9526C71.9352 58.9526 73.2953 57.9066 74.5099 56.7128C74.5353 58.0959 75.2004 58.9042 76.4658 58.9042C78.0383 58.9042 79.1836 58.1675 80.0426 57.641L79.9711 56.9737H77.9205V56.9737Z"
      fill="#00923F"
    />
    <path
      d="M46.5414 46.5296L47.629 46.8875C47.9384 47.0076 48.1278 47.2454 48.1278 47.6495C48.1278 47.8643 48.0816 48.1021 47.9869 48.3885L44.9089 56.2095L41.5698 45.7168H36.8707C36.776 46.0031 36.8338 46.4234 36.8892 46.6474C38.0068 47.033 38.374 46.9406 38.7711 47.9798L42.8098 59.38L45.1929 58.6896L49.3447 48.7695C49.7742 47.675 50.2268 46.7675 50.6309 46.3126C50.6586 46.2179 50.6586 45.8115 50.6586 45.7168H46.5668C46.4975 46.0054 46.4975 46.2202 46.5414 46.5296Z"
      fill="#00923F"
    />
  </svg>
);

VaudoiseLogo.displayName = 'VaudoiseLogo';

export default VaudoiseLogo;
