async function initMocks() {
  if (typeof window === 'undefined') {
    const { server } = await import('./server');

    server.listen({
      onUnhandledRequest(req, print) {
        const excludedUrls = [
          'http://localhost:9779/',
          'https://beagle.dev.tda.link',
          'https://www.googletagmanager.com',
          'https://fonts.googleapis.com',
          'https://telemetry.nextjs.org',
        ];

        const isExcluded = excludedUrls.some((route) =>
          req.url.includes(route),
        );

        if (isExcluded) {
          return;
        }

        print.warning();
      },
    });
  } else {
    const { worker } = await import('./browser');
    worker.start();
  }
}

initMocks();

export {};
