import { castArray } from '@scout24ch/fs24-utils';
import i18next, {
  i18n,
  InitOptions,
  createInstance as createI18nextInstance,
} from 'i18next';
import i18nextHttpBackend from 'i18next-http-backend';
import { LanguageDetector as ServerLanguageDetector } from 'i18next-http-middleware';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SeverityLevel } from '@sentry/node';
import { captureException } from 'utils/sentry';
import { TRANSLATION_RELOAD_INTERVAL_MIN } from '../const';
import { defaultConfig, ALL_NAMESPACES } from './defaultConfig';

// Fix to import es6 module in common-js.
if ((process as any).browser && i18next.hasOwnProperty('default')) {
  i18next['default'];
}

class TranslationError extends Error {
  constructor(
    readonly translationKey: string,
    readonly locale: string,
  ) {
    super(`Missing translation "${translationKey}" for ${locale}`);
  }
}

function missingKeyHandler(lngs: string[], ns: string, key: string): void {
  const translationKey = `${ns}:${key}`;

  captureException(new TranslationError(translationKey, String(lngs)), {
    level: 'warning' as SeverityLevel,
    fingerprint: ['missing translation', translationKey],
  });
}

/**
 * Create configuration for node server to load translation by node-remote-backend.
 */
function createConfigForServer(
  i18n: i18n,
  config: Partial<InitOptions>,
): InitOptions {
  i18n.use(i18nextHttpBackend);
  i18n.use(ServerLanguageDetector);

  // Available options: https://github.com/i18next/i18next-node-remote-backend/blob/master/src/index.js#L19
  config.backend = {
    ...config.backend,
    // in ms
    reloadInterval: parseInt(TRANSLATION_RELOAD_INTERVAL_MIN, 10) * 60 * 1000,
  };

  // Preload translations for all languages on server start
  config.ns = ALL_NAMESPACES;
  config.preload = [...(config.supportedLngs as string[])];
  config.missingKeyHandler = missingKeyHandler;

  return config;
}

/**
 * Create configuration for browser client to load translation by xhr-backend
 */
function createConfigForClient(
  i18n: i18n,
  config: Partial<InitOptions>,
): Partial<InitOptions> {
  i18n.use(i18nextHttpBackend);
  i18n.use(LanguageDetector);

  config.backend = {
    ...config.backend,
    allowMultiLoading: true,
  };

  config.missingKeyHandler = missingKeyHandler;

  return config;
}

const captureI18nInitException = (err: unknown): void => {
  // i18next might return an array of errors
  for (const error of castArray(err)) {
    // TODO: This currently happens a lot we should investigate why :)
    //       Not logging this to Sentry as it creates a lot of noise.
    //       I suspect this happens mostly during because the translation
    //       service is not available there.
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

/**
 * Create i18next instance depending on client or server.
 */
export function createInstance(
  i18n = createI18nextInstance(),
  config: Partial<InitOptions> = defaultConfig,
  customHeaders?: Record<string, string>,
): [i18n, InitOptions, Promise<unknown>] {
  let initPromise = Promise.resolve<unknown>(null);

  if (!i18n?.isInitialized) {
    const copiedDefaultConfig: Partial<InitOptions> = {
      ...config,
      backend: { ...config.backend, customHeaders },
    };

    // config varies for server and client, so create config depending on where it runs
    const adaptedConfig =
      // @ts-ignore
      typeof window === 'object'
        ? createConfigForClient(i18n, copiedDefaultConfig)
        : createConfigForServer(i18n, copiedDefaultConfig);

    initPromise = i18n
      .init(adaptedConfig, (err) => {
        if (err) {
          captureI18nInitException(err);
        }
      })
      .catch(captureI18nInitException);
  }

  return [i18n, i18n.options, initPromise];
}
