import { InternalAxiosRequestConfig } from 'axios';
import { fetchConfig } from 'utils/fetchConfig';

/**
 *
 * To connect to our INT environment, this interceptor automatically populates
 * the `cf-access-token` header if CF_ACCESS_TOKEN or NEXT_PUBLIC_CF_ACCESS_TOKEN
 * variables are set.
 */
export const cloudFlareInterceptor = async (
  request: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const clientId =
    process.env.CF_CLIENT_ID || process.env.NEXT_PUBLIC_CF_CLIENT_ID;
  const clientSecret =
    process.env.CF_CLIENT_SECRET || process.env.NEXT_PUBLIC_CF_CLIENT_SECRET;

  if (clientId && clientSecret) {
    if (!request.headers.has('cf-access-client-id')) {
      request.headers['cf-access-client-id'] = clientId;
    }

    if (!request.headers.has('cf-Access-client-secret')) {
      request.headers['cf-access-client-secret'] = clientSecret;
    }
  }

  // attach basic auth bypass header for test environment (server side only)
  if (process.env.ENVIRONMENT === 'TEST' && typeof window === 'undefined') {
    const secretsConfig = await fetchConfig();

    if (!request.headers.has('fs24-bypass-basic-auth')) {
      request.headers['fs24-bypass-basic-auth'] =
        secretsConfig.FS24_BYPASS_BASIC_AUTH;
    }
  } else {
    if (request.headers.has('fs24-bypass-basic-auth')) {
      delete request.headers['fs24-bypass-basic-auth'];
    }
  }

  return request;
};
