/* eslint-disable import/prefer-default-export */
import {
  NS_GETPREMIUM,
  NS_PREMIUM_COMMON,
  NS_REQUEST_OFFER,
} from 'utils/namespace-const';
import Translatable from 'utils/Translatable';
import AlertMessage from './AlertMessage';

export class OfferAlertMessage {
  private static ALERT_SUCCESS = (params) =>
    AlertMessage.createTranslatedMessage(
      `${NS_REQUEST_OFFER}.alert.success`,
      params,
    );

  private static ALERT_ERROR = AlertMessage.createTranslatedMessage(
    `${NS_PREMIUM_COMMON}.alert.error`,
  );

  static createSuccessMessage(insuranceName: string, amount: number) {
    const insurance = AlertMessage.createTranslatedParam(
      `${NS_GETPREMIUM}.insurance-${insuranceName}`,
    );
    const count = new Translatable(String(amount), false);
    return this.ALERT_SUCCESS({ count, insurance });
  }

  static createErrorMessage() {
    return this.ALERT_ERROR;
  }
}
