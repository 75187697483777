import _get from 'lodash/get';

import {
  PROP_INSURANCEHOLDER_DETAILS,
  PROP_MAINDRIVER_DETAILS,
} from 'utils/namespace-const';
import { ID_EMAIL } from '../const';
import { makeRequestOfferInput, setInquiry } from './RequestOfferInput';

export default class RequestOfferInputUtils {
  static createRequestOffer(inquiry) {
    let request = makeRequestOfferInput();
    request = setInquiry(request, inquiry);
    return request;
  }

  static updateRequestOffer(request) {
    const newRequest = Object.assign(makeRequestOfferInput(), request);

    if (newRequest.sameAddress) {
      const { street, houseNumber, city } =
        newRequest.insuranceHolderPersonalDetails;
      Object.assign(newRequest.mainDriverPersonalDetails, {
        street,
        houseNumber,
        city,
      });
    }
    return newRequest;
  }

  static getDriverEmail(request) {
    const driver =
      _get(request, PROP_INSURANCEHOLDER_DETAILS) ||
      _get(request, PROP_MAINDRIVER_DETAILS);
    return _get(driver, ID_EMAIL);
  }

  static copyRequestOffer(request) {
    return Object.assign(makeRequestOfferInput(), request);
  }
}
