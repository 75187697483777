import { RESUME_LONG_INQUIRY } from 'state/InquiryInput/actions';
import RequestOfferInputUtils from '../../containers/OfferPage/components/RequestOffer/models/RequestOfferInputUtils';
import {
  SEND_OFFER_REQUEST,
  SEND_OFFER_REQUEST_FAILED,
  SEND_OFFER_REQUEST_FULFILLED,
  UPDATE_OFFER_REQUEST,
  OFFER_VALIDATION_CHANGED,
  RESUME_OFFER_REQUEST,
  SET_SELECTED_OFFERS,
  RESET_OFFER_STATE,
  SET_UPSELLING_OPTIONS,
} from './actions';
import { OfferPageState, OfferRequestInput } from './types';

const initialState: OfferPageState = {
  isSendingOffer: false,
  hasFailed: false,
  hasSucceeded: false,
  offerRequestInput: null,
  validation: undefined,
  validated: false,
  scrollToValidation: false,
  previousOfferRequest: null,
  selectedOffers: [],
  upSellingOptions: [],
};

const reducer = (state = initialState, action): OfferPageState => {
  switch (action.type) {
    case SEND_OFFER_REQUEST:
      return {
        ...state,
        isSendingOffer: true,
        hasFailed: false,
        hasSucceeded: false,
        scrollToValidation: false,
      };
    case SEND_OFFER_REQUEST_FAILED:
      return {
        ...state,
        isSendingOffer: false,
        hasFailed: true,
        hasSucceeded: false,
        scrollToValidation: false,
      };
    case SEND_OFFER_REQUEST_FULFILLED:
      return {
        ...state,
        isSendingOffer: false,
        hasFailed: false,
        hasSucceeded: true,
        scrollToValidation: false,
        previousOfferRequest: RequestOfferInputUtils.copyRequestOffer(
          state.offerRequestInput,
        ),
      };
    case RESET_OFFER_STATE:
      return {
        ...state,
        isSendingOffer: false,
        hasFailed: false,
        hasSucceeded: false,
      };
    case RESUME_LONG_INQUIRY:
      return {
        ...state,
        offerRequestInput: RequestOfferInputUtils.createRequestOffer(
          action.inputs,
        ) as OfferRequestInput,
        scrollToValidation: false,
      };
    case UPDATE_OFFER_REQUEST:
      return {
        ...state,
        offerRequestInput: RequestOfferInputUtils.updateRequestOffer(
          action.offerRequestInput,
        ),
        scrollToValidation: false,
      };
    case SET_SELECTED_OFFERS:
      return {
        ...state,
        selectedOffers: action.selectedOffers,
      };
    case OFFER_VALIDATION_CHANGED:
      return {
        ...state,
        validation: action.validation,
        validated: true,
        scrollToValidation: action.scrollToValidation,
      };
    case RESUME_OFFER_REQUEST:
      return {
        ...state,
        offerRequestInput: RequestOfferInputUtils.copyRequestOffer(
          state.previousOfferRequest,
        ),
        previousOfferRequest: undefined,
      };
    case SET_UPSELLING_OPTIONS:
      return {
        ...state,
        upSellingOptions: action.upSellingOptions,
      };
    default:
      return state;
  }
};

export default reducer;
