import {
  INQUIRY_SEARCH_TYPE_CHANGED,
  SAVE_LONG_INQUIRY,
} from 'state/InquiryInput/actions';
import { STORE_AS24_USER_ID } from 'state/InsuranceInquiry/actions';
import {
  CLEAR_VEHICLE,
  FETCH_VEHICLE_FULFILLED,
} from 'state/VehicleData/actions';
import { InsuranceInquiryUtils } from 'models/InsuranceInquiry';
import {
  setUsageDetails,
  setDriver,
} from 'models/InsuranceInquiry/InsuranceInquiry';
import {
  STEP_VEHICLE,
  STEP_DRIVER,
  STEP_INCIDENTS,
  STEP_INSURANCE_OPTIONS,
} from 'utils/namespace-const';
import { START_ADJUSTING_DATA } from '../AdjustingData/actions';
import {
  INQUIRY_INPUT_CHANGED,
  INQUIRY_STEP_CHANGED,
  VALIDATION_CHANGED,
  LOAD_LONG_INQUIRY,
  CREATE_LONG_INQUIRY,
  RESUME_LONG_INQUIRY,
} from './actions';
import { InquiryInput, InquiryInputState, Vehicle } from './types';

const inputSetters = {
  driver: setDriver,
  usageDetails: setUsageDetails,
};

export function setInput(inputs, key, value) {
  if (!inputs[key]) {
    return inputs;
  }

  const setter = inputSetters[key];

  // If there is a setter defined, call the setter,
  if (setter) {
    return setter(inputs, value);
  }

  // otherwise set the value.
  return {
    ...inputs,
    [key]: value,
  };
}

const updateInput = (state, input, nameOverride) => {
  const {
    step: { name },
    inputs,
  } = state;
  const modifiedInput = { ...input };
  delete modifiedInput['success-events'];

  return setInput(inputs, nameOverride || name, modifiedInput);
};

const getIsStepValid = (index, action) => {
  switch (index) {
    case STEP_VEHICLE.index:
      return action.isStepVehicleValid;
    case STEP_DRIVER.index:
      return action.isStepDriverValid;
    case STEP_INSURANCE_OPTIONS.index:
      return action.isStepInsuranceOptionsValid;
    case STEP_INCIDENTS.index:
      return action.isStepIncidentsValid;
    default:
      return true;
  }
};

const updateStep = (state, step, action) => {
  let { stepReached } = state;
  const { index } = step;
  if (index && (!stepReached || stepReached < index)) {
    stepReached = index;
  }

  const isStepValid = getIsStepValid(index, action);
  if (!isStepValid) {
    stepReached = index;
  }

  return {
    step,
    stepReached,
    validation: undefined,
    validated: false,
    scrollToValidation: false,
  };
};

const longInquiryInitialStateFrom = (step): InquiryInputState => {
  return {
    step,
    stepReached: STEP_VEHICLE.index,
    validation: undefined,
    validated: false,
    scrollToValidation: false,
  };
};

const longInquiryInitialState = (): InquiryInputState => {
  return longInquiryInitialStateFrom(STEP_VEHICLE);
};

const resumeLongInquiryInitialState: InquiryInputState = {
  step: STEP_INSURANCE_OPTIONS,
  stepReached: STEP_INCIDENTS.index,
  validation: undefined,
  validated: false,
  scrollToValidation: false,
  pristine: true,
};

const isPageValid = (action) =>
  action.isStepVehicleValid &&
  action.isStepDriverValid &&
  action.isStepInsuranceOptionsValid &&
  action.isStepIncidentsValid;

const inquiryInputReducer = (
  state = {} as InquiryInputState,
  action,
): InquiryInputState => {
  switch (action.type) {
    case INQUIRY_INPUT_CHANGED:
      return {
        ...state,
        inputs: updateInput(state, action.input, action.nameOverride),
        scrollToValidation: false,
        pristine: false,
      };
    case INQUIRY_STEP_CHANGED:
      return {
        ...state,
        ...updateStep(state, action.step, action),
        isInquiryPageValid: isPageValid(action),
        isStepVehicleValid: action.isStepVehicleValid,
        isStepDriverValid: action.isStepDriverValid,
        isStepInsuranceOptionsValid: action.isStepInsuranceOptionsValid,
        isStepIncidentsValid: action.isStepIncidentsValid,
      };
    case VALIDATION_CHANGED:
      return {
        ...state,
        validation: action.validation,
        validated: true,
        scrollToValidation: action.scrollToValidation,
      };
    case CREATE_LONG_INQUIRY:
      return {
        ...longInquiryInitialState(),
        keyFromParam: action.keyFromParam,
      };
    case LOAD_LONG_INQUIRY:
      return {
        ...state,
        inputs: action.inputs,
        pristine: true,
        isInquiryPageValid: true,
        isStepVehicleValid: true,
        isStepDriverValid: true,
        isStepInsuranceOptionsValid: true,
        isStepIncidentsValid: true,
      };
    case RESUME_LONG_INQUIRY:
      return {
        ...(action.fromPreviousInquiry
          ? longInquiryInitialStateFrom(state.step)
          : resumeLongInquiryInitialState),
        inputs: {
          ...action.inputs,
          vehicle: (state.inputs || action.inputs).vehicle,
          as24UserId:
            (state.inputs && state.inputs.as24UserId) ||
            (action.inputs && action.inputs.as24UserId),
        },
        isInquiryPageValid: true,
        isStepVehicleValid: true,
        isStepDriverValid: true,
        isStepInsuranceOptionsValid: true,
        isStepIncidentsValid: true,
      };
    case FETCH_VEHICLE_FULFILLED: {
      if (state.inputs) {
        const newState: Partial<InquiryInputState> = { ...state };
        newState.inputs = InsuranceInquiryUtils.updateVehicle(
          newState.inputs as InquiryInput,
          action.payload,
        ) as InquiryInput;

        return newState;
      }
      return state;
    }
    case CLEAR_VEHICLE: {
      if (state.inputs) {
        const newState: Partial<InquiryInputState> = { ...state };
        newState.inputs.vehicle = {
          typeName: '',
        } as Vehicle;
        return newState;
      }
      return state;
    }
    case START_ADJUSTING_DATA: {
      if (action.stepOverride) {
        return {
          ...state,
          step: action.stepOverride,
        };
      }

      return {
        ...state,
        isInquiryPageValid: true,
        isStepVehicleValid: true,
        isStepDriverValid: true,
        isStepInsuranceOptionsValid: true,
        isStepIncidentsValid: true,
      };
    }
    case SAVE_LONG_INQUIRY: {
      const newState: InquiryInputState = { ...state };
      newState.isInquiryPageValid = true;
      newState.isStepVehicleValid = true;
      newState.isStepDriverValid = true;
      newState.isStepInsuranceOptionsValid = true;
      newState.isStepIncidentsValid = true;
      return newState;
    }
    case STORE_AS24_USER_ID: {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          as24UserId: action.payload,
        },
      };
    }
    case INQUIRY_SEARCH_TYPE_CHANGED: {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          vehicle: { searchType: action.payload },
        },
      };
    }
    default:
      return state;
  }
};

export default inquiryInputReducer;
