import AlertMessage from '../AlertMessage';
import { ERROR, SUCCESS, WARNING } from './types';
import { SHOW_ALERT, HIDE_ALERT } from './actions';

export type AlertType = typeof SUCCESS | typeof ERROR | typeof WARNING | null;
export interface AlertState {
  type: AlertType;
  messages: AlertMessage[];
}

const initialState = {
  type: null,
  messages: [],
};

const alertReducer = (state: AlertState = initialState, action): AlertState => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        type: action.alertType,
        messages: action.messages,
      };
    case HIDE_ALERT:
      return initialState;
    default:
      return state;
  }
};

export default alertReducer;
