import getCircularReplacer from 'utils/jsonUtils';

export default class LocalStorageUtils {
  static inMemoryFallback = new Map();

  static isAvailable() {
    let hasLocalStorage = false;
    try {
      if (localStorage) {
        const x = 't';
        localStorage.setItem(x, x);
        localStorage.removeItem(x);
        hasLocalStorage = true;
      }
    } catch (e) {
      hasLocalStorage = false;
    }

    return hasLocalStorage;
  }

  static set(key: string, value: any) {
    if (this.isAvailable()) {
      localStorage.setItem(key, value);
    } else {
      LocalStorageUtils.inMemoryFallback.set(key, value);
    }
  }

  static get(key: string) {
    if (this.isAvailable()) {
      return localStorage.getItem(key);
    }

    const value = LocalStorageUtils.inMemoryFallback.get(key);
    return value || null;
  }

  static getJson<T>(key: string) {
    return JSON.parse(LocalStorageUtils.get(key)) as T;
  }

  static setJson(key: string, value: any) {
    const json = JSON.stringify(value, getCircularReplacer());

    if (this.isAvailable()) {
      localStorage.setItem(key, json);
    } else {
      LocalStorageUtils.inMemoryFallback.set(key, json);
    }
  }

  static clear() {
    if (this.isAvailable()) {
      localStorage.clear();
    } else {
      // @ts-ignore
      LocalStorageUtils.inMemoryFallback.forEach((value, key) => {
        LocalStorageUtils.inMemoryFallback.set(key, null);
      });
    }
  }
}
