import {
  FETCH_INSURANCE_COMPARISON,
  FETCH_INSURANCE_COMPARISON_FAILED,
  FETCH_INSURANCE_COMPARISON_FULFILLED,
  CLEAR_PRODUCT_BUNDLE_IDS,
  DESELECT_PRODUCT_BUNDLE_ID,
  ActionType,
} from './actions';
import type { InsuranceComparison } from './types';

type PremiumDetailState = {
  fetchingInsuranceComparison: boolean;
  fetchingInsuranceComparisonFailed: boolean;
  insuranceComparison: InsuranceComparison | null;
  productBundleIds: number[];
  insuranceInquiryKey: string | null;
};

const initialState: PremiumDetailState = {
  fetchingInsuranceComparison: false,
  fetchingInsuranceComparisonFailed: false,
  insuranceComparison: null,
  productBundleIds: [],
  insuranceInquiryKey: null,
};

const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case FETCH_INSURANCE_COMPARISON:
      return {
        ...state,
        fetchingInsuranceComparison: true,
        fetchingInsuranceComparisonFailed: false,
        productBundleIds: action.productBundleIds,
        insuranceInquiryKey: action.insuranceInquiryKey,
      };
    case FETCH_INSURANCE_COMPARISON_FULFILLED:
      return {
        fetchingInsuranceComparison: false,
        fetchingInsuranceComparisonFailed: false,
        insuranceComparison: action.payload,
        productBundleIds: action.productBundleIds,
        insuranceInquiryKey: action.insuranceInquiryKey,
      };
    case FETCH_INSURANCE_COMPARISON_FAILED:
      return {
        fetchingInsuranceComparison: false,
        fetchingInsuranceComparisonFailed: true,
        insuranceComparison: null,
        productBundleIds: action.productBundleIds,
        insuranceInquiryKey: action.insuranceInquiryKey,
      };
    case CLEAR_PRODUCT_BUNDLE_IDS:
      return {
        ...state,
        productBundleIds: [],
      };
    case DESELECT_PRODUCT_BUNDLE_ID:
      return {
        ...state,
        productBundleIds: state.productBundleIds.filter(
          (id) => id !== action.productBundleId,
        ),
        insuranceComparison: {
          ...state.insuranceComparison,
          productBundleDetails:
            state.insuranceComparison.productBundleDetails.filter(
              (productBundleDetail) =>
                productBundleDetail.productBundleId !== action.productBundleId,
            ),
        },
      };
    default:
      return state;
  }
};

export default reducer;
