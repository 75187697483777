import { Error500 } from '@scout24ch/fs24-design-system';
import { SeverityLevel } from '@sentry/node';
import * as React from 'react';
import { captureException } from '../../utils/sentry';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export interface ErrorBoundaryState {
  readonly hasError?: boolean;
  readonly error?: Error;
}

/**
 * Catches possible errors during the render/reconcililation
 * tasks of react, can be used for catching errors of any kind.
 */
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public static displayName = 'ErrorBoundary';

  state: ErrorBoundaryState = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error) {
    captureException(error, {
      level: 'fatal' as SeverityLevel,
    });
  }

  public render() {
    if (this.state.hasError) {
      return <Error500 />;
    }

    return this.props.children;
  }
}
