import genderEmpty from './genderEmpty.json';
import dateOfBirthInvalid from './dateOfBirthInvalid.json';
import dateOfBirthGreaterThan from './dateOfBirthGreaterThan.json';
import dateOfBirthLesserThan from './dateOfBirthLesserThan.json';
import dateOfDrivingLicenseInvalid from './dateOfDrivingLicenseInvalid.json';
import dateOfDrivingLicenseGreaterThan from './dateOfDrivingLicenseGreaterThan.json';
import dateOfDrivingLicenseLessThan from './dateOfDrivingLicenseLessThan.json';
import nationalityEmpty from './nationalityEmpty.json';
import residencePermitEmpty from './residencePermitEmpty.json';
import residencePermitSinceInvalid from './residencePermitSinceInvalid.json';
import residencePermitSinceGreaterThan from './residencePermitSinceGreaterThan.json';
import residencePermitSinceLessThan from './residencePermitSinceLessThan.json';
import driverDateOfBirthInvalid from './driverDateOfBirthInvalid.json';
import driverDateOfBirthGreaterThan from './driverDateOfBirthGreaterThan.json';
import driverDateOfBirthLesserThan from './driverDateOfBirthLesserThan.json';
import driverGenderEmpty from './driverGenderEmpty.json';
import driverNationalityEmpty from './driverNationalityEmpty.json';
import driverResidencePermitEmpty from './driverResidencePermitEmpty.json';
import driverResidencePermitSinceInvalid from './driverResidencePermitSinceInvalid.json';
import driverResidencePermitSinceGreaterThan from './driverResidencePermitSinceGreaterThan.json';
import driverResidencePermitSinceLessThan from './driverResidencePermitSinceLessThan.json';
import driversOutsideTheHouseholdEmpty from './driversOutsideTheHouseholdEmpty.json';
import driversUnderTheAgeOf25Empty from './driversUnderTheAgeOf25Empty.json';
import driversWithLearningOrTrialLicenseEmpty from './driversWithLearningOrTrialLicenseEmpty.json';
import isMainDriverEmpty from './isMainDriverEmpty.json';
import otherDriversEmpty from './otherDriversEmpty.json';
import postCodeInvalid from './postCodeInvalid.json';
import driverPostCodeInvalid from './driverPostCodeInvalid.json';
import mainDriverEmailInvalid from './mainDriverEmailInvalid.json';
import mainDriverEmailMandatoryInvalid from './mainDriverEmailMandatoryInvalid.json';
import policyHolderEmailInvalid from './policyHolderEmailInvalid.json';
import cityInvalid from './cityInvalid.json';
import cantonInvalid from './cantonInvalid.json';

export default {
  genderEmpty,
  dateOfBirthInvalid,
  dateOfBirthGreaterThan,
  dateOfBirthLesserThan,
  dateOfDrivingLicenseInvalid,
  dateOfDrivingLicenseGreaterThan,
  dateOfDrivingLicenseLessThan,
  nationalityEmpty,
  residencePermitEmpty,
  residencePermitSinceInvalid,
  residencePermitSinceGreaterThan,
  residencePermitSinceLessThan,
  isMainDriverEmpty,
  otherDriversEmpty,
  postCodeInvalid,
  driversWithLearningOrTrialLicenseEmpty,
  driversUnderTheAgeOf25Empty,
  driversOutsideTheHouseholdEmpty,
  driverResidencePermitSinceInvalid,
  driverResidencePermitSinceGreaterThan,
  driverResidencePermitEmpty,
  driverResidencePermitSinceLessThan,
  driverNationalityEmpty,
  driverGenderEmpty,
  driverDateOfBirthInvalid,
  driverDateOfBirthGreaterThan,
  driverDateOfBirthLesserThan,
  driverPostCodeInvalid,
  mainDriverEmailInvalid,
  policyHolderEmailInvalid,
  cityInvalid,
  cantonInvalid,
  mainDriverEmailMandatoryInvalid,
};
