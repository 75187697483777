import moment from 'moment';
import _findLast from 'lodash/findLast';
import _find from 'lodash/find';
import _range from 'lodash/range';
import i18next from 'i18next';
import { isEmptyOrNone } from 'utils/operators';
import { isNil } from 'utils';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { getLng } from 'utils/i18n/utils';
import { LookupItem } from 'utils/types';
import { LookupData } from 'state/LookUpData/reducer';
import { makeUsageDetails } from './UsageDetails';
import { makeIndividual } from './Individual';
import { makeDriverDetails } from './DriverDetails';
import { makeInsuranceOptions } from './InsuranceOptions';
import { makeIncidents } from './Incidents';
import {
  clearKey,
  clearOriginalInquiryKey,
  clearPolicyHolder,
  FLOW_TYPE_LONG,
  InsuranceInquiry,
  makeInsuranceInquiry,
  setUsageDetails,
} from './InsuranceInquiry';
import { Incidents, Person } from '../../state/InquiryInput/types';
import { getVehicleFullName } from '../../utils/vehicleUtils';

function isStringOrNumber(value: unknown): value is string | number {
  return typeof value === 'string' || typeof value === 'number';
}

export const defaultLookupValue: LookupItem = {
  key: 0,
  name: 'None',
  displayName: 'None',
};

const defaultLanguage = {
  key: 2,
  name: 'de',
  displayName: 'Deutsch',
};

// TODO - Refactor this class
class InsuranceInquiryUtils {
  static defaultUsageDetails() {
    const usageDetails = makeUsageDetails();

    usageDetails.kilometerPerYear = IS_AUTO_INSURANCE ? null : 2000;
    usageDetails.registrationCanton = defaultLookupValue;
    usageDetails.expectedPurchaseYear = null;

    return usageDetails;
  }

  static defaultMainDriver(): Person {
    const mainDriver = makeIndividual();

    mainDriver.isMainDriver = true;
    mainDriver.nationality = defaultLookupValue;
    mainDriver.postCode = '';
    mainDriver.emailAddress = '';

    const driverDetails = makeDriverDetails();
    driverDetails.numberOfChildren = -1;
    driverDetails.ageOfTheYoungestChild = '';

    mainDriver.driverDetails = driverDetails;

    return mainDriver;
  }

  static defaultPolicyHolder(): Person {
    const policyHolder = makeIndividual();

    policyHolder.isMainDriver = false;
    policyHolder.isPolicyHolder = true;
    policyHolder.nationality = defaultLookupValue;
    policyHolder.postCode = '';
    policyHolder.emailAddress = '';

    return policyHolder;
  }

  static defaultIncidents(): Incidents {
    const incidents = makeIncidents();

    incidents.isAdditionalQuestions = null;
    incidents.claims = [];
    incidents.infringements = [];
    incidents.suspensionOfDrivingLicense = [];

    return incidents;
  }

  static defaultInsuranceOptions(dateOfFirstRegistration) {
    const insuranceOptions = makeInsuranceOptions();

    insuranceOptions.isLiabilityInsurance = true;
    insuranceOptions.isPartialCoverInsurance = false;
    insuranceOptions.isFullyComprehensiveInsurance = false;
    insuranceOptions.isAccidentInsuranceMedicalExpenses = true;
    insuranceOptions.accidentInsuredPersons = defaultLookupValue;
    insuranceOptions.insuranceStartDate = dateOfFirstRegistration;
    insuranceOptions.partialCoverInsuranceCostSharing = defaultLookupValue;
    insuranceOptions.freeGarageChoice = defaultLookupValue;
    insuranceOptions.fullyComprehensiveInsuranceCostSharing =
      defaultLookupValue;

    return insuranceOptions;
  }

  static createDefaultInquiry(vehicle) {
    let inquiry = makeInsuranceInquiry();

    inquiry = setUsageDetails(inquiry, this.defaultUsageDetails());
    inquiry.mainDriver = this.defaultMainDriver();
    inquiry.policyHolder = this.defaultPolicyHolder();
    inquiry.incidents = this.defaultIncidents();
    inquiry.flowType = FLOW_TYPE_LONG;

    inquiry.insuranceOptions = this.defaultInsuranceOptions(
      this.getInsuranceDate(vehicle),
    );
    inquiry = this.updateVehicle(inquiry, vehicle);
    return inquiry;
  }

  static updateVehicle(inquiry, vehicle) {
    const newInquiry = inquiry;
    const isPerfectMatch = Boolean(
      vehicle?.eurotaxId && vehicle?.eurotaxMatches?.length === 1,
    );

    if (!isNil(inquiry) && !isNil(vehicle)) {
      const searchType = inquiry.vehicle?.searchType;
      newInquiry.vehicle = vehicle;

      if (searchType) {
        newInquiry.vehicle.searchType = searchType;
      }

      if (!isNil(newInquiry.usageDetails)) {
        newInquiry.usageDetails.priceOfAccessories =
          vehicle.priceOfAccessories || 0;
        if (vehicle.mileage) {
          newInquiry.usageDetails.mileage = vehicle.mileage;
        }

        if (isPerfectMatch) {
          // AGGR-4750 If the vehicle is a perfect AS24 match, preselect possesion field to 'NO'
          newInquiry.usageDetails.expectedPurchaseYear = -1;
        } else if (vehicle.yearOfPurchase) {
          newInquiry.usageDetails.expectedPurchaseYear = vehicle.yearOfPurchase;
        } else if (isNil(newInquiry.usageDetails.expectedPurchaseYear)) {
          newInquiry.usageDetails.expectedPurchaseYear = null;
        }

        if (vehicle.dateOfFirstRegistration) {
          const date = moment(vehicle.dateOfFirstRegistration);
          newInquiry.usageDetails.firstRegistrationMonth = date.month() + 1;
          newInquiry.usageDetails.firstRegistrationYear = date.year();
        }
      }

      if (!isNil(newInquiry.insuranceOptions)) {
        newInquiry.insuranceOptions.insuranceStartDate =
          this.getInsuranceStartDate(inquiry);
      }

      return newInquiry;
    }

    newInquiry.vehicle = {
      typeName: '',
    };

    return newInquiry;
  }

  static deleteUnusedVehicleValues(vehicle) {
    const v = vehicle;
    delete v.fuelType;
    delete v.color;
    delete v.isMetallic;
    delete v.conditionType;
    delete v.sellingPrice;
    return v;
  }

  static prepareInquiry(
    inquiryParam,
    lookUpData = {} as LookupData,
    insuranceInquiryId,
    oldInquiry,
    fetchPreviousInquiry,
    keyFromParam,
    fromQuickAdjust,
  ) {
    if (isNil(inquiryParam)) {
      return {};
    }

    const inquiry = inquiryParam;
    const {
      mainDriver: { driverDetails },
      mainDriver: {
        driverDetails: { otherDrivers },
      },
      insuranceOptions,
      insuranceOptions: {
        isFullyComprehensiveInsurance,
        isPartialCoverInsurance,
        isParkingDamage,
        isParkingDamageSumUnlimited,
        hasExistingParkingDamage,
      },
      vehicle,
      usageDetails,
    } = inquiry;

    inquiry.language =
      (lookUpData?.languages ?? []).find(
        (lng) => lng.name === getLng(i18next),
      ) ?? defaultLanguage;

    if (otherDrivers === false) {
      driverDetails.driversOutsideTheHousehold = false;
      driverDetails.driversWithLearningOrTrialLicense = false;
      driverDetails.driversUnderTheAgeOf25 = false;
    }
    vehicle.mileage = usageDetails.mileage
      ? parseInt(usageDetails.mileage, 10)
      : 0;
    usageDetails.mileage = vehicle.mileage;
    vehicle.priceOfAccessories = parseInt(usageDetails.priceOfAccessories, 10);
    vehicle.fullName = getVehicleFullName(vehicle);

    usageDetails.garageAtHome = usageDetails.garageAtHome
      ? usageDetails.garageAtHome
      : false;
    usageDetails.garageAtWork = usageDetails.garageAtWork
      ? usageDetails.garageAtWork
      : false;

    insuranceOptions.isParkingDamage = isPartialCoverInsurance
      ? isParkingDamage
      : false;
    insuranceOptions.isParkingDamageSumUnlimited = isParkingDamage
      ? isParkingDamageSumUnlimited
      : false;
    insuranceOptions.hasExistingParkingDamage = isParkingDamage
      ? hasExistingParkingDamage
      : false;

    if (
      isFullyComprehensiveInsurance &&
      isEmptyOrNone(insuranceOptions.fullyComprehensiveInsuranceCostSharing)
    ) {
      insuranceOptions.fullyComprehensiveInsuranceCostSharing = _findLast(
        lookUpData.fullyComprehensiveCostSharings,
        { key: 1 },
      );
    }

    if (
      isPartialCoverInsurance &&
      isEmptyOrNone(insuranceOptions.freeGarageChoice)
    ) {
      insuranceOptions.freeGarageChoice = _findLast(
        lookUpData.freeGarageChoices,
        { key: 1 },
      );
    }

    if (
      isPartialCoverInsurance &&
      isEmptyOrNone(insuranceOptions.partialCoverInsuranceCostSharing)
    ) {
      insuranceOptions.partialCoverInsuranceCostSharing = _findLast(
        lookUpData.partialCoverCostSharings,
        { key: 1 },
      );
    }

    insuranceOptions.insuranceStartDate = this.getInsuranceStartDate(inquiry);

    if (inquiry.mainDriver.isPolicyHolder) {
      clearPolicyHolder(inquiry);
    }
    inquiry.isQuickAdjust = fromQuickAdjust;

    // grosslyNegligent can only be set to false from quick adjust
    if (!inquiry.isQuickAdjust && !insuranceInquiryId) {
      insuranceOptions.grosslyNegligent = true;
    }

    const key =
      keyFromParam ||
      insuranceInquiryId ||
      (oldInquiry ? oldInquiry.key : null);
    if (key && oldInquiry) {
      const { originalInquiryKey } = oldInquiry;
      inquiry.originalInquiryKey = originalInquiryKey;

      if (fromQuickAdjust) {
        inquiry.originalInquiryKey = originalInquiryKey || key;
      }
    }

    if (fetchPreviousInquiry) {
      clearOriginalInquiryKey(inquiry);
    }
    clearKey(inquiry);
    inquiry.vehicle = this.deleteUnusedVehicleValues(inquiry.vehicle);

    return inquiry;
  }

  static inquiryFromJSON(inquiry, vehicle?, fromOverview = false) {
    // DON'T use Object.assign in this case, because the order of setting a property is important
    let result = makeInsuranceInquiry();

    result.id = inquiry.id;
    result.key = inquiry.key;
    result.language = inquiry.language;
    result.originalInquiryKey = inquiry.originalInquiryKey;
    result.isQuickAdjust = inquiry.isQuickAdjust;
    result.as24UserId = inquiry.as24UserId;

    result.flowType = FLOW_TYPE_LONG;

    result.insuranceOptions = Object.assign(
      makeInsuranceOptions(),
      inquiry.insuranceOptions,
    );
    result = setUsageDetails(
      result,
      Object.assign(makeUsageDetails(), inquiry.usageDetails),
    );

    if (!isNil(inquiry.mainDriver)) {
      result.mainDriver = Object.assign(makeIndividual(), inquiry.mainDriver);
      result.mainDriver.hasAutoDrivingLicense =
        result.mainDriver.dateOfDrivingLicense != null;
      if (!isNil(inquiry.mainDriver.driverDetails)) {
        result.mainDriver.driverDetails = Object.assign(
          makeDriverDetails(),
          inquiry.mainDriver.driverDetails,
        );
      }
    }
    if (isNil(inquiry.policyHolder)) {
      result.policyHolder = Object.assign(
        makeIndividual(),
        this.defaultPolicyHolder(),
      );
    } else {
      result.policyHolder = Object.assign(
        makeIndividual(),
        inquiry.policyHolder,
      );
    }

    result.incidents = Object.assign(makeIncidents(), inquiry.incidents);
    result.incidents.dataProtectionAccepted = inquiry.dataProtectionAccepted;

    let newVehicle = vehicle;
    if (!fromOverview) {
      newVehicle = isNil(vehicle) ? inquiry.vehicle : vehicle;
    }
    result = this.updateVehicle(result, newVehicle);
    return result;
  }

  static isInsuranceStartDateInvalid(inquiry: Partial<InsuranceInquiry>) {
    return (
      moment(inquiry.insuranceOptions.insuranceStartDate).isBefore(
        moment(),
        'day',
      ) ||
      moment(inquiry.insuranceOptions.insuranceStartDate).isBefore(
        inquiry.vehicle.dateOfFirstRegistration,
      )
    );
  }

  static getInsuranceDate(vehicle) {
    let date = moment().add(1, 'd').toDate();

    if (vehicle) {
      const dateOfFirstRegistration = moment(vehicle.dateOfFirstRegistration);
      if (dateOfFirstRegistration.isAfter(date)) {
        date = dateOfFirstRegistration.toDate();
      }
    }

    return date;
  }

  static getInsuranceStartDate(inquiry: Partial<InsuranceInquiry>) {
    if (InsuranceInquiryUtils.isInsuranceStartDateInvalid(inquiry)) {
      return this.getInsuranceDate(inquiry.vehicle);
    }
    return inquiry.insuranceOptions.insuranceStartDate;
  }

  static yearRange = (currentYear: number): number[] =>
    _range(currentYear + 1, currentYear - 21, -1);

  static getValidFirstRegistrationMonth(
    month: number,
    paramMonth: string | number,
  ): number {
    // Take the month data from usageDetails, if month is already selected
    if (month > 0) {
      return month;
    }

    if (isStringOrNumber(paramMonth)) {
      const parsedMonth = parseInt(String(paramMonth), 10);

      if (parsedMonth > 0 && parsedMonth < 13) {
        return parsedMonth;
      }
    }

    return month;
  }

  static getValidFirstRegistrationYear(
    year: number,
    paramYear: string | number,
  ): number {
    // Take the year data from usageDetails, if year is selected
    if (year > 0) {
      return year;
    }

    if (isStringOrNumber(paramYear)) {
      const parsedYear = parseInt(String(paramYear), 10);

      // ordered by desc
      const validYears = InsuranceInquiryUtils.yearRange(moment().year());

      if (
        parsedYear >= validYears[validYears.length - 1] &&
        parsedYear <= validYears[0]
      ) {
        return parsedYear;
      }
    }

    return year;
  }
}

export default InsuranceInquiryUtils;
