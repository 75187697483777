import * as React from 'react';
import { useAuth0 } from '@scout24ch/fs24-auth-react';
import { useOnMount } from '@scout24ch/fs24-hooks';
import {
  GoogleTagManagerProvider,
  GTMOptions,
  GUID_REGEX,
  useGoogleTagManager,
} from '@scout24ch/fs24-gtm-react';
import { castArray } from '@scout24ch/fs24-utils';
import { useRouter } from 'next/router';
import { useLocale } from 'hooks/useLocale';
import {
  ENVIRONMENT,
  GTM_AUTH,
  GTM_ENV,
  GTM_ID,
  IS_AUTO_INSURANCE,
} from '../../utils/const';
import { PAGEVIEW_CATEGORY } from '../../utils/GTM/events';

const ROOT_URL = IS_AUTO_INSURANCE ? 'car-insurance/' : 'moto-insurance/';

// The first match is used in pageviews
const evppMapping = new Map([
  ['/inquiry[?]step=usageDetails', `${ROOT_URL}comparison/1-vehicle-data`],
  ['/inquiry[?]step=driver', `${ROOT_URL}comparison/2-driver-data`],
  ['/inquiry[?]step=insuranceOptions', `${ROOT_URL}comparison/3-coverage-data`],
  ['/inquiry[?]step=incidents', `${ROOT_URL}comparison/4-incidents-data`],
  [`/premiums/${GUID_REGEX}[?]?[^/]*$`, `${ROOT_URL}comparison/5-resultlist`],
  [
    `/premiums/${GUID_REGEX}/details\\?ids=[0-9]+&ids=[0-9]`,
    `${ROOT_URL}comparison/6-details-multiple-offers`,
  ],
  [
    `/premiums/${GUID_REGEX}/details`,
    `${ROOT_URL}comparison/6-details-single-offer`,
  ],
  [
    `/premiums/${GUID_REGEX}/offer-confirmation?`,
    `${ROOT_URL}comparison/8-offer-confirmation`,
  ],
  [`/premiums/${GUID_REGEX}/offer?`, `${ROOT_URL}comparison/7-request-offer`],
  ['/error', `${ROOT_URL}comparison/error`],
  ['/direct-deal/personal-data', `${ROOT_URL}comparison/9-direct-contactdata`],
  ['/direct-deal/vehicle', `${ROOT_URL}comparison/10-direct-vehicle`],
  ['/direct-deal/confirm', `${ROOT_URL}comparison/11-direct-overview`],
  [
    `/premiums/${GUID_REGEX}/direct-deal/result?`,
    `${ROOT_URL}comparison/12-direct-confirmation`,
  ],
  ['/inquiry', `${ROOT_URL}comparison/1-vehicle-data`],
]);

const config: GTMOptions = {
  id: GTM_ID,
  auth: GTM_AUTH,
  preview: GTM_ENV,
  beagleEnv: ENVIRONMENT === 'PROD' ? 'prod' : 'dev',
  evppMapping,
};

const GtmPageView: React.FC = () => {
  const router = useRouter();
  const gtm = useGoogleTagManager();

  useOnMount(() => {
    const triggerGtmPageView = () => {
      if (!gtm.shouldSkipPageView()) {
        gtm.pageView({
          primaryCategory: PAGEVIEW_CATEGORY,
          secondaryCategory: 'Funnel',
          productCategory: PAGEVIEW_CATEGORY,
        });
      }
    };

    triggerGtmPageView();

    router.events.on('routeChangeComplete', triggerGtmPageView);

    return () => router.events.off('routeChangeComplete', triggerGtmPageView);
  });

  return null;
};

export interface GoogleTagManagerProps {
  ignoredPaths?: string | string[];
  children: React.ReactNode;
}

export const GoogleTagManager: React.FC<GoogleTagManagerProps> = (props) => {
  const { children } = props;
  const ignoredPaths = castArray(props.ignoredPaths);

  const locale = useLocale();
  const { user } = useAuth0();
  const router = useRouter();

  const ignoredRoute = React.useMemo(
    () =>
      ignoredPaths
        .map((path) => new URL(path))
        .some((url) => router.asPath.startsWith(url.pathname)),
    [router.asPath, ignoredPaths],
  );

  if (ignoredRoute) {
    return <>{children}</>;
  }

  return (
    <GoogleTagManagerProvider
      config={config}
      language={locale}
      userProfile={user as any}
    >
      <GtmPageView />
      {children}
    </GoogleTagManagerProvider>
  );
};

GoogleTagManager.displayName = 'GoogleTagManager';
