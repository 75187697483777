// @ts-ignore
import { Engine } from 'json-rules-engine';

import { isEmpty, isNotDivisible } from 'utils/operators';
import {
  ValidationSuccessFunction,
  ValidationFailureFunction,
} from 'utils/validation/types';
import { UsageDetails } from '../types';
import { validateVehicle } from './VehicleValidationFactory';
import rules from './rules';
import { Vehicle } from '../../../../../state/InquiryInput/types';

const motoValidationEngine = new Engine();
motoValidationEngine.addOperator('empty', isEmpty);
motoValidationEngine.addOperator('notDivisible', isNotDivisible);

motoValidationEngine.addRule(rules.leasingEmpty);
motoValidationEngine.addRule(rules.kmPerYearEmpty);
motoValidationEngine.addRule(rules.kmPerYearLessThan);
motoValidationEngine.addRule(rules.kmPerYearNotDivisible);
motoValidationEngine.addRule(rules.usageEmpty);
motoValidationEngine.addRule(rules.registrationCantonEmpty);
motoValidationEngine.addRule(rules.priceOfAccessoriesEmpty);
motoValidationEngine.addRule(rules.priceOfAccessoriesLessThan);
motoValidationEngine.addRule(rules.firstRegistrationMonthEmpty);
motoValidationEngine.addRule(rules.firstRegistrationYearEmpty);
motoValidationEngine.addRule(rules.yearOfPurchaseEmpty);
motoValidationEngine.addRule(rules.yearOfPurchaseNotSelected);

const validateMotoVehicle = (
  usageDetails: UsageDetails,
  vehicle: Vehicle,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) =>
  validateVehicle(
    motoValidationEngine,
    usageDetails,
    vehicle,
    success,
    failure,
  );

export default validateMotoVehicle;
