import { START_ADJUSTING_DATA, STOP_ADJUSTING_DATA } from './actions';

export interface AdjustingDataState {
  isAdjustingData: boolean;
}

const initialState: AdjustingDataState = {
  isAdjustingData: false,
};

const adjustingDataReducer = (
  state: AdjustingDataState = initialState,
  action,
): AdjustingDataState => {
  switch (action.type) {
    case START_ADJUSTING_DATA:
      return {
        isAdjustingData: true,
      };
    case STOP_ADJUSTING_DATA:
      return {
        isAdjustingData: false,
      };
    default:
      return state;
  }
};

export default adjustingDataReducer;
