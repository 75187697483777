import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  concatMap,
} from 'rxjs/operators';
import { scheduled, asyncScheduler, Observable, from } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import moment from 'moment';
import i18next from 'i18next';
import { getGoogleAnalyticsClientId } from '@scout24ch/fs24-utils';
import { resumeLongInquiry } from 'state/InquiryInput/actions';
import { fetchVehicleFulfilled } from 'state/VehicleData/actions';
import { KEY_LAST_INQUIRY_KEY, KEY_LEGACY_LAST_INQUIRY_KEY } from 'utils/const';
import { isVehicleValidForLP } from 'utils/vehicleUtils';
import { getLng } from 'utils/i18n/utils';
import { clearSelectedPremiumIds } from 'state/PremiumList/actions';
import { InsuranceInquiryUtils } from 'models/InsuranceInquiry';
import { toJSON as inquiryToJSON } from 'models/InsuranceInquiry/InsuranceInquiry';
import CookieUtils from 'utils/cookieUtils';
import { captureException } from 'utils/sentry';
import { startAdjustingData } from '../AdjustingData/actions';
import { clearPolicyHolder } from '../../models/InsuranceInquiry/InsuranceInquiry';
import LocalStorageUtils from '../../utils/LocalStorageUtils';
import {
  SAVE_INQUIRY,
  FETCH_INQUIRY_FULFILLED,
  FETCH_PREVIOUS_INQUIRY,
  fetchInsuranceInquiryFailed,
  fetchInsuranceInquiryFulfilled,
  saveInsuranceInquiryFailed,
  saveInsuranceInquiryFulfilled,
  fetchPreviousInsuranceInquiryAborted,
  saveInsuranceInquiry,
  fetchPreviousInsuranceInquiryFulfilled,
} from './actions';
import {
  getInsuranceInquiries,
  saveNewInsuranceInquiry,
} from '../../api/insuranceInquiries';

const vehicle = ({ value }) => value.vehicleData.vehicle;

const saveLastInquiryKey = (key: string, typeName: string) => {
  CookieUtils.setSharedCookie(
    KEY_LAST_INQUIRY_KEY,
    {
      key,
      timestamp: moment.now(),
      label: typeName,
      path: `/${getLng(i18next)}/premiums/${key}`,
    },
    2,
  );
};

const saveFinished = (inquiryKey, vehicleData) => {
  const typeName = `${vehicleData.makeName} ${vehicleData.typeName}`;
  saveLastInquiryKey(inquiryKey, typeName);
  return saveInsuranceInquiryFulfilled(inquiryKey);
};

const fetchInsurance = ({ payload }) =>
  fromPromise(getInsuranceInquiries(payload)).pipe(
    map(fetchInsuranceInquiryFulfilled),
    catchError((e) =>
      scheduled([fetchInsuranceInquiryFailed(e)], asyncScheduler),
    ),
  );

const prepareActions = (state: any, action: { payload: any }) => {
  const newInquiry = InsuranceInquiryUtils.inquiryFromJSON(
    action.payload,
    vehicle(state),
    true,
  );

  const isVehicleValid = isVehicleValidForLP(newInquiry.vehicle);

  let actions = [];
  if (isVehicleValid) {
    actions = actions.concat(saveInsuranceInquiry(newInquiry));
  }
  actions = actions.concat(
    fetchPreviousInsuranceInquiryFulfilled(newInquiry),
    resumeLongInquiry(newInquiry, true),
    startAdjustingData(),
  );

  return actions;
};

const getPreviousInquiry = (state: any) => {
  let lastInquiry: any = CookieUtils.getCookieValue(KEY_LAST_INQUIRY_KEY);
  if (!lastInquiry) {
    const legacyLastInquiry = LocalStorageUtils.get(
      KEY_LEGACY_LAST_INQUIRY_KEY,
    );
    if (legacyLastInquiry) {
      lastInquiry = JSON.parse(legacyLastInquiry);
    }
  }

  if (lastInquiry) {
    const { key, timestamp } = lastInquiry;
    if (key && moment().diff(timestamp, 'days') <= 30) {
      return fetchInsurance({ payload: key }).pipe(
        concatMap((action) => {
          if (action.type === FETCH_INQUIRY_FULFILLED) {
            const actions = prepareActions(state, action);
            return scheduled(actions, asyncScheduler);
          }
          return [fetchPreviousInsuranceInquiryAborted()];
        }),
      );
    }
  }
  return scheduled([fetchPreviousInsuranceInquiryAborted()], asyncScheduler);
};

export const saveInsuranceInquiryEpic = (
  action$: Observable<{ type: string; payload?: any }>,
  state: any,
) =>
  action$.pipe(
    ofType(SAVE_INQUIRY),
    map((action) => {
      const modifiedAction = action;
      const { mainDriver } = action.payload;

      // remove unnecessary policy holder from payload
      if (mainDriver !== undefined && mainDriver.isPolicyHolder) {
        clearPolicyHolder(modifiedAction.payload);
      }

      modifiedAction.payload.gaClientId = getGoogleAnalyticsClientId();

      modifiedAction.payload = inquiryToJSON(modifiedAction.payload);
      modifiedAction.payload.as24UserId =
        state.value.inquiryInput.inputs.as24UserId;
      modifiedAction.payload.profileEmail =
        state.value.inquiryInput.inputs.mainDriver?.emailAddress;
      modifiedAction.payload.dataProtectionAccepted =
        state.value.inquiryInput.inputs.incidents?.dataProtectionAccepted;

      return modifiedAction;
    }),
    mergeMap(({ payload }) =>
      from(saveNewInsuranceInquiry(payload)).pipe(
        switchMap((response) =>
          scheduled(
            [
              saveFinished(response.data, payload.vehicle),
              clearSelectedPremiumIds(),
            ],
            asyncScheduler,
          ),
        ),
        catchError((e) => {
          captureException(e);

          return scheduled([saveInsuranceInquiryFailed(e)], asyncScheduler);
        }),
      ),
    ),
  );

export const fetchPreviousInsuranceInquiryEpic = (action$, state) =>
  action$.pipe(
    ofType(FETCH_PREVIOUS_INQUIRY),
    mergeMap(() => getPreviousInquiry(state)),
    catchError(() =>
      scheduled([fetchPreviousInsuranceInquiryAborted()], asyncScheduler),
    ),
  );

export const fetchInquiryFulfilledEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_INQUIRY_FULFILLED),
    switchMap(({ payload }) =>
      scheduled(
        [
          fetchVehicleFulfilled(payload.vehicle),
          resumeLongInquiry(
            InsuranceInquiryUtils.inquiryFromJSON(payload),
            false,
          ),
        ],
        asyncScheduler,
      ),
    ),
  );
