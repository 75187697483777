import { UpsellingOption } from '../../types/upselling';

export const CROSSENGAGE_EMAIL_SENT = 'CROSSENGAGE_EMAIL_SENT';
export const FETCH_UPSELLING_OPTIONS_FULFILLED =
  'FETCH_UPSELLING_OPTIONS_FULFILLED';

export const crossEngageEmailSent = () => ({
  type: CROSSENGAGE_EMAIL_SENT,
});

export const fetchUpsellingOptionsFulfilled = (payload: UpsellingOption[]) => ({
  type: FETCH_UPSELLING_OPTIONS_FULFILLED,
  payload,
});
