import { InitOptions } from 'i18next';
import { LanguageCode } from 'utils/language';
import { TRANSLATION_URL, APPLICATION_HOST } from '../const';

const loadPath = TRANSLATION_URL;

export const getTranslationLoadUrl = (
  lang: string,
  namespace: string,
): string => loadPath.replace('{{ns}}', namespace).replace('{{lng}}', lang);

const interpolationFormat = (value: string): string => {
  return value;
};

// Add all namespaces, so that all translations are loaded initially.
// It is IMPORTANT for initial server-side rendering.
export const ALL_NAMESPACES = [
  'common',
  'premium',
  'direct-deal',
  'email',
  'portal',
  'premium',
  'step',
  'step.insuranceOptions',
  'premium.premiumDetail',
];

/** Backen load path requires full URL */
const getBackendLoadPath = () => {
  const isFullUrl = /^https?:\/\//.test(TRANSLATION_URL);

  if (!isFullUrl) {
    return `${APPLICATION_HOST}${TRANSLATION_URL}`;
  }

  return TRANSLATION_URL;
};

export const PROD_LANGUAGES: LanguageCode[] = ['de', 'fr', 'it'];
export const DEV_LANGUAGES: LanguageCode[] = [...PROD_LANGUAGES, 'en'];
const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'PROD';

export const defaultConfig: Partial<InitOptions> = {
  // debug: true,
  defaultNS: 'common',
  ns: ['common'],
  fallbackLng: 'de',
  supportedLngs: isProd ? PROD_LANGUAGES : DEV_LANGUAGES,
  detection: {
    lookupFromPathIndex: 0,
    order: ['path'],
  },
  // keySeparator: false,
  nsSeparator: ':',
  react: {
    // wait: true,
    useSuspense: false,
  },
  interpolation: {
    format: interpolationFormat,
  },
  // appendNamespaceToCIMode: true,
  backend: {
    loadPath: getBackendLoadPath(),
    allowMultiLoading: true,
    parse: (data: any) => {
      return JSON.parse(data);
    },
  },
};
