import React from 'react';
import {
  AllianzLogo,
  AxaLogo,
  SimpegoLogo,
  ElviaLogo,
  GeneraliLogo,
  HelvetiaLogo,
  MobiLogoDe,
  MobiLogoFr,
  MobiLogoIt,
  SmileLogo,
  ZurichLogo,
  TcsLogo,
  BelsuraLogo,
  VaudoiseLogo,
  BaloiseLogo,
} from 'Icon/insurances';

export interface LogoRendererProps {
  insurance: string;
  language: string;
  className?: string;
  svgWidth: string;
  svgHeight: string;
}

const getLogoComponent = (name, language, svgWidth, svgHeight) => {
  switch (name) {
    case 'allianz':
      return (
        <AllianzLogo viewBox="0 -5 95 50" width={svgWidth} height={svgHeight} />
      );
    case 'axa':
      return <AxaLogo width={svgWidth} height={svgHeight} />;
    case 'baloise':
      return (
        <BaloiseLogo
          viewBox="2 12 140 65"
          width={svgWidth}
          height={svgHeight}
        />
      );
    case 'simpego':
      return <SimpegoLogo width={svgWidth} height={svgHeight} />;
    case 'elvia':
      return (
        <ElviaLogo
          viewBox="0 0 229.6 114.5"
          width={svgWidth}
          height={svgHeight}
        />
      );
    case 'generali':
      return (
        <GeneraliLogo
          viewBox="-100 0 1100.3 709.6"
          width={svgWidth}
          height={svgHeight}
        />
      );
    case 'helvetia':
      return (
        <HelvetiaLogo
          viewBox="-35 -25 250 100"
          width={svgWidth}
          height={svgHeight}
        />
      );
    case 'mobiliar':
      const mobiliarViewBox = '-8 0 160 23';
      switch (language) {
        case 'de':
          return (
            <MobiLogoDe
              viewBox={mobiliarViewBox}
              width={svgWidth}
              height={svgHeight}
            />
          );
        case 'fr':
          return (
            <MobiLogoFr
              viewBox={mobiliarViewBox}
              width={svgWidth}
              height={svgHeight}
            />
          );
        case 'it':
          return (
            <MobiLogoIt
              viewBox={mobiliarViewBox}
              width={svgWidth}
              height={svgHeight}
            />
          );
        default:
          return (
            <MobiLogoDe
              viewBox={mobiliarViewBox}
              width={svgWidth}
              height={svgHeight}
            />
          );
      }
    case 'zurich':
      return (
        <ZurichLogo
          width={svgWidth}
          height={svgHeight}
          viewBox="-10 0 152 88"
        />
      );
    case 'smile':
      return <SmileLogo width={svgWidth} height={svgHeight} />;
    case 'tcs':
      return <TcsLogo width={svgWidth} height={svgHeight} />;
    case 'belsura':
      return <BelsuraLogo width={svgWidth} height={svgHeight} />;
    case 'vaudoise':
      return (
        <VaudoiseLogo
          width={svgWidth}
          height={svgHeight}
          viewBox="-2 8 150 86"
        />
      );
    default:
      return null;
  }
};

export const LogoRenderer: React.FC<LogoRendererProps> = ({
  insurance,
  language,
  className,
  svgWidth,
  svgHeight,
}) => {
  return (
    <div className={`logo-renderer ${className || ''}`}>
      {getLogoComponent(insurance, language, svgWidth, svgHeight)}
    </div>
  );
};

export default LogoRenderer;
