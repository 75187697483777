export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const SHOW_LOADING_SCREEN_AUTO_HIDE = 'SHOW_LOADING_SCREEN_AUTO_HIDE';
export const ALLOW_HIDING = 'ALLOW_HIDING';
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';
export const HIDE_LOADING_SCREEN_DELAYED = 'HIDE_LOADING_SCREEN_DELAYED';

export const startShowLoadingScreen = (
  text: string,
  isLogoLoader: boolean,
) => ({
  type: SHOW_LOADING_SCREEN,
  text,
  isLogoLoader,
});

export const startHideLoadingScreenDelayed = (delayTime: number) => ({
  type: HIDE_LOADING_SCREEN_DELAYED,
  delayTime,
});
export const startHideLoadingScreen = () => ({ type: HIDE_LOADING_SCREEN });
export const allowHiding = (timeout: number) => ({
  type: ALLOW_HIDING,
  timeout,
});
