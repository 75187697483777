/* eslint-disable no-param-reassign */
import { convertToE164Format } from '@scout24ch/fs24-utils';
import { OfferRequestInput } from '../../../../../state/RequestOffer/types';
import { makePersonalDetails } from './PersonalDetails';

function makeRequestOfferInput() {
  return {
    sameAddress: false,
    insuranceHolderPersonalDetails: null,
  };
}

function setInquiry(requestOfferInput, { mainDriver, policyHolder }) {
  const next = {
    ...requestOfferInput,
  };

  if (!mainDriver.isPolicyHolder) {
    next.insuranceHolderPersonalDetails = makePersonalDetails(policyHolder);
  }

  next.mainDriverPersonalDetails = makePersonalDetails(mainDriver);
  next.isPolicyHolder = mainDriver.isPolicyHolder;

  return next;
}

function toJSON(
  requestOfferInput: OfferRequestInput,
  multipleOffers?: boolean,
) {
  return {
    mainDriverPersonalDetails: {
      ...requestOfferInput.mainDriverPersonalDetails,
      privatePhone: convertToE164Format(
        requestOfferInput.mainDriverPersonalDetails.privatePhone,
      ),
    },
    insuranceHolderPersonalDetails:
      requestOfferInput.insuranceHolderPersonalDetails,
    insuranceResponseId: requestOfferInput.insuranceResponseId,
    sortCriteriaID: requestOfferInput.sortCriteriaID,
    sortRank: requestOfferInput.sortRank,
    gaClientId: requestOfferInput.gaClientId,
    upSellingOptions: !multipleOffers
      ? requestOfferInput.upSellingOptions ?? []
      : [],
  };
}

export { makeRequestOfferInput, setInquiry, toJSON };
