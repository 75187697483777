import firstNameEmpty from './firstNameEmpty.json';
import nameEmpty from './nameEmpty.json';
import streetEmpty from './streetEmpty.json';
import cityEmpty from './cityEmpty.json';
import privatePhoneEmpty from './privatePhoneEmpty.json';
import privatePhoneInvalid from './privatePhoneInvalid.json';
import emailEmpty from './emailEmpty.json';
import emailInvalid from './emailInvalid.json';
import houseNumberToLong from './houseNumberToLong.json';
import holderFirstNameEmpty from './holderFirstNameEmpty.json';
import holderNameEmpty from './holderNameEmpty.json';
import holderStreetEmpty from './holderStreetEmpty.json';
import holderCityEmpty from './holderCityEmpty.json';
import holderPrivatePhoneEmpty from './holderPrivatePhoneEmpty.json';
import holderPrivatePhoneInvalid from './holderPrivatePhoneInvalid.json';
import holderEmailEmpty from './holderEmailEmpty.json';
import holderEmailInvalid from './holderEmailInvalid.json';
import holderHouseNumberToLong from './holderHouseNumberToLong.json';
import cityInvalid from './cityInvalid.json';
import cantonInvalid from './cantonInvalid.json';
import holderPrivatePhoneSwissNumberInvalid from './holderPrivatePhoneSwissNubmerInvalid.json';
import privatePhoneSwissNumberInvalid from './privatePhoneSwissNumberInvalid.json';
export default {
  firstNameEmpty,
  nameEmpty,
  streetEmpty,
  cityEmpty,
  privatePhoneEmpty,
  privatePhoneInvalid,
  emailEmpty,
  emailInvalid,
  houseNumberToLong,
  holderFirstNameEmpty,
  holderNameEmpty,
  holderStreetEmpty,
  holderCityEmpty,
  holderPrivatePhoneEmpty,
  holderPrivatePhoneInvalid,
  holderEmailEmpty,
  holderEmailInvalid,
  holderHouseNumberToLong,
  cityInvalid,
  cantonInvalid,
  holderPrivatePhoneSwissNumberInvalid,
  privatePhoneSwissNumberInvalid,
};
