import { isNil } from 'utils';
import { Incidents } from '../../state/InquiryInput/types';

export function makeIncidents(): Incidents {
  return {
    cancellationOrSpecialConditions: null,
    isAdditionalQuestions: null,
    denialOrSpecialConditions: null,
    claims: null,
    infringements: null,
    suspensionOfDrivingLicense: null,
    dataProtectionAccepted: true,
  };
}

export function toJSON(
  incidents: Incidents,
): Omit<Incidents, 'dataProtectionAccepted'> {
  if (isNil(incidents)) {
    return incidents;
  }

  return {
    cancellationOrSpecialConditions: incidents.cancellationOrSpecialConditions,
    isAdditionalQuestions: incidents.isAdditionalQuestions,
    denialOrSpecialConditions: incidents.denialOrSpecialConditions,
    claims: incidents.claims,
    infringements: incidents.infringements,
    suspensionOfDrivingLicense: incidents.suspensionOfDrivingLicense,
  };
}
