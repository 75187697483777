export default class Translatable {
  constructor(
    public value: string | string[],
    public shouldTranslate: boolean,
  ) {}

  static translate(
    translatable: Translatable,
    t: (i: string, params: object) => string,
    params?: object,
  ) {
    if (translatable.value && translatable.shouldTranslate && t) {
      if (Array.isArray(translatable.value)) {
        return translatable.value.map((i) => t(i, params));
      }
      return t(translatable.value, params);
    }
    return translatable.value;
  }
}
