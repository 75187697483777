import { captureException } from '@sentry/node';
import { AxiosResponse } from 'axios';
import {
  VehicleSuggestion,
  VehicleTypeSuggestion,
} from '../containers/InquiryPage/components/StepVehicle/types';
import { Vehicle } from '../state/InquiryInput/types';
import { VehicleMakes } from '../state/VehicleMakes/types';
import { INSURANCE_TYPE } from '../utils/const';
import { insuranceService } from './apiClients/insuranceService';
import { CarBrands } from '../utils/types';
import { vehicleSearchService } from './apiClients/vehicleSearchService';

interface VehicleSelectionItem {
  id: number;
  name: string | null;
  labels: string[] | null;
}

interface EurotaxMatch {
  id: number | null;
  name: string | null;
  engineHorsePower: number | null;
  doors: number | null;
  transmissionType: VehicleSelectionItem;
  bodyType: VehicleSelectionItem;
  make: string | null;
  model: string | null;
  priceProvider: number | null;
  certificationCodes: string[];
  month: number | null;
  year: number | null;
}
interface VehicleResponse {
  modelMonth?: number;
  vehicleType: VehicleSelectionItem;
  driverType: VehicleSelectionItem;
  co2Emission: number | null;
  isInspected: boolean;
  hasWarranty: boolean;
  consumptionRatingType: VehicleSelectionItem;
  insuranceTypeId: number;
  eurotaxMatches: EurotaxMatch[];
  typeCertificate: string | null;
  eurotaxId: number | null;
  chassisNumber: string | null;
  fullName: string | null;
  makeName: string | null;
  modelName: string | null;
  modelYear: number | null;
  string: string | null;
  dateOfFirstRegistration: string | null;
  capacityCcm: number | null;
  enginePowerHp: number | null;
  enginePowerKw: number | null;
  emptyWeightKg: number | null;
  price: number | null;
  sellingPrice: number | null;
  priceOfAccessories: number | null;
  mileage: number | null;
  imageUrl: string | null;
  as24VehicleId: number | null;
  transmissionType: VehicleSelectionItem;
  fuelType: VehicleSelectionItem;
  type: VehicleSelectionItem;
  color: VehicleSelectionItem;
  isMetallic: boolean | null;
  conditionType: VehicleSelectionItem;
  makeId: number | null;
  modelId: number | null;
  firstRegYear: number | null;
  firstRegMonth: number | null;
  doors: number | null;
  providerId: number | null;
}

export type LicensePlateResponse = LicensePlateResult[];

export interface LicensePlateResult {
  vehicle_type: string;
  canton: string;
  number_plate: string;
  stammnummer: string;
  type_certification_code: string;
  make_name: string;
  model_name: string;
  complete_number_plate: string;
  registration_date: string;
  vin: string;
  engine_displacement: number;
  cylinders: number;
  seats: number;
  engine_power_kw: number;
  last_inspection_date: string;
  next_inspection_date: string;
  fuel_type_id: string;
  fuel_type_de: string;
  fuel_type_fr: string;
  fuel_type_it: string;
  fuel_type_en: string;
  gearing_type_id: string;
  gearing_type_de: string;
  gearing_type_fr: string;
  gearing_type_it: string;
  gearing_type_en: string;
  transmission_type_id: string;
  transmission_type_de: string;
  transmission_type_fr: string;
  transmission_type_it: string;
  transmission_type_en: string;
  body_type_id: string;
  body_type_de: string;
  body_type_fr: string;
  body_type_it: string;
  body_type_en: string;
  eurotax_ids: string[];
  eurotax_matches: number;
  eurotax_common_data: LicensePlateNumberEurotaxCommonData;
  eurotax_different_data: LicensePlateNumberEurotaxDifferentData[];
}

export interface LicensePlateNumberEurotaxCommonData {
  make_name: string;
  body_type_de: string;
  body_type_fr: string;
  body_type_it: string;
  fuel_type_de: string;
  fuel_type_fr: string;
  fuel_type_it: string;
  gearing_type_de: string;
  gearing_type_fr: string;
  gearing_type_it: string;
  transmission_type_de: string;
  transmission_type_fr: string;
  transmission_type_it: string;
  doors: number[];
  seats: number[];
  cylinders: number;
  engine_displacement: number;
  engine_power_kw: number;
  engine_power_hp: number;
  eurotax_id: string;
  model_name: string;
  price_brutto: number;
}

export interface LicensePlateNumberEurotaxDifferentData {
  make_name: string;
  body_type_de: string;
  body_type_fr: string;
  body_type_it: string;
  fuel_type_de: string;
  fuel_type_fr: string;
  fuel_type_it: string;
  gearing_type_de: string;
  gearing_type_fr: string;
  gearing_type_it: string;
  transmission_type_de: string;
  transmission_type_fr: string;
  transmission_type_it: string;
  doors: number[];
  seats: number[];
  cylinders: number;
  engine_displacement: number;
  engine_power_kw: number;
  engine_power_hp: number;
  eurotax_id: string;
  model_name: string;
  price_brutto: number;
}

export const getVehicleById = async (id: number) => {
  const response: AxiosResponse<VehicleResponse> = await insuranceService.get(
    `vehicles/${id}`,
  );

  return response.data;
};

export const getVehicleByEurotaxId = async (
  eurotaxId: number,
  params: { month: number; year: number; transmissionTypeId?: number },
): Promise<Vehicle> => {
  const { month, year, transmissionTypeId } = params;
  const queryParams = new URLSearchParams({
    month: String(month),
    year: String(year),
    transmissionTypeId: transmissionTypeId ? String(transmissionTypeId) : '0',
  }).toString();

  const response: AxiosResponse<Vehicle> = await insuranceService.get(
    `/vehicles/eurotax/${eurotaxId}?${queryParams}`,
  );

  return response.data;
};

export const getVehicleMakes = async () => {
  try {
    const response: AxiosResponse<VehicleMakes[]> = await insuranceService.get(
      `/vehicle-selections/${INSURANCE_TYPE}/makes`,
    );

    return response.data;
  } catch (error) {
    captureException(error);
  }
};

export const searchVehicles = async (
  make: string,
  searchTerm: string,
  month: number,
  year: number,
  eurotaxId?: number,
): Promise<VehicleSuggestion[]> => {
  const params: Record<string, string | number> = {
    SearchTerm: searchTerm,
    Month: month,
    Year: year,
    InsuranceType: INSURANCE_TYPE,
  };

  if (eurotaxId) {
    params.EuroTaxId = eurotaxId;
  }

  try {
    const response = await insuranceService.get(
      `/vehicle-selections/make/${make}/vehicles`,
      { params },
    );

    return response.data;
  } catch (error) {
    captureException(error);
    return [];
  }
};

export const searchVehicleType = async ({
  makeName,
  searchTerm,
  year,
  month,
  eurotaxId,
}: {
  makeName: string;
  searchTerm: string;
  year: number;
  month: number;
  eurotaxId?: number;
}): Promise<Omit<VehicleTypeSuggestion, 'firstRegistrationDates'>[]> => {
  try {
    let params: Record<string, string | number> = {
      SearchTerm: searchTerm,
      Month: month,
      Year: year,
      InsuranceType: INSURANCE_TYPE,
    };

    if (eurotaxId) {
      params = { EuroTaxId: eurotaxId };
    }

    const response = await insuranceService.get(
      `/vehicle-selections/make/${makeName}/vehicle-types`,
      { params },
    );

    return response.data.map((t) => ({
      vehicleType: t.vehicleType,
      euroTaxId: t.euroTaxId,
    }));
  } catch (error) {
    captureException(error);
    return [];
  }
};

export type CarCertificateModelResponse = {
  make: keyof typeof CarBrands;
  vehicleTypes: VehicleTypeSuggestion[];
};

export const searchVehiclesByCertificate = async ({
  typeCertificate,
  month,
  year,
}: {
  typeCertificate: string;
  month: number;
  year: number;
}): Promise<CarCertificateModelResponse> => {
  const params: Record<string, string | number> = {
    Month: month,
    Year: year,
    InsuranceType: INSURANCE_TYPE,
  };

  try {
    const response = await insuranceService.get(
      `/vehicle-selections/type-certificate/${typeCertificate}`,
      { params },
    );
    return response.data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export async function getVehicleByLicensePlate(
  canton: string,
  number: string,
): Promise<LicensePlateResponse> {
  try {
    const response = await vehicleSearchService.get(
      `/licenseplates/car/${canton}/${number}`,
    );
    return response.data;
  } catch (error) {
    captureException(error);
    throw error;
  }
}
