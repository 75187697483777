import { UpsellingOptionResponse } from '../api/fetchUpsellingOptions';

export interface UpsellingOption extends UpsellingOptionResponse {
  isSelected: boolean;
}

export enum UpsellingProducts {
  ASSISTANCE = 'assistance',
  ASSISTANCE_SINGLE = 'assistanceSingle',
  ASSISTANCE_FAMILY = 'assistanceFamily',
  LEGAL = 'legal',
  LEGAL_SINGLE = 'legalSingle',
  LEGAL_FAMILY = 'legalFamily',
  KEY_LOSS = 'keyloss',
}

export interface UpsellingProduct {
  option:
    | UpsellingProducts.ASSISTANCE
    | UpsellingProducts.ASSISTANCE_SINGLE
    | UpsellingProducts.ASSISTANCE_FAMILY
    | UpsellingProducts.LEGAL
    | UpsellingProducts.LEGAL_SINGLE
    | UpsellingProducts.LEGAL_FAMILY
    | UpsellingProducts.KEY_LOSS;
}

export enum UpsellingInquiryPremiumTypes {
  FULL_CASCO = 'fullCasco',
}
