import getConfig from 'next/config';
import { InsuranceType } from 'utils/types';
import {
  STEP_DRIVER,
  STEP_FINISH,
  STEP_INCIDENTS,
  STEP_INSURANCE_OPTIONS,
  STEP_VEHICLE,
} from 'utils/namespace-const';

const { publicRuntimeConfig = {} } = getConfig() || {};
const {
  NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_GTM_AUTH,
  NEXT_PUBLIC_GTM_ENV,
  NODE_ENV,
  I18NEXT_RELOAD_INTERVAL,
  NEXT_PUBLIC_INSURANCE_TYPE,
  NEXT_PUBLIC_CMS_URL,
  NEXT_PUBLIC_MAIN_DOMAIN,
  NEXT_PUBLIC_ENVIRONMENT,
} = publicRuntimeConfig;

export const GTM_ID = NEXT_PUBLIC_GTM_ID || process.env.NEXT_PUBLIC_GTM_ID;
export const GTM_AUTH =
  NEXT_PUBLIC_GTM_AUTH || process.env.NEXT_PUBLIC_GTM_AUTH;
export const GTM_ENV = NEXT_PUBLIC_GTM_ENV || process.env.NEXT_PUBLIC_GTM_ENV;

export const ENV = NODE_ENV || process.env.NODE_ENV;
export const I18N_RELOAD_INTERVAL =
  I18NEXT_RELOAD_INTERVAL || process.env.I18NEXT_RELOAD_INTERVAL;
export const INSURANCE_TYPE =
  NEXT_PUBLIC_INSURANCE_TYPE || process.env.NEXT_PUBLIC_INSURANCE_TYPE;

export const IS_AUTO_INSURANCE = INSURANCE_TYPE === InsuranceType.AUTO;
export const IS_MOTO_INSURANCE = INSURANCE_TYPE === InsuranceType.MOTO;

export const KEY_LAST_INQUIRY_KEY = IS_AUTO_INSURANCE
  ? 'fs24.getPremium_last_inquiry_key_auto'
  : 'fs24.getPremium_last_inquiry_key_moto';
export const KEY_LEGACY_LAST_INQUIRY_KEY = 'getPremium_last_inquiry_key';
export const KEY_LAST_OFFER_PERSONAL_DATA = 'last_offer_personal_data';

export const MOBILIAR_CLEVER_DRIVE_PRODUCT_BUNDLE_ID = 9;

export const APPLICATION_HOST =
  publicRuntimeConfig.APPLICATION_HOST || process.env.APPLICATION_HOST;

export const CMS_URL = NEXT_PUBLIC_CMS_URL || process.env.NEXT_PUBLIC_CMS_URL;

export const CMS_API =
  ENV === 'production' ? `${APPLICATION_HOST}/api/portal` : CMS_URL;

export const MAIN_DOMAIN =
  NEXT_PUBLIC_MAIN_DOMAIN || process.env.NEXT_PUBLIC_MAIN_DOMAIN || 'localhost';

export const ENVIRONMENT =
  NEXT_PUBLIC_ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;

export const SENTRY_DSN = publicRuntimeConfig.SENTRY_DSN;
export const SENTRY_ENABLED = publicRuntimeConfig.SENTRY_ENABLED;
export const SENTRY_TRACES_SAMPLE_RATE =
  publicRuntimeConfig.SENTRY_TRACES_SAMPLE_RATE;
export const SENTRY_LOG_TO_CONSOLE = publicRuntimeConfig.SENTRY_LOG_TO_CONSOLE;

export const TRANSLATION_RELOAD_INTERVAL_MIN =
  publicRuntimeConfig.TRANSLATION_RELOAD_INTERVAL_MIN;

export const TRANSLATION_URL =
  publicRuntimeConfig.TRANSLATION_URL || process.env.TRANSLATION_URL;

export const MYFS24_API_URL =
  ENV === 'production' ? `${APPLICATION_HOST}/api/my` : CMS_URL;

export const INSURANCE_COMPANIES = {
  MOBILIAR: 'mobiliar',
  ZURICH: 'zurich',
  HELVETIA: 'helvetia',
};

export const isProd = ENVIRONMENT === 'PROD';

export const LANGUAGES = {
  de: 'de-ch',
  fr: 'fr-ch',
  it: 'it',
  en: 'en-gb',
};

export const ALL_LANGUAGES = ['de', 'fr', 'it', 'en'];

export const DEFAULT_LOOKUP_VALUES = {
  leasingPartners: [],
  firstRegistrationReasons: [],
  genders: [],
  residencePermits: [],
  countries: [],
  claimCauses: [],
  claimTypes: [],
  selfInflictedOptions: [],
  claimCausers: [],
  infringementReasons: [],
  suspensionPeriods: [],
  insuredPersons: [],
  insuredSumsAtDeath: [],
  insuredSumsAtDisability: [],
  accidentInsuranceDailyAllowance: [],
  carriedThingsInsuranceSum: [],
  partialCoverCostSharings: [],
  freeGarageChoices: [],
  fullyComprehensiveCostSharings: [],
  allowances: [],
  insuranceCompanies: [],
  cantons: [],
  languages: [],
  carriedThingsInsuranceSums: [],
};

export const INSURANCE_INQUIRY_STEPS = [
  STEP_VEHICLE,
  STEP_DRIVER,
  STEP_INSURANCE_OPTIONS,
  STEP_INCIDENTS,
  STEP_FINISH,
];
