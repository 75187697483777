import { formatDate } from 'utils/dateformat-const';
import { toJSON as driverDetailsToJSON } from './DriverDetails';
import { Person } from '../../state/InquiryInput/types';

function makeIndividual(): Person {
  return {
    gender: null,
    isMainDriver: null,
    isPolicyHolder: null,
    hasAutoDrivingLicense: null,
    dateOfBirth: null,
    dateOfDrivingLicense: null,
    dateOfMotoDrivingLicense: null,
    nationality: null,
    residencePermit: null,
    residencePermitSince: null,
    postCode: null,
    city: null,
    driverDetails: null,
    isQuickAdjust: null,
    emailAddress: null,
  };
}

function toJSON(individual: Person): Person {
  if (individual === null || individual === undefined) {
    return individual;
  }

  return {
    gender: individual.gender,
    isMainDriver: individual.isMainDriver,
    hasAutoDrivingLicense: individual.hasAutoDrivingLicense,
    isPolicyHolder: individual.isPolicyHolder,
    dateOfBirth: formatDate(individual.dateOfBirth),
    dateOfDrivingLicense: formatDate(individual.dateOfDrivingLicense),
    dateOfMotoDrivingLicense: formatDate(individual.dateOfMotoDrivingLicense),
    nationality: individual.nationality,
    residencePermit: individual.residencePermit,
    residencePermitSince: formatDate(individual.residencePermitSince),
    postCode: individual.postCode,
    // TODO: Why are we only sending the postCode but not the city?
    // city: individual.city,
    driverDetails: driverDetailsToJSON(individual.driverDetails),
    isQuickAdjust: individual.isQuickAdjust,
    emailAddress: individual.emailAddress,
  };
}

export { makeIndividual, toJSON };
