import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';

import {
  ID_RATING,
  ID_RESPONSE,
  ID_OFFER_REQUESTED,
  DEFAULT_SORT_BY,
  SORT_ITEMS,
  SortByNameOptions,
} from 'utils/premiumConst';
import { Premium } from 'types/insurances';
import { UpsellingInquiryPremiumTypes } from 'types/upselling';
import {
  PREMIUMS_CHANGED,
  CLEAR_PREMIUMS,
  PREMIUM_SORT_CHANGED,
  INIT_LOADING_PREMIUMS,
  ActionType,
  SET_PREMIUMS_AS_OFFER_REQUESTED,
  SET_USPELLING_INQUIRY_PREMIUMS,
} from './actions';
import { PremiumLoaderActionTypes } from './premiumLoader';

type UpsellingInquiryPremiums = {
  [key in UpsellingInquiryPremiumTypes]: Premium[];
};

type PremiumLoaderState = {
  premiums: Premium[];
  oldPremiums: Premium[];
  sortBy: SortByNameOptions;
  loading: boolean;
  pollingInitialized: boolean;
  completed: boolean;
  completedInsurances: number;
  totalInsurances: number;
  premiumsCompleted: boolean;
  inquiryKey: string | undefined;
  upsellingInquiryPremiums: UpsellingInquiryPremiums;
  upsellingCallOriginalInquiryKey: string;
};

const consolidatePremiums = (newPremium, premiums) => {
  const oldPremium = _find(
    premiums,
    (p) => p[ID_RESPONSE] === newPremium[ID_RESPONSE],
  );
  if (oldPremium) {
    return {
      ...newPremium,
      [ID_RATING]: oldPremium[ID_RATING],
      [ID_OFFER_REQUESTED]:
        oldPremium[ID_OFFER_REQUESTED] || newPremium[ID_OFFER_REQUESTED],
    };
  }

  return newPremium;
};

export const getSortOrder = (sortKey: SortByNameOptions) => {
  const primarySort = _find(SORT_ITEMS, { name: sortKey });
  return [primarySort].concat(SORT_ITEMS.filter((s) => s.name !== sortKey));
};

export const sortPremiums = (
  premiums: Premium[],
  primarySortKey: SortByNameOptions = DEFAULT_SORT_BY,
) => {
  const sortOrder = getSortOrder(primarySortKey);
  return _orderBy(
    premiums,
    sortOrder.map((k) => k.name),
    sortOrder.map((k) => k.orderBy),
  );
};

const initialState: PremiumLoaderState = {
  premiums: [],
  oldPremiums: [],
  sortBy: DEFAULT_SORT_BY,
  loading: false,
  pollingInitialized: false,
  completed: false,
  completedInsurances: 0,
  totalInsurances: 0,
  premiumsCompleted: false,
  inquiryKey: undefined,
  upsellingInquiryPremiums: {} as UpsellingInquiryPremiums,
  upsellingCallOriginalInquiryKey: undefined,
};

const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case PREMIUMS_CHANGED: {
      const {
        premiums: newPremiums,
        completedInsurances,
        totalInsurances,
        completed: premiumsCompleted,
      } = action;
      const completed = action.completed ?? state.completed;
      if (!newPremiums) {
        return {
          ...state,
          completed,
          completedInsurances,
          totalInsurances,
          premiumsCompleted,
        };
      }
      const { premiums: oldPremiums, sortBy } = state;
      const premiums = sortPremiums(
        newPremiums.map((p) => consolidatePremiums(p, oldPremiums)),
        sortBy,
      );

      return {
        ...state,
        premiums,
        completed,
        completedInsurances,
        totalInsurances,
        premiumsCompleted,
      };
    }
    case PREMIUM_SORT_CHANGED: {
      const { premiums: oldPremiums, completed } = state;
      const premiums = sortPremiums(oldPremiums, action.sortBy);

      return {
        ...state,
        sortBy: action.sortBy,
        premiums,
        completed,
      };
    }
    case CLEAR_PREMIUMS: {
      return {
        ...initialState,
      };
    }
    case SET_PREMIUMS_AS_OFFER_REQUESTED: {
      const { responseIds } = action;

      const updatedPremiums = state.premiums.map((p) => {
        if (responseIds.includes(p.responseId) && p.supportsOffer) {
          return { ...p, offerRequested: true };
        }
        return p;
      });

      return {
        ...state,
        premiums: updatedPremiums,
      };
    }
    case PremiumLoaderActionTypes.StartPollingPremiums: {
      return {
        ...state,
        loading: true,
        pollingInitialized: true,
        inquiryKey: action.inquiryKey,
      };
    }
    case INIT_LOADING_PREMIUMS: {
      return {
        ...state,
        loading: true,
        pollingInitialized: false,
        inquiryKey: action.inquiryKey,
      };
    }
    case PremiumLoaderActionTypes.StopLoadingPremiums: {
      return {
        ...state,
        loading: false,
        pollingInitialized: true,
        completed: true,
      };
    }
    case SET_USPELLING_INQUIRY_PREMIUMS: {
      return {
        ...state,
        upsellingInquiryPremiums: {
          ...state.upsellingInquiryPremiums,
          [action.uppselingType]: action.premiums,
        },
        upsellingCallOriginalInquiryKey: action.originalInquiryKey,
      };
    }
    default:
      return state;
  }
};

export default reducer;
