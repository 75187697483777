//@ts-nocheck
import { ofType } from 'redux-observable';
import moment from 'moment';
import { mergeMap, debounceTime, map, filter } from 'rxjs/operators';

import incidentsValidation from 'containers/InquiryPage/components/StepIncidents/helpers/incidentsValidation';
import { saveInsuranceInquiry } from 'state/InsuranceInquiry/actions';

import { tracker } from 'utils/GTM';
import {
  EVENT_GTM,
  CATEGORY_INSURANCE_COMPARISON,
  ACTION_SUBMIT_VEHICLESUM_AND_TERM,
  ACTION_SUBMIT_DRIVER_DATA,
  ACTION_SUBMIT_COVERAGE_DATA,
  ACTION_SUBMIT_INCIDENTS_DATA,
} from 'utils/GTM/events';
import { hasErrors } from 'utils/validation';
import { logger } from 'utils';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { DATE_FORMAT_DAY } from 'utils/dateformat-const';
import { InsuranceInquiryUtils } from 'models/InsuranceInquiry';
import {
  STEP_VEHICLE,
  STEP_DRIVER,
  STEP_INSURANCE_OPTIONS,
  STEP_INCIDENTS,
  STEP_FINISH,
} from 'utils/namespace-const';
import createInsuranceOptionsValidation from 'containers/InquiryPage/components/StepInsuranceOptions/helpers/InsuranceOptionsValidationFactory';
import createDriverValidation from 'containers/InquiryPage/components/StepDriver/helpers/DriverValidationFactory';
import createVehicleValidation from 'containers/InquiryPage/components/StepVehicle/helpers/VehicleValidationFactory';
import { SearchFlow } from 'utils/types';
import {
  INQUIRY_INPUT_CHANGED,
  VALIDATE_INPUT,
  REQUEST_CHANGE_STEP,
  CHANGE_STEP_WITH_VALIDATION,
  CREATE_LONG_INQUIRY,
  SAVE_LONG_INQUIRY,
  validationChanged,
  loadLongInquiry,
  changeStep,
  validateInput,
  saveLongInquiry,
} from './actions';

function mapSearchType(searchType: SearchFlow | undefined) {
  if (!searchType || searchType === SearchFlow.LICENSE_PLATE_NUMBER) {
    return 'plate';
  }

  if (searchType === SearchFlow.BRAND) {
    return 'model';
  }

  return 'type';
}

const executeValidation = (step, input, success, errors) => {
  const defaultAction = new Promise((then) => then(success()));
  try {
    switch (step.name) {
      case STEP_VEHICLE.name: {
        const validate = createVehicleValidation(IS_AUTO_INSURANCE);
        return validate(input.usageDetails, input.vehicle, success, errors);
      }
      case STEP_DRIVER.name: {
        const validate = createDriverValidation(IS_AUTO_INSURANCE);

        return validate(
          input.mainDriver,
          input.policyHolder,
          input.insuranceOptions,
          success,
          errors,
        );
      }
      case STEP_INSURANCE_OPTIONS.name: {
        const validate = createInsuranceOptionsValidation(IS_AUTO_INSURANCE);
        return validate(input.insuranceOptions, input.vehicle, success, errors);
      }
      case STEP_INCIDENTS.name:
        return incidentsValidation(input, success, errors);
      default:
        return defaultAction;
    }
  } catch (error) {
    logger.error(`Error while validating ${step.name}`, {
      input,
      step: step.name,
      error,
    });
    throw error;
  }
};

const inputValidation = (
  { inquiryInput: { step, inputs } },
  nextStep,
  currentStep,
) => {
  const success = () => ({
    validation: undefined,
    nextStep,
    currentStep,
  });
  const failure = (errors) => ({
    validation: errors,
    nextStep,
    currentStep,
  });
  return executeValidation(step, inputs, success, failure);
};

export const changingInputEpic = (action$, state$) =>
  action$.pipe(
    ofType(INQUIRY_INPUT_CHANGED),
    filter(() => state$.value.inquiryInput.validated),
    map(validateInput),
  );

export const revalidateInputEpic = (action$, state$) =>
  action$.pipe(
    ofType(VALIDATE_INPUT),
    debounceTime(125),
    mergeMap(() => inputValidation(state$.value)),
    map(({ validation }) => validationChanged(validation, false)),
  );

const createTrackingObject = (eventAction) => ({
  event: EVENT_GTM,
  eventCategory: CATEGORY_INSURANCE_COMPARISON,
  eventAction,
});

const trackInsuranceComparison = (eventAction) => {
  tracker.track(createTrackingObject(eventAction));
};

const formatCarUsage = (usageDetails) => {
  let result = '';
  if (usageDetails.privateUsage) {
    result = 'private';
  }
  if (usageDetails.commuteUsage) {
    if (result.length > 0) {
      result += ' / ';
    }
    result += 'commute';
  }
  if (usageDetails.businessUsage) {
    if (result.length > 0) {
      result += ' / ';
    }
    result += 'business';
  }

  return result;
};

export const changeStepWithValidationEpic = (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_STEP_WITH_VALIDATION),
    mergeMap(({ step, currentStep }) =>
      inputValidation(state$.value, step, currentStep),
    ),
    map(({ validation, nextStep, currentStep }) => {
      let {
        inquiryInput: {
          isStepVehicleValid,
          isStepDriverValid,
          isStepInsuranceOptionsValid,
          isStepIncidentsValid,
        },
      } = state$.value;
      const isStepValid = !hasErrors(validation);
      switch (currentStep.index) {
        case STEP_VEHICLE.index: {
          isStepVehicleValid = isStepValid;
          break;
        }
        case STEP_DRIVER.index: {
          isStepDriverValid = isStepValid;
          break;
        }
        case STEP_INSURANCE_OPTIONS.index: {
          isStepInsuranceOptionsValid = isStepValid;
          break;
        }
        case STEP_INCIDENTS.index: {
          isStepIncidentsValid = isStepValid;
          break;
        }
        default:
          break;
      }
      return changeStep(
        nextStep,
        isStepVehicleValid,
        isStepDriverValid,
        isStepInsuranceOptionsValid,
        isStepIncidentsValid,
      );
    }),
  );

export const requestChangeStepEpic = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_CHANGE_STEP),
    mergeMap(({ step, currentStep }) =>
      inputValidation(state$.value, step, currentStep),
    ),
    map(({ validation, nextStep, currentStep }) => {
      let {
        inquiryInput: {
          isStepVehicleValid,
          isStepDriverValid,
          isStepInsuranceOptionsValid,
          isStepIncidentsValid,
        },
      } = state$.value;
      if (hasErrors(validation)) {
        return validationChanged(validation, true);
      }
      if (currentStep) {
        switch (currentStep.index) {
          case STEP_VEHICLE.index: {
            isStepVehicleValid = true;
            if (nextStep.name === STEP_DRIVER.name) {
              const { inputs } = state$.value.inquiryInput;

              const trackingData = createTrackingObject(
                ACTION_SUBMIT_VEHICLESUM_AND_TERM,
              );

              if (IS_AUTO_INSURANCE) {
                trackingData.vehicle_choice = mapSearchType(
                  inputs.vehicle.searchType,
                );
              }

              trackingData.makeName = inputs.vehicle.makeName;
              trackingData.makeModel = inputs.vehicle.typeName;
              trackingData.carOwner =
                inputs.usageDetails.expectedPurchaseYear &&
                inputs.usageDetails.expectedPurchaseYear !== -1
                  ? 'yes'
                  : 'no';
              trackingData.leasing = inputs.usageDetails.leasing ? 'yes' : 'no';
              trackingData.kmPerYear = inputs.usageDetails.kilometerPerYear;
              trackingData.carUsage = formatCarUsage(inputs.usageDetails);
              trackingData.applicationRegion =
                inputs.usageDetails.registrationCanton?.name;
              trackingData.interiorValue = inputs.vehicle.priceOfAccessories;
              trackingData.privateParking =
                inputs.usageDetails.garageAtWork ||
                inputs.usageDetails.garageAtHome
                  ? 'yes'
                  : 'no';
              tracker.track(trackingData);
            }
            break;
          }
          case STEP_DRIVER.index: {
            isStepDriverValid = true;
            if (nextStep.name === STEP_INSURANCE_OPTIONS.name) {
              trackInsuranceComparison(ACTION_SUBMIT_DRIVER_DATA);
            }
            break;
          }
          case STEP_INSURANCE_OPTIONS.index: {
            isStepInsuranceOptionsValid = true;
            if (nextStep.name === STEP_INCIDENTS.name) {
              const trackingData = createTrackingObject(
                ACTION_SUBMIT_COVERAGE_DATA,
              );
              trackingData.contractStartDate = moment(
                state$.value.inquiryInput.inputs.insuranceOptions
                  .insuranceStartDate,
              ).format(DATE_FORMAT_DAY);
              tracker.track(trackingData);
            }
            break;
          }
          case STEP_INCIDENTS.index: {
            isStepIncidentsValid = true;
            if (nextStep.name === STEP_FINISH.name) {
              const trackingData = createTrackingObject(
                ACTION_SUBMIT_INCIDENTS_DATA,
              );
              trackingData.hasFam =
                state$.value.inquiryInput.inputs.mainDriver.driverDetails
                  .numberOfChildren > 0
                  ? 'yes'
                  : 'no';
              tracker.track(trackingData);
            }
            break;
          }
          default:
            break;
        }
      }
      switch (nextStep.index) {
        case STEP_FINISH.index: {
          return saveLongInquiry();
        }
        default: {
          return changeStep(
            nextStep,
            isStepVehicleValid,
            isStepDriverValid,
            isStepInsuranceOptionsValid,
            isStepIncidentsValid,
          );
        }
      }
    }),
  );

export const saveLongInquiryEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_LONG_INQUIRY),
    map(({ fromQuickAdjust }) => {
      const {
        inquiryInput: { inputs, keyFromParam },
        fetchLookUpData: { lookUpData },
        saveInsuranceInquiry: { insuranceInquiryId },
        fetchInsuranceInquiry: {
          insuranceInquiry: insuranceInquiryLoaded,
          fetchPreviousInquiry,
        },
      } = state$.value;
      const insuranceInquiry = InsuranceInquiryUtils.prepareInquiry(
        inputs,
        lookUpData,
        insuranceInquiryId,
        insuranceInquiryLoaded,
        fetchPreviousInquiry,
        keyFromParam,
        fromQuickAdjust,
      );
      return saveInsuranceInquiry(insuranceInquiry);
    }),
  );

export const createLongInquiryEpic = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_LONG_INQUIRY),
    map(() => {
      const { vehicleData } = state$.value;
      const inputs = InsuranceInquiryUtils.createDefaultInquiry(
        vehicleData.vehicle,
      );
      return loadLongInquiry(inputs);
    }),
  );
