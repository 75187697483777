import { isNil } from 'utils';
import { UsageDetails } from '../../state/InquiryInput/types';

export function makeUsageDetails(): UsageDetails {
  return {
    firstRegistrationMonth: 0,
    firstRegistrationYear: 0,
    leasing: null,
    kilometerPerYear: null,
    mileage: null,
    privateUsage: false,
    businessUsage: false,
    commuteUsage: false,
    registrationCanton: null,
    priceOfAccessories: null,
    garageAtWork: null,
    garageAtHome: null,
    expectedPurchaseYear: null,
  };
}

export function toJSON(details: UsageDetails) {
  if (isNil(details)) {
    return details;
  }

  return {
    leasing: details.leasing,
    kilometerPerYear: details.kilometerPerYear,
    mileage: details.mileage,
    privateUsage: details.privateUsage,
    businessUsage: details.businessUsage,
    commuteUsage: details.commuteUsage,
    registrationCanton: details.registrationCanton,
    garageAtWork: details.garageAtWork,
    garageAtHome: details.garageAtHome,
    expectedPurchaseYear: details.expectedPurchaseYear,
    priceOfAccessories: details.priceOfAccessories,
  };
}
