import Translatable from '../../utils/Translatable';

export default class AlertMessage {
  constructor(
    public text: Translatable,
    public additionalParams: object = null,
    public isBold = false,
  ) {}

  static createTranslatedMessage(
    key: string,
    additionalParams: object = null,
    isBold = false,
  ) {
    return new AlertMessage(
      new Translatable(key, true),
      additionalParams,
      isBold,
    );
  }

  static createTranslatedParam(paramKey: string) {
    return new Translatable(paramKey, true);
  }

  static createMessage(text: string, additionalParams = null, isBold = false) {
    return new AlertMessage(
      new Translatable(text, false),
      additionalParams,
      isBold,
    );
  }

  static createEmailMessage(email: string) {
    return this.createMessage(email, null, true);
  }

  static translate(
    { text, additionalParams },
    t: (i: string, params: object) => string,
  ) {
    return Translatable.translate(
      text,
      t,
      this.concatParams(additionalParams, t),
    );
  }

  static concatParams(
    additionalParams: object,
    t: (i: string, params: object) => string,
  ) {
    if (additionalParams) {
      const translatedParams = {};
      Object.keys(additionalParams).forEach((key) => {
        translatedParams[key] = Translatable.translate(
          additionalParams[key],
          t,
        );
      });
      return translatedParams;
    }
    return additionalParams;
  }
}
