import { CookieStorage, cookieStorage } from '@scout24ch/fs24-utils';
import moment from 'moment';
import getCircularReplacer from './jsonUtils';
import { MAIN_DOMAIN } from './const';
import { logger } from './newLogger';

/**
 * @deprecated
 *
 * TODO - Replace implementation with
 * import { cookieStorage } from '@scout24ch/fs24-utils';
 */
export default class CookieUtils {
  public static getCookieValue<T>(key: string): T {
    if (!CookieStorage.isSupported()) {
      return null;
    }

    const cookieValue = cookieStorage.getItem(key);

    if (!cookieValue) {
      return null;
    }

    try {
      return JSON.parse(cookieValue) as T;
    } catch (error) {
      logger.error(
        `Error while parsing cookie value JSON: ${error}, cookieValue: ${cookieValue}`,
        {
          cookieValue,
          error,
        },
      );
      throw error;
    }
  }

  public static setSharedCookie(
    key: string,
    value: any,
    numberOfYearsUntilExpiration: number,
  ) {
    if (!CookieStorage.isSupported()) {
      return;
    }

    const json = JSON.stringify(value, getCircularReplacer());
    const endDate = moment().add(numberOfYearsUntilExpiration, 'years');
    const expirationDate = endDate.toDate().toUTCString();
    cookieStorage.setSharedItem(key, json, MAIN_DOMAIN, expirationDate);
  }
}

export const getCookieValue = (
  cookie: string,
  key: string,
): string | undefined => {
  if (!cookie) {
    return;
  }

  const splittedValues = cookie.split('; ');
  if (splittedValues.length == 0) {
    return;
  }

  for (const cookieKeyValue of splittedValues) {
    if (cookieKeyValue.startsWith(key)) {
      const value = cookieKeyValue.replace(`${key}=`, '');

      if (value) {
        return value;
      }
    }
  }

  return;
};
