import { AxiosResponse, isCancel } from 'axios';
import { InsuranceComparisonResponse } from 'types/insurances';
import { captureException } from '../utils/sentry';
import { insuranceService } from './apiClients/insuranceService';

export const getPremiumResults = async (
  inquiryKey: string,
  signal?: AbortSignal,
) => {
  try {
    const response: AxiosResponse<InsuranceComparisonResponse> =
      await insuranceService.get(`/premium-results/${inquiryKey}`, { signal });

    return response.data;
  } catch (error) {
    if (isCancel(error)) {
      return;
    }

    captureException(error);
  }
};
