// @ts-ignore
import { Engine } from 'json-rules-engine';

import {
  ValidationError,
  ValidationFailureFunction,
  ValidationSuccessFunction,
} from 'utils/validation/types';
import { isNumberPlateValid } from 'utils/validation/numberPlateValidation';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { UsageDetails, UsageDetailsType } from '../types';
import {
  ID_LICENSE_PLATE_NUMBER,
  ID_TYPE_CERTIFICATE,
  ID_VEHICLE_SEARCH_TEXT,
} from '../components/const';
import MotoVehicleValidation from './motoVehicleValidation';
import AutoVehicleValidation from './autoVehicleValidation';
import { Vehicle } from '../../../../../state/InquiryInput/types';
import { SearchFlow } from '../../../../../utils/types';

type ValidationFunction = (
  usageDetails: UsageDetails,
  vehicle: Vehicle,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => void;

const createVehicleValidation = (
  isAutoInsurance: boolean,
): ValidationFunction =>
  isAutoInsurance ? AutoVehicleValidation : MotoVehicleValidation;

export const validateVehicle = (
  engine: Engine,
  usageDetails: UsageDetailsType,
  vehicle: Vehicle,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) =>
  engine.run(usageDetails).then((errors: ValidationError[]) => {
    if (!vehicle || !vehicle.makeName) {
      errors.push({
        type: `make-empty`,
      });
    }

    if (
      vehicle &&
      !vehicle.typeName &&
      vehicle.searchType === SearchFlow.BRAND
    ) {
      errors.push({
        type: `${ID_VEHICLE_SEARCH_TEXT}-empty`,
      });
    }

    if (
      vehicle.searchType === SearchFlow.TYPE_CERTIFICATE &&
      !vehicle.typeCertificate
    ) {
      errors.push({
        type: `${ID_TYPE_CERTIFICATE}-empty`,
      });
    }

    // license plate number validation
    if (IS_AUTO_INSURANCE) {
      const isLicensePlateFlow =
        vehicle.searchType === SearchFlow.LICENSE_PLATE_NUMBER ||
        !vehicle.searchType;

      if (
        isLicensePlateFlow &&
        !isNumberPlateValid(vehicle.licensePlateNumber)
      ) {
        errors.push({
          type: `${ID_LICENSE_PLATE_NUMBER}-empty`,
        });
      }

      if (isLicensePlateFlow && !vehicle.licensePlateNumber) {
        errors.push({
          type: `${ID_LICENSE_PLATE_NUMBER}-empty`,
        });
      }

      if (
        isLicensePlateFlow &&
        isNumberPlateValid(vehicle.licensePlateNumber) &&
        !usageDetails.firstRegistrationYear
      ) {
        errors.push({
          type: `${ID_LICENSE_PLATE_NUMBER}-nosearch`,
        });
      }
    }

    if (vehicle?.typeName && !vehicle.typeNameSelected) {
      errors.push({
        type: `${ID_VEHICLE_SEARCH_TEXT}-notFound`,
      });
    }

    if (vehicle && !vehicle.eurotaxId) {
      errors.push({
        type: `vehicleSelection-empty`,
      });
    }

    if (errors.length > 0) {
      return failure(errors);
    }

    return success();
  });

export default createVehicleValidation;
