export interface LookupItem {
  key: number;
  name: string;
  displayName: string;
}

export interface Canton {
  id: number;
  name: string;
  displayName: string;
}

export interface City {
  postCode: number;
  name: string;
  bfsNumber: number;
  canton: string;
}
export enum InsuranceType {
  AUTO = 'AUTO',
  MOTO = 'MOTO',
}

export interface LastInquiry {
  key: string;
  path?: string;
  timestamp: number;
  label?: string;
}

export enum SearchFlow {
  BRAND = 'brand',
  TYPE_CERTIFICATE = 'typeCertificate',
  LICENSE_PLATE_NUMBER = 'licensePlateNumber',
}

export enum CarBrands {
  AIWAYS = 'AIWAYS',
  AIXAM = 'AIXAM',
  'ALFA ROMEO' = 'ALFA ROMEO',
  ALPINE = 'ALPINE',
  ARTEGA = 'ARTEGA',
  'ASTON MARTIN' = 'ASTON MARTIN',
  AUDI = 'AUDI',
  BENTLEY = 'BENTLEY',
  BMW = 'BMW',
  'BMW-ALPINA' = 'BMW-ALPINA',
  CADILLAC = 'CADILLAC',
  CATERHAM = 'CATERHAM',
  CHEVROLET = 'CHEVROLET',
  CHRYSLER = 'CHRYSLER',
  CITROEN = 'CITROEN',
  CUPRA = 'CUPRA',
  DACIA = 'DACIA',
  DAEWOO = 'DAEWOO',
  DAIHATSU = 'DAIHATSU',
  DAIMLER = 'DAIMLER',
  DFM = 'DFM',
  DFSK = 'DFSK',
  DODGE = 'DODGE',
  DONGFENG = 'DONGFENG',
  'DS AUTOMOBILES' = 'DS AUTOMOBILES',
  ELARIS = 'ELARIS',
  FERRARI = 'FERRARI',
  FIAT = 'FIAT',
  FISKER = 'FISKER',
  FORD = 'FORD',
  GENESIS = 'GENESIS',
  HONDA = 'HONDA',
  HS = 'HS',
  HUMMER = 'HUMMER',
  HYUNDAI = 'HYUNDAI',
  INEOS = 'INEOS',
  INFINITI = 'INFINITI',
  ISUZU = 'ISUZU',
  IVECO = 'IVECO',
  JAC = 'JAC',
  JAGUAR = 'JAGUAR',
  JEEP = 'JEEP',
  KIA = 'KIA',
  KTM = 'KTM',
  LADA = 'LADA',
  LAMBORGHINI = 'LAMBORGHINI',
  LANCIA = 'LANCIA',
  'LAND ROVER' = 'LAND ROVER',
  LEVC = 'LEVC',
  LEXUS = 'LEXUS',
  LIGIER = 'LIGIER',
  LOTUS = 'LOTUS',
  LUCID = 'LUCID',
  MASERATI = 'MASERATI',
  MAXUS = 'MAXUS',
  MAZDA = 'MAZDA',
  MCLAREN = 'MCLAREN',
  'MERCEDES-BENZ' = 'MERCEDES-BENZ',
  MG = 'MG',
  MICRO = 'MICRO',
  MINI = 'MINI',
  MITSUBISHI = 'MITSUBISHI',
  MORGAN = 'MORGAN',
  NISSAN = 'NISSAN',
  OPEL = 'OPEL',
  PEUGEOT = 'PEUGEOT',
  PIAGGIO = 'PIAGGIO',
  POLESTAR = 'POLESTAR',
  PORSCHE = 'PORSCHE',
  RENAULT = 'RENAULT',
  'ROLLS-ROYCE' = 'ROLLS-ROYCE',
  ROVER = 'ROVER',
  SAAB = 'SAAB',
  SEAT = 'SEAT',
  SECMA = 'SECMA',
  SKODA = 'SKODA',
  SMART = 'SMART',
  SSANGYONG = 'SSANGYONG',
  SUBARU = 'SUBARU',
  SUZUKI = 'SUZUKI',
  TATA = 'TATA',
  TAZZARI = 'TAZZARI',
  TESLA = 'TESLA',
  THINK = 'THINK',
  TOYOTA = 'TOYOTA',
  TVR = 'TVR',
  VOLVO = 'VOLVO',
  VOYAH = 'VOYAH',
  VW = 'VW',
  WIESMANN = 'WIESMANN',
}

export enum MotoBrands {
  SUZUKI = 'SUZUKI',
  YAMAHA = 'YAMAHA',
  HONDA = 'HONDA',
  'HARLEY-DAVIDSON' = 'HARLEY-DAVIDSON',
  DUCATI = 'DUCATI',
  TRIUMPH = 'TRIUMPH',
  BMW = 'BMW',
  'CAN-AM' = 'CAN-AM',
  APRILIA = 'APRILIA',
  KTM = 'KTM',
  KAWASAKI = 'KAWASAKI',
  PIAGGIO = 'PIAGGIO',
  'MOTO GUZZI' = 'MOTO GUZZI',
  'MV AGUSTA' = 'MV AGUSTA',
  PEUGEOT = 'PEUGEOT',
  KYMCO = 'KYMCO',
  SYM = 'SYM',
  MALAGUTI = 'MALAGUTI',
  INDIAN = 'INDIAN',
  'CF MOTO' = 'CF MOTO',
  'ROYAL ENFIELD' = 'ROYAL ENFIELD',
  BENELLI = 'BENELLI',
  BETA = 'BETA',
  FANTIC = 'FANTIC',
  HUSQVARNA = 'HUSQVARNA',
  MASH = 'MASH',
  VICTORY = 'VICTORY',
  POLARIS = 'POLARIS',
  GILERA = 'GILERA',
  'SMC (SM)' = 'SMC (SM)',
  TGB = 'TGB',
  SWM = 'SWM',
  BRIXTON = 'BRIXTON',
  NIU = 'NIU',
  BUELL = 'BUELL',
  CAGIVA = 'CAGIVA',
  GASGAS = 'GASGAS',
  LML = 'LML',
  VOGE = 'VOGE',
  MONDIAL = 'MONDIAL',
  KUMPAN = 'KUMPAN',
  'MOTO MORINI' = 'MOTO MORINI',
  VANDERHALL = 'VANDERHALL',
  ZERO = 'ZERO',
  DAELIM = 'DAELIM',
  ENERGICA = 'ENERGICA',
  MBK = 'MBK',
  WOTTAN = 'WOTTAN',
  ETRIX = 'ETRIX',
  LAMBRETTA = 'LAMBRETTA',
  'SUPER SOCO' = 'SUPER SOCO',
  ECOOTER = 'ECOOTER',
  KOVE = 'KOVE',
  QUADRO = 'QUADRO',
  BULLIT = 'BULLIT',
  MOTRON = 'MOTRON',
  RA = 'RA',
  SHERCO = 'SHERCO',
  VMOTO = 'VMOTO',
  BEELINE = 'BEELINE',
  BRAMMO = 'BRAMMO',
  DERBI = 'DERBI',
  HUSABERG = 'HUSABERG',
  SILENCE = 'SILENCE',
  VENT = 'VENT',
  RIEJU = 'RIEJU',
  UGBEST = 'UGBEST',
  ZONTES = 'ZONTES',
  COLOVE = 'COLOVE',
  DINLI = 'DINLI',
  HYOSUNG = 'HYOSUNG',
  STELS = 'STELS',
  TINBOT = 'TINBOT',
  YADEA = 'YADEA',
  'GENERIC / KSR MOTO' = 'GENERIC / KSR MOTO',
  'MONNIER-HONDA' = 'MONNIER-HONDA',
  MORGAN = 'MORGAN',
}

export type SelectOption = {
  disabled: boolean;
  label: string;
  value: (number | string) | LookupItem;
};
