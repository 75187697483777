import * as Sentry from '@sentry/node';
import { NodeOptions } from '@sentry/node';
import { BrowserOptions } from '@sentry/browser';
import { Express } from 'express';
import { RewriteFrames } from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { Options, SamplingContext } from '@sentry/types';
import {
  getGlobal,
  shouldIgnoreException,
  ExceptionFilter,
  fingerprintException,
  exceptionUrlFilter,
  isAxiosError,
} from '@scout24ch/fs24-utils';
import * as config from './const';

type SentryOptions = Options | NodeOptions | BrowserOptions;

const TRACES_SAMPLE_RATE = parseFloat(config.SENTRY_TRACES_SAMPLE_RATE);

export const shouldIgnoreByBreadcrumbUrl = (
  breadcrumbs: Sentry.Breadcrumb[],
) => {
  const ignoreBreadcrumbUrls = [
    // Ignore fetch errors caused by beagle.min.js
    'beagleider.tamedia.link',
    'ad.doubleclick.net',
    'pagead2.googlesyndication.com',
  ];

  const errorBreadcrumb = breadcrumbs?.find((crumb) => crumb.level === 'error');

  if (errorBreadcrumb) {
    const shouldIgnore = ignoreBreadcrumbUrls.some(
      (url) => errorBreadcrumb.data?.url?.includes(url),
    );
    if (shouldIgnore) {
      return true;
    }
  }

  return false;
};

export const init = ({ app }: { app?: Express } = {}): void => {
  if (!config.SENTRY_DSN) {
    return;
  }

  let tracesSampler: (context: SamplingContext) => number | boolean;
  const integrations = [];

  if (process.env.NEXT_IS_SERVER === 'true') {
    const excludedTraces = [
      '/static/',
      '/_next/',
      '/api/health',
      '/favicon.ico',
    ];

    tracesSampler = (context) => {
      try {
        const { pathname } = new URL(
          (context.request && context.request.url) || 'http://localhost/',
        );

        if (excludedTraces.some((path) => pathname.startsWith(path))) {
          return 0;
        }

        if (context.parentSampled !== undefined) {
          return context.parentSampled;
        }

        return TRACES_SAMPLE_RATE;
      } catch {
        return 0;
      }
    };

    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          if (frame.filename != null) {
            frame.filename = frame.filename
              // Normalizing cwd to match in case we ever move the directory within
              // the images.
              .replace(process.cwd() || '', 'app:///')
              // Matching filename from server to the from the browser build,
              // as the browser builds into _next.
              .replace('.next', '_next');
          }

          return frame;
        },
      }),
    );

    integrations.push(new Sentry.Integrations.Http({ tracing: true }));
    integrations.push(new TracingIntegrations.Express({ app }));
  } else {
    tracesSampler = (context) =>
      context.parentSampled != null
        ? context.parentSampled
        : TRACES_SAMPLE_RATE;

    integrations.push(
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', 'www.financescout24.ch', /^\//],
        beforeNavigate: (context) => {
          const location: { pathname: string } = (getGlobal() as any).location;

          return {
            ...context,
            name: location.pathname
              .replace(/^\/(?:de|fr|it|en)/, '')
              .replace(
                /\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/gi,
                '/<uuid>',
              )
              .replace(/\/[a-f0-9]{32}/gi, '/<hash>')
              .replace(/\/\d+/g, '/<digits>'),
          };
        },
      }),
    );
  }

  const enabled = config.SENTRY_ENABLED === 'true';
  const ignoreErrorsList: ExceptionFilter[] = [
    // Error thrown by redirect in case vehicle was not found from as24
    (error: Error) => error && error.message === 'vehicle-error',
    // https://sentry.io/share/issue/4dcd37320ab94adeb99aae8442f7a6a1/
    /undefined is not an object \(evaluating 'document\.getElementsByTagName\('video'\)\[0\]\.webkitExitFullScreen'\)/,
    // https://sentry.io/share/issue/1954784c44b1495885cc2763523a006b/
    /can't access dead object/,

    // These are being handled and have a JIRA ticket.
    /Hydration failed/,
    /There was an error while hydrating/,

    // Axios network errors
    // https://financescout24.sentry.io/share/issue/943f15d79d654da9ab4ee47fe521d0cf/
    (error) => isAxiosError(error) && error.message === 'Network Error',
    // https://financescout24.sentry.io/issues/4419186664/?query=start%3D2023-08-27T20%3A49%3A00%26end%3D2023-08-29T12%3A02%3A24%26groupStatsPeriod%3Dauto&referrer=alerts-related-issues-issue-stream
    /Request failed with status code 400/,

    // Marketing does not know where those errors come from
    /Cannot read properties of undefined \(reading 'configuration'\)/,
    /undefined is not an object \(evaluating 'this.getSDK\(\).configuration'\)/,
    /this\.getSDK\(\) is undefined/,
  ];

  const sentryOptions: SentryOptions = {
    environment: config.ENVIRONMENT,
    integrations,
    tracesSampler,
    denyUrls: exceptionUrlFilter(),
    dsn: config.SENTRY_DSN,
    release: process.env.GITHUB_SHA,
    beforeSend(event, hint) {
      if (shouldIgnoreException(hint?.originalException, ignoreErrorsList)) {
        return null;
      }

      if (shouldIgnoreByBreadcrumbUrl(event.breadcrumbs)) {
        return null;
      }

      fingerprintException(event, hint && hint.originalException);

      if (config.SENTRY_LOG_TO_CONSOLE === 'true') {
        // eslint-disable-next-line no-console
        console.error(
          (hint && hint.originalException) || (hint && hint.syntheticException),
        );
      }

      return enabled ? event : null;
    },
  };

  Sentry.init(sentryOptions);

  Sentry.configureScope((scope) => {
    scope.setTag('SSR', process.env.NEXT_IS_SERVER === 'true');
  });
};

export const captureMessage: typeof Sentry.captureMessage = config.SENTRY_DSN
  ? Sentry.captureMessage
  : // eslint-disable-next-line no-console
    (err) => (console.log(err), '');

export const captureException: typeof Sentry.captureException =
  config.SENTRY_DSN
    ? Sentry.captureException
    : // eslint-disable-next-line no-console
      (err) => (console.error(err), '');

export const configureScope = Sentry.configureScope;
export const withScope = Sentry.withScope;
export { withProfiler } from '@sentry/react';
