import type { InsuranceComparison } from './types';

export const FETCH_INSURANCE_COMPARISON = 'FETCH_INSURANCE_COMPARISON' as const;
export const FETCH_INSURANCE_COMPARISON_FULFILLED =
  'FETCH_INSURANCE_COMPARISON_FULFILLED' as const;
export const FETCH_INSURANCE_COMPARISON_FAILED =
  'FETCH_INSURANCE_COMPARISON_FAILED' as const;
export const CLEAR_PRODUCT_BUNDLE_IDS = 'CLEAR_PRODUCT_BUNDLE_IDS' as const;
export const DESELECT_PRODUCT_BUNDLE_ID = 'DESELECT_PRODUCT_BUNDLE_ID' as const;

export const fetchInsuranceComparison = (
  insuranceInquiryKey: string,
  productBundleIds: number[],
) => ({
  type: FETCH_INSURANCE_COMPARISON,
  productBundleIds,
  insuranceInquiryKey,
});

export const fetchInsuranceComparisonFulfilled = (
  payload: InsuranceComparison,
  insuranceInquiryKey: string,
  productBundleIds: number[],
) => ({
  type: FETCH_INSURANCE_COMPARISON_FULFILLED,
  payload,
  insuranceInquiryKey,
  productBundleIds,
});

export const fetchInsuranceComparisonFailed = (
  error: unknown,
  insuranceInquiryKey: string,
  productBundleIds: number[],
) => ({
  type: FETCH_INSURANCE_COMPARISON_FAILED,
  error,
  insuranceInquiryKey,
  productBundleIds,
});

export const clearProductBundleIds = () => ({
  type: CLEAR_PRODUCT_BUNDLE_IDS,
});

export const deselectProductBundleId = (productBundleId: number) => ({
  type: DESELECT_PRODUCT_BUNDLE_ID,
  productBundleId,
});

export type ActionType = ReturnType<
  | typeof fetchInsuranceComparison
  | typeof fetchInsuranceComparisonFulfilled
  | typeof fetchInsuranceComparisonFailed
  | typeof clearProductBundleIds
  | typeof deselectProductBundleId
>;
