/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const ElviaLogo = (props: IconProps) => {
  return (
    <svg
      id="prefix__Logo"
      className={`a-icon a-icon--elvia-logo ${props.className || ''}`}
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 229.6 114.5"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.prefix__st1{fill:#e30613}'}</style>
      <switch>
        <g>
          <path
            fill="#fff"
            d="M45.3 20.1v9.5h-9.5V39h9.5v9.6h9.3V39H64v-9.4h-9.4v-9.5z"
          />
          <path
            className="prefix__st1"
            d="M45.3 20.1h-10S22.6 34 22.7 34.2c.1.2 12.9 14.5 12.9 14.5h9.8V39H36v-9.4h9.4v-9.5zM54.6 20.1h10S77.3 34 77.2 34.2 64.4 48.6 64.4 48.6h-9.8V39H64v-9.4h-9.4v-9.5z"
          />
          <path d="M81.1 20.1h24.4v6.3H89.6V31h14.8v6.2H89.6v5.1h16.7v6.3H81.1zM131.2 48.6v-6.1h-13.6V20.1h-8.4v28.5zM122.2 20.1l13.4 28.5h10.8l13.5-28.5h-9.2L141 42l-9.6-21.9zM160.5 20.1h8.3v28.6h-8.3z" />
          <path
            d="M193.6 20.1L207 48.6h-9.4l-2.8-5.9h-13.2l-2.7 5.9h-9.4L182.8 20h10.8zm-5.6 7.2l-4.1 9.3h8.2l-4.1-9.3h0zm0 4.7"
            stroke="#000"
            strokeWidth={0}
          />
          <g>
            <path d="M118.1 75.7h-3.3l1.8-5.8 1.5 5.8zm-5.1 6.4l1.1-3.8h4.5l1.1 3.8h4.2l-4.1-14.5c-.2-.9-.8-1.3-1.7-1.3h-5.9v1.4h.5c.6 0 .9.2.9.7 0 .3-.1.6-.3 1.3l-4.1 12.4h3.8zM128.7 82.1V67.5c0-.8-.4-1.2-1.2-1.2h-3.6v1.4h.2c.6 0 .9.3.9.9v13.5h3.7zM135 82.1V67.5c0-.8-.5-1.2-1.2-1.2h-3.6v1.4h.2c.6 0 .9.3.9.9v13.5h3.7zM141.4 67.7c0-1.1-.8-1.7-2.1-1.7s-2.1.6-2.1 1.7.8 1.7 2.1 1.7c1.4 0 2.1-.6 2.1-1.7m0 14.4V71.4c0-.8-.5-1.2-1.2-1.2h-3.6v1.3h.2c.6 0 .9.3.9.9V82h3.7zM149.5 76.4v2.3c-.6.6-1.4 1-2 1-.7 0-.9-.3-.9-1.2 0-.9.2-1.3.8-1.5.5-.3 1.2-.5 2.1-.6m-5-2.8c1.2-.6 2.6-.9 3.6-.9s1.4.4 1.4 1.4v.3c-1.5.2-2 .3-2.8.5-.5.1-.9.3-1.4.4-1.5.6-2.2 1.7-2.2 3.5 0 2.2 1 3.3 3 3.3.7 0 1.4-.1 2-.4.5-.2.8-.4 1.6-1v.2c0 .7.4 1.1 1.1 1.1h3.4v-1.3h-.2c-.6 0-.9-.3-.9-1v-5.6c0-3-1-4-4-4-1 0-1.9.1-2.8.3-.9.2-1.4.4-2.5.9l.7 2.3zM159.5 82.1v-8c.6-.7 1.3-1 2-1 .8 0 1 .3 1 1.5v7.5h3.7v-8c0-1.9-.1-2.5-.7-3.1-.5-.5-1.3-.8-2.2-.8-1.4 0-2.5.4-3.8 1.7v-.5c0-.7-.4-1.1-1.1-1.1h-3.5v1.3h.2c.6 0 .9.3.9.9v9.6h3.5zM177.1 79.6h-5.2l4.9-6.9v-2.4h-7.7c-.9 0-1.3.4-1.3 1.3v2.1h1.4v-.2c0-.5.3-.8.9-.8h2.7l-5 7v2.4h9.3v-2.5zM196.4 81.7V68.4c0-1.1-.4-1.4-1.4-1.4h-3.1v1.5h.2c.7 0 .9.2.9 1v12.3h3.4zm1.6 0h3.5V71.3c0-1-.4-1.4-1.4-1.4H198v11.8zm-6.6 0V69.9h-2.1c-1 0-1.4.4-1.4 1.4v10.4h3.5zm13.3-6.7c0 5.9-4.2 10.2-10 10.2s-10-4.3-10-10.2c0-5.9 4.2-10.2 10-10.2s10 4.3 10 10.2m2.1 0c0-6.9-5.2-12.1-12.1-12.1-6.9 0-12.1 5.2-12.1 12.1 0 6.9 5.2 12.1 12.1 12.1 6.9 0 12.1-5.2 12.1-12.1" />
            <g>
              <path d="M91.6 76.7c0 3.3-2 5.6-5 5.6-1.5 0-2.9-.7-3.8-1.9V82h-1.7V66.3h1.8v6.6c.9-1.2 2.2-1.8 3.7-1.8 2.9.1 5 2.3 5 5.6zm-1.8 0c0-2.3-1.4-3.8-3.5-3.8-2 0-3.4 1.5-3.4 3.8 0 2.4 1.3 3.9 3.4 3.9 2.1 0 3.5-1.6 3.5-3.9zM98.7 82.2l-.2.4c-1.1 2.7-2.2 3.5-4 3.5-.5 0-1.1-.1-1.5-.2l.2-1.7c.4.1.9.2 1.3.2 1 0 1.5-.3 2.4-2.4l-4.3-10.6h1.9l3.3 8.4 3.3-8.4h1.9l-4.3 10.8z" />
            </g>
          </g>
        </g>
      </switch>
    </svg>
  );
};

ElviaLogo.displayName = 'ElviaLogo';

export default ElviaLogo;
