import { RootState } from '../reducers';

export const getLoadingScreenVisible = (state: RootState) => {
  return state.loadingScreen.loadingScreenVisible;
};

export const getLoadingScreenText = (state: RootState) => {
  return state.loadingScreen.loadingScreenText;
};

export const getLogoLoader = (state: RootState) => {
  return state.loadingScreen.isLogoLoader;
};

export const getCanHide = (state: RootState) => {
  return state.loadingScreen.canHide;
};
