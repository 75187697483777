import { ofType } from 'redux-observable';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import _keys from 'lodash/keys';
import _findIndex from 'lodash/findIndex';
import _pickBy from 'lodash/pickBy';
import { getGoogleAnalyticsClientId } from '@scout24ch/fs24-utils';
import { showAlert } from 'components/Alert/state/actions';
import { onPremiumsChanged } from 'components/PremiumLoader/state/actions';
import { ERROR } from 'components/Alert/state/types';
import { hasErrors } from 'utils/validation';
import { tracker } from 'utils/GTM';
import {
  ACTION_REQUEST_MULTIPLE_OFFERS_SENT,
  createEvent,
  createOfferSentEvent,
} from 'utils/GTM/events';
import { KEY_LAST_OFFER_PERSONAL_DATA } from 'utils/const';
import { OfferAlertMessage } from 'components/Alert/OfferAlertMessage';
import { makeLastOffer } from 'containers/OfferPage/components/RequestOffer/models/LastOfferUtil';
import { toJSON as requestOfferInputToJSON } from 'containers/OfferPage/components/RequestOffer/models/RequestOfferInput';
import LocalStorageUtils from 'utils/LocalStorageUtils';
import { ID_MATCH, ID_PRICE, ID_RATING } from 'utils/premiumConst';
import { Premium } from 'types/insurances';
import { captureException } from 'utils/sentry';
import { UpsellingInquiryPremiumTypes } from 'types/upselling';
import validateOffer from '../../containers/OfferPage/components/RequestOffer/helpers/offerValidation';
import RequestOfferInputUtils from '../../containers/OfferPage/components/RequestOffer/models/RequestOfferInputUtils';
import {
  requestMultipleOffers,
  requestOffer as requestOfferRequest,
} from '../../api/offerRequests';
import {
  offerValidationChanged,
  SEND_OFFER_REQUEST,
  SEND_OFFER_REQUEST_FAILED,
  SEND_OFFER_REQUEST_FULFILLED,
  sendOfferRequest,
  sendOfferRequestFailed,
  sendOfferRequestFulfilled,
  TRY_SEND_OFFER_REQUEST,
  UPDATE_OFFER_REQUEST,
  VALIDATE_OFFER_INPUT,
  validateOfferInput,
} from './actions';

const inputValidation = (state) => {
  const success = () => undefined;
  const failure = (errors) => errors;
  return validateOffer(state.value.requestOffer, success, failure);
};

const evaluateValidationResult = (validation, responseId) => {
  return hasErrors(validation)
    ? offerValidationChanged(validation, true)
    : sendOfferRequest(responseId);
};

const sendOffer = (responseId, state) => {
  const {
    value: {
      requestOffer,
      premiumLoader: { premiums, sortBy },
    },
  } = state;

  const selectedOffers = requestOffer.selectedOffers?.filter(
    (premium) => premium.isSelected,
  );

  const premiumsWithoutRating = _keys(
    _pickBy(premiums, ['customerRating', null]),
  );
  let whileIndex = 0;
  const payload = requestOffer.offerRequestInput;
  switch (sortBy) {
    case ID_MATCH:
      payload.sortCriteriaID = 1;
      break;
    case ID_PRICE:
      payload.sortCriteriaID = 2;
      break;
    case ID_RATING:
      payload.sortCriteriaID = 3;
      while (whileIndex < premiumsWithoutRating.length) {
        premiums.push(premiums.splice(premiumsWithoutRating[whileIndex], 1)[0]);
        whileIndex += 1;
      }
      break;
    default:
      payload.sortCriteriaID = 1;
  }

  payload.sortRank = _findIndex(premiums, ['responseId', responseId]) + 1;
  payload.insuranceResponseId = responseId;
  payload.gaClientId = getGoogleAnalyticsClientId();

  if (selectedOffers?.length > 1) {
    return requestMultipleOffers({
      offerRequest: requestOfferInputToJSON(payload, true),
      insuranceResponseIds: selectedOffers.map((offer) => offer.responseId),
    });
  }

  return requestOfferRequest(requestOfferInputToJSON(payload));
};

export const trySendOfferRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRY_SEND_OFFER_REQUEST),
    mergeMap(({ responseId }) =>
      from(inputValidation(state$)).pipe(
        map((validation) => evaluateValidationResult(validation, responseId)),
      ),
    ),
  );

export const sendOfferRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(SEND_OFFER_REQUEST),
    mergeMap(({ responseId }) =>
      from(sendOffer(responseId, state$)).pipe(
        map(() => sendOfferRequestFulfilled(responseId)),
        catchError((e) => {
          captureException(e);
          return of(sendOfferRequestFailed(e));
        }),
      ),
    ),
  );

export const changingOfferInputEpic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_OFFER_REQUEST),
    mergeMap(() =>
      state$.value.requestOffer.validated ? of(validateOfferInput()) : of(),
    ),
  );

export const revalidateOfferInputEpic = (action$, state$) =>
  action$.pipe(
    ofType(VALIDATE_OFFER_INPUT),
    debounceTime(125),
    mergeMap(() => inputValidation(state$)),
    map((validation) => offerValidationChanged(validation, false)),
  );

export const sendOfferRequestFulfilledEpic = (action$, state$) =>
  action$.pipe(
    ofType(SEND_OFFER_REQUEST_FULFILLED),
    mergeMap(({ responseId }) => {
      const {
        value: {
          requestOffer,
          premiumLoader: {
            premiums,
            completed,
            completedInsurances,
            totalInsurances,
            upsellingInquiryPremiums,
          },
        },
      } = state$;
      const inquiryKey =
        state$.value.fetchInsuranceInquiry.insuranceInquiry.key;
      const selectedOffers = requestOffer.selectedOffers?.filter(
        (premium) => premium.isSelected,
      );

      const email = RequestOfferInputUtils.getDriverEmail(
        requestOffer.offerRequestInput,
      );

      // this affects only multi offers flow
      if (selectedOffers.length > 0) {
        tracker.track(
          createEvent(ACTION_REQUEST_MULTIPLE_OFFERS_SENT, {
            eventLabel: selectedOffers.length.toString(),
          }),
        );

        selectedOffers.forEach((premium: Premium) => {
          const requestedPremium = premiums.find(
            (p: Premium) => premium.responseId === p.responseId,
          );
          requestedPremium.offerRequested = true;
          tracker.track(createOfferSentEvent(premium, email));
        });
      } else {
        // this is related to only one offer being requested

        const premium: Premium = premiums.find(
          (i: Premium) =>
            i.responseId === requestOffer.offerRequestInput.insuranceResponseId,
        );

        if (premium) {
          premium.offerRequested = true;
          tracker.track(createOfferSentEvent(premium, email));
        }
      }

      const lastOffer = makeLastOffer(
        requestOffer.offerRequestInput,
        inquiryKey,
      );
      LocalStorageUtils.set(
        KEY_LAST_OFFER_PERSONAL_DATA,
        JSON.stringify(lastOffer),
      );

      // workaround:
      // full casco upselling - mark partial casco premium as requested if fullcasco upselling is requested for it
      const fullCascoPremiums =
        upsellingInquiryPremiums?.[UpsellingInquiryPremiumTypes.FULL_CASCO] ??
        [];
      if (fullCascoPremiums.length > 0) {
        const fullCascoPremiumRequested = fullCascoPremiums.find(
          (fcp: Premium) => fcp.responseId === responseId,
        );

        if (fullCascoPremiumRequested) {
          const partialCascoPremium = premiums.find(
            (p: Premium) =>
              p.productBundleId === fullCascoPremiumRequested.productBundleId,
          );

          if (partialCascoPremium) {
            partialCascoPremium.offerRequested = true;
          }
        }
      }

      return of(
        onPremiumsChanged(
          premiums,
          completed,
          completedInsurances,
          totalInsurances,
        ),
      );
    }),
  );

export const sendOfferRequestFailedEpic = (action$) =>
  action$.pipe(
    ofType(SEND_OFFER_REQUEST_FAILED),
    map(() => showAlert(ERROR, OfferAlertMessage.createErrorMessage())),
  );
