import {
  LOAD_INSURANCE_PROVIDERS,
  LOAD_INSURANCE_PROVIDERS_FULFILLED,
  LOAD_INSURANCE_PROVIDERS_FAILED,
} from './actions';

export interface InsuranceProvider {
  name: string;
  phone: string;
  web: string;
  email: string;
}

interface InsuranceProvidersState {
  loadingInsurance: boolean;
  loadingInsuranceFailed: boolean;
  insurances: InsuranceProvider[];
  error: unknown | null;
}

const initialState: InsuranceProvidersState = {
  loadingInsurance: false,
  loadingInsuranceFailed: false,
  insurances: [],
  error: null,
};

const reducer = (state = initialState, action): InsuranceProvidersState => {
  switch (action.type) {
    case LOAD_INSURANCE_PROVIDERS:
      return {
        loadingInsurance: true,
        loadingInsuranceFailed: false,
        insurances: [],
        error: null,
      };
    case LOAD_INSURANCE_PROVIDERS_FULFILLED:
      return {
        loadingInsurance: false,
        loadingInsuranceFailed: false,
        insurances: action.payload ? action.payload : [],
        error: null,
      };
    case LOAD_INSURANCE_PROVIDERS_FAILED:
      return {
        loadingInsurance: false,
        loadingInsuranceFailed: true,
        insurances: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
