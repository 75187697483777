import leasingEmpty from './leasingEmpty.json';
import kmPerYearEmpty from './kmPerYearEmpty.json';
import mileageEmpty from './mileageEmpty.json';
import mileageLessThan from './mileageLessThan.json';
import kmPerYearLessThan from './kmPerYearLessThan.json';
import usageEmpty from './usageEmpty.json';
import registrationCantonEmpty from './registrationCantonEmpty.json';
import registrationCantonIsNull from './registrationCantonIsNull.json';
import priceOfAccessoriesEmpty from './priceOfAccessoriesEmpty.json';
import priceOfAccessoriesLessThan from './priceOfAccessoriesLessThan.json';
import garageEmpty from './garageEmpty.json';
import kmPerYearNotDivisible from './kmPerYearNotDivisible.json';
import firstRegistrationMonthEmpty from './firstRegistrationMonthEmpty.json';
import firstRegistrationYearEmpty from './firstRegistrationYearEmpty.json';
import yearOfPurchaseEmpty from './yearOfPurchaseEmpty.json';
import yearOfPurchaseNotSelected from './yearOfPurchaseNotSelected.json';

export default {
  leasingEmpty,
  kmPerYearEmpty,
  mileageEmpty,
  mileageLessThan,
  kmPerYearLessThan,
  usageEmpty,
  registrationCantonEmpty,
  registrationCantonIsNull,
  priceOfAccessoriesEmpty,
  garageEmpty,
  kmPerYearNotDivisible,
  priceOfAccessoriesLessThan,
  firstRegistrationMonthEmpty,
  firstRegistrationYearEmpty,
  yearOfPurchaseEmpty,
  yearOfPurchaseNotSelected,
};
