export const SAVE_INQUIRY = 'SAVE_INQUIRY';
export const SAVE_INQUIRY_FULFILLED = 'SAVE_INQUIRY_FULFILLED';
export const SAVE_INQUIRY_FAILED = 'SAVE_INQUIRY_FAILED';
export const FETCH_INQUIRY_FULFILLED = 'FETCH_INQUIRY_FULFILLED';
export const FETCH_INQUIRY_FAILED = 'FETCH_INQUIRY_FAILED';
export const FETCH_PREVIOUS_INQUIRY = 'FETCH_PREVIOUS_INQUIRY';
export const FETCH_PREVIOUS_INQUIRY_ABORTED = 'FETCH_PREVIOUS_INQUIRY_ABORTED';
export const FETCH_PREVIOUS_INQUIRY_FULFILLED =
  'FETCH_PREVIOUS_INQUIRY_FULFILLED';
export const STORE_AS24_USER_ID = 'STORE_AS24_USER_ID';

export const saveInsuranceInquiry = (payload) => ({
  type: SAVE_INQUIRY,
  payload,
});

export const saveInsuranceInquiryFulfilled = (payload) => ({
  type: SAVE_INQUIRY_FULFILLED,
  payload,
});

export const saveInsuranceInquiryFailed = (payload) => ({
  type: SAVE_INQUIRY_FAILED,
  payload,
});

export const fetchInsuranceInquiryFulfilled = (payload) => ({
  type: FETCH_INQUIRY_FULFILLED,
  payload,
});

export const fetchInsuranceInquiryFailed = (payload) => ({
  type: FETCH_INQUIRY_FAILED,
  payload,
});

export const fetchPreviousInsuranceInquiry = () => ({
  type: FETCH_PREVIOUS_INQUIRY,
});

export const fetchPreviousInsuranceInquiryAborted = () => ({
  type: FETCH_PREVIOUS_INQUIRY_ABORTED,
});

export const fetchPreviousInsuranceInquiryFulfilled = (payload) => ({
  type: FETCH_PREVIOUS_INQUIRY_FULFILLED,
  payload,
});

export const storeAutoScout24UserId = (payload) => ({
  type: STORE_AS24_USER_ID,
  payload,
});
