import { Premium } from 'types/insurances';
import { SortByNameOptions } from 'utils/premiumConst';
import { UpsellingInquiryPremiumTypes } from 'types/upselling';
import { PremiumLoaderActionTypes } from './premiumLoader';

export const PREMIUMS_CHANGED = 'PREMIUMS_CHANGED' as const;
export const PREMIUM_SORT_CHANGED = 'PREMIUM_SORT_CHANGED' as const;
export const INIT_LOADING_PREMIUMS = 'INIT_LOADING_PREMIUMS' as const;
export const CLEAR_PREMIUMS = 'CLEAR_PREMIUMS' as const;
export const SET_PREMIUMS_AS_OFFER_REQUESTED =
  'SET_PREMIUMS_AS_OFFER_REQUESTED' as const;
export const SET_USPELLING_INQUIRY_PREMIUMS =
  'SET_USPELLING_INQUIRY_PREMIUMS' as const;

export const onPremiumsChanged = (
  premiums: Premium[],
  completed: boolean,
  completedInsurances: number,
  totalInsurances: number,
) => ({
  type: PREMIUMS_CHANGED,
  premiums,
  completed,
  completedInsurances,
  totalInsurances,
});

export const onPremiumSortChanged = (sortBy: SortByNameOptions) => {
  return {
    type: PREMIUM_SORT_CHANGED,
    sortBy,
  };
};

export const initLoadingPremiums = (inquiryKey: string) => ({
  type: INIT_LOADING_PREMIUMS,
  inquiryKey,
});

export const pollPremiums = (inquiryKey: string, pollingInterval: number) => ({
  type: PremiumLoaderActionTypes.StartPollingPremiums as const,
  inquiryKey,
  pollingInterval,
});

export const clearPremiums = () => ({ type: CLEAR_PREMIUMS });

export const stopLoadingPremiums = () => ({
  type: PremiumLoaderActionTypes.StopLoadingPremiums as const,
});

export const delayStopLoadingPremiums = (pollingTimeout) => ({
  type: PremiumLoaderActionTypes.StopLoadingPremiumsDelayed as const,
  pollingTimeout,
});

export const setPremiumsAsOfferRequested = (responseIds: number[]) => ({
  type: SET_PREMIUMS_AS_OFFER_REQUESTED,
  responseIds,
});

export const setUpsellingInquiryPremiums = ({
  premiums,
  originalInquiryKey,
  uppselingType,
}: {
  premiums: Premium[];
  originalInquiryKey: string;
  uppselingType: UpsellingInquiryPremiumTypes;
}) => {
  return {
    type: SET_USPELLING_INQUIRY_PREMIUMS,
    premiums,
    originalInquiryKey,
    uppselingType,
  };
};

export type ActionType = ReturnType<
  | typeof onPremiumsChanged
  | typeof onPremiumSortChanged
  | typeof initLoadingPremiums
  | typeof pollPremiums
  | typeof delayStopLoadingPremiums
  | typeof clearPremiums
  | typeof stopLoadingPremiums
  | typeof setPremiumsAsOfferRequested
  | typeof setUpsellingInquiryPremiums
>;
