import { VEHICLE_SELECTION } from 'containers/InquiryPage/components/StepVehicle/components/const';

export const STEP_OVERVIEW = { name: 'overview', index: 0 };
export const STEP_VEHICLE = { name: 'usageDetails', index: 1 };
export const STEP_DRIVER = { name: 'driver', index: 2 };
export const STEP_DRIVER_MAIN = 'mainDriver';
export const STEP_DRIVER_POLICY = 'policyHolder';
export const STEP_INSURANCE_OPTIONS = { name: 'insuranceOptions', index: 3 };
export const STEP_INCIDENTS = { name: 'incidents', index: 4 };
export const STEP_FINISH = { name: 'finish', index: 5 };

export const ID_GENDER = `${STEP_DRIVER_MAIN}.gender`;
export const NS_COMMON = 'common';
export const NS_COMMON_COMMON = `${NS_COMMON}:common`;
export const NS_STEP_COMMON = `step:${NS_COMMON}`;
export const NS_STEP_OVERVIEW = `step:${STEP_OVERVIEW.name}`;
export const NS_STEP_VEHICLE = `step:${STEP_VEHICLE.name}`;
export const NS_STEP_DRIVER = `step:${STEP_DRIVER.name}`;
export const NS_STEP_INSURANCE_OPTIONS = `step:${STEP_INSURANCE_OPTIONS.name}`;
export const NS_STEP_INCIDENTS = `step:${STEP_INCIDENTS.name}`;
export const STEP_CREATE_OFFER = { name: 'createOffer' };

export const STEP_GETPREMIUM = { name: 'getPremium' };
export const STEP_PREMIUM_DETAIL = { name: 'premiumDetail' };

export const NS_PREMIUM_COMMON = 'premium:common';
export const NS_GETPREMIUM = `premium:${STEP_GETPREMIUM.name}`;
export const NS_PREMIUM_DETAIL = `premium:${STEP_PREMIUM_DETAIL.name}`;
export const NS_REQUEST_OFFER = `premium:${STEP_CREATE_OFFER.name}`;
export const NS_REQUEST_OFFER_CONFIRMATION = `premium:${STEP_CREATE_OFFER.name}Confirmation`;
export const NS_PREMIUM_SUMMARY = 'premium:summary';

export const NS_ERROR_PAGE = `${NS_COMMON}:errorPage`;

export const NS_DIRECT_DEAL = 'direct-deal';
export const NS_DIRECT_DEAL_COMMON = `${NS_DIRECT_DEAL}:common`;
export const NS_DIRECT_DEAL_STEP_PERSONAL = `${NS_DIRECT_DEAL}:step-personal`;
export const NS_DIRECT_DEAL_STEP_VEHICLE = `${NS_DIRECT_DEAL}:step-vehicle`;
export const NS_DIRECT_DEAL_STEP_CONFIRM = `${NS_DIRECT_DEAL}:step-confirm`;
export const NS_DIRECT_DEAL_CONFIRMATION = `${NS_DIRECT_DEAL}:confirmation`;

export const PROP_MAINDRIVER_DETAILS = 'mainDriverPersonalDetails';
export const PROP_INSURANCEHOLDER_DETAILS = 'insuranceHolderPersonalDetails';

export const NS_VEHICLE_SELECTION = VEHICLE_SELECTION;
