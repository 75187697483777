import { getGlobal } from 'utils/browserUtil';

class Tracker {
  getGlobal?: () => Window;
  trackedVariables?: Set<string>;

  constructor() {
    this.trackedVariables = new Set();
    this.getGlobal = getGlobal;
  }

  track(gtmObject) {
    this.addTrackedVariables(gtmObject);

    if (this.isDataLayerDefined()) {
      this.getGlobal().dataLayer.push(gtmObject);
    }
  }

  addTrackedVariables(gaObject) {
    Object.keys(gaObject).forEach((variableName) => {
      this.trackedVariables.add(variableName);
    });
  }

  isDataLayerDefined() {
    return (
      typeof window !== 'undefined' &&
      typeof this.getGlobal().dataLayer !== 'undefined'
    );
  }
}

export default new Tracker();
export { Tracker };
