import { captureException } from '@sentry/node';
import { AxiosResponse } from 'axios';
import { InsuranceProvider } from '../state/InsuranceProviders/reducer';
import { LookupData } from '../state/LookUpData/reducer';
import { INSURANCE_TYPE } from '../utils/const';
import { insuranceService } from './apiClients/insuranceService';

export const fetchLookupData = async (): Promise<LookupData | null> => {
  try {
    const response: AxiosResponse<LookupData> =
      await insuranceService.get('/lookups');

    return response.data;
  } catch (error) {
    captureException(error);
    return null;
  }
};

export const fetchLookupProviders = async (): Promise<InsuranceProvider[]> => {
  const response: AxiosResponse<InsuranceProvider[]> =
    await insuranceService.get(`/lookups/insurances/${INSURANCE_TYPE}`);

  return response.data;
};
