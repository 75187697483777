export const SHARE_PREMIUM_RESULTS = 'SHARE_PREMIUM_RESULTS';
export const SHARE_PREMIUM_DETAILS = 'SHARE_PREMIUM_DETAILS';
export const SEND_EMAIL_FULFILLED = 'SEND_EMAIL_FULFILLED';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';

export const sharePremiumResults = (inquiryKey, emailAddress) => ({
  type: SHARE_PREMIUM_RESULTS,
  inquiryKey,
  emailAddress,
});

export const sharePremiumDetails = (
  inquiryKey,
  premiumIds,
  emailAddress,
  isDirectDeal,
) => ({
  type: SHARE_PREMIUM_DETAILS,
  inquiryKey,
  premiumIds,
  emailAddress,
  isDirectDeal,
});

export const sendEmailFulfilled = (payload) => ({
  type: SEND_EMAIL_FULFILLED,
  payload,
});

export const sendEmailFailed = (payload) => ({
  type: SEND_EMAIL_FAILED,
  payload,
});
