import dateOfMotoDrivingLicenseInvalid from './dateOfMotoDrivingLicenseInvalid.json';
import dateOfMotoDrivingLicenseGreaterThan from './dateOfMotoDrivingLicenseGreaterThan.json';
import dateOfMotoDrivingLicenseLessThan from './dateOfMotoDrivingLicenseLessThan.json';
import dateOfDrivingLicenseInvalid from './dateOfDrivingLicenseInvalid.json';
import dateOfDrivingLicenseGreaterThan from './dateOfDrivingLicenseGreaterThan.json';
import dateOfDrivingLicenseLessThan from './dateOfDrivingLicenseLessThan.json';
import hasAutoDrivingLicenseEmpty from './hasAutoDrivingLicenseEmpty.json';
import dateOfBirthGreaterThan16 from './dateOfBirthGreaterThan16.json';
import driverDateOfBirthGreaterThan16 from './driverDateOfBirthGreaterThan16.json';

export default {
  dateOfMotoDrivingLicenseInvalid,
  dateOfMotoDrivingLicenseGreaterThan,
  dateOfMotoDrivingLicenseLessThan,
  dateOfDrivingLicenseInvalid,
  dateOfDrivingLicenseGreaterThan,
  dateOfDrivingLicenseLessThan,
  hasAutoDrivingLicenseEmpty,
  dateOfBirthGreaterThan16,
  driverDateOfBirthGreaterThan16,
};
