import moment, { now } from 'moment';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { NEVER, Observable, of, timer } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter } from 'rxjs/internal/operators/filter';
import { map, switchMap, takeUntil, concatMap } from 'rxjs/operators';
import { saveLongInquiry } from 'state/InquiryInput/actions';
import { KEY_LAST_INQUIRY_KEY } from 'utils/const';

import { LastInquiry } from 'utils/types';
import CookieUtils from 'utils/cookieUtils';
import { onPremiumsChanged, stopLoadingPremiums } from './actions';
import {
  PremiumLoaderActionTypes,
  ProcessResultParams,
  StartPollingPremiumsAction,
  StopLoadingPremiumsAction,
} from './premiumLoader';
import { getPremiumResults } from '../../../api/premiumResults';

export class PollPremiumsEpicUtils {
  private static getLastInquiry(): LastInquiry {
    return CookieUtils.getCookieValue<LastInquiry>(KEY_LAST_INQUIRY_KEY);
  }

  private static setLastInquiry(lastInquiry: LastInquiry) {
    CookieUtils.setSharedCookie(KEY_LAST_INQUIRY_KEY, lastInquiry, 2);
  }

  public static hasLastInquiry(): boolean {
    const lastInquiry = PollPremiumsEpicUtils.getLastInquiry();

    if (lastInquiry) {
      if (
        lastInquiry.key &&
        moment().diff(lastInquiry.timestamp, 'days') >= 30
      ) {
        lastInquiry.timestamp = moment().valueOf();

        PollPremiumsEpicUtils.setLastInquiry(lastInquiry);
        return false;
      }
    }
    return true;
  }

  public static saveInquiryAction({ inquiryKey }: StartPollingPremiumsAction) {
    let lastInquiry = PollPremiumsEpicUtils.getLastInquiry();

    if (!lastInquiry) {
      lastInquiry = {
        key: inquiryKey,
        timestamp: now(),
      } as LastInquiry;
    }

    PollPremiumsEpicUtils.setLastInquiry(lastInquiry);

    return of(saveLongInquiry());
  }

  public static getOnPremiumsChanged({
    results,
    completed,
    completedInsurances,
    totalInsurances,
  }: ProcessResultParams) {
    const event = onPremiumsChanged(
      results,
      completed,
      completedInsurances,
      totalInsurances,
    );
    if (completed) {
      return of(event, stopLoadingPremiums());
    }
    return of(event);
  }
}

export class PremiumLoaderEpics {
  rootAction: ActionsObservable<StartPollingPremiumsAction>;

  constructor(rootAction: ActionsObservable<StartPollingPremiumsAction>) {
    this.rootAction = rootAction;
    this.checkPollFetchPremiumsEpic =
      this.checkPollFetchPremiumsEpic.bind(this);
  }

  checkPollFetchPremiumsEpic(action: Observable<StartPollingPremiumsAction>) {
    return action.pipe(
      filter(PollPremiumsEpicUtils.hasLastInquiry),
      switchMap(({ inquiryKey, pollingInterval }) =>
        timer(0, pollingInterval).pipe(
          switchMap(() => fromPromise(getPremiumResults(inquiryKey))),
          switchMap((processResultParams) => {
            if (!processResultParams) {
              return NEVER;
            } else {
              return of(processResultParams);
            }
          }),
          switchMap(PollPremiumsEpicUtils.getOnPremiumsChanged),
          takeUntil(
            this.rootAction.pipe(
              ofType(PremiumLoaderActionTypes.StopLoadingPremiums),
            ),
          ),
        ),
      ),
    );
  }

  public checkCreateNewInquiryEpic(
    action: Observable<StartPollingPremiumsAction>,
  ) {
    return action.pipe(
      filter(() => !PollPremiumsEpicUtils.hasLastInquiry()),
      concatMap(PollPremiumsEpicUtils.saveInquiryAction),
    );
  }

  static stopLoadingPremiumsDelayedEpic(
    action$: ActionsObservable<StopLoadingPremiumsAction>,
  ) {
    return action$.pipe(
      ofType(PremiumLoaderActionTypes.StopLoadingPremiumsDelayed),
      switchMap(({ pollingTimeout }) =>
        timer(pollingTimeout).pipe(
          map(stopLoadingPremiums),
          takeUntil(
            action$.pipe(ofType(PremiumLoaderActionTypes.StopLoadingPremiums)),
          ),
        ),
      ),
    );
  }

  static pollPremiumsEpic(
    action: ActionsObservable<StartPollingPremiumsAction>,
  ) {
    const epics = new PremiumLoaderEpics(action);
    return action.pipe(
      ofType(PremiumLoaderActionTypes.StartPollingPremiums),
      combineEpics(
        epics.checkCreateNewInquiryEpic,
        epics.checkPollFetchPremiumsEpic,
      ),
    );
  }
}
