import { MultipleOffersRequest } from '../containers/OfferConfirmationPage/model/MultipleOffersRequest';
import { insuranceService } from './apiClients/insuranceService';

export const requestOffer = (request) => {
  return insuranceService.post('/offer-requests', request);
};

export const requestMultipleOffers = (request: MultipleOffersRequest) => {
  return insuranceService.post('/offer-requests/multiple', request);
};
