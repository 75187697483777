import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const BelsuraLogo = (props: IconProps) => (
  <svg
    className={`a-icon ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 585 130"
    {...props}
  >
    <path
      d="M149.563 103.602H190.042C211.128 103.602 221.541 95.5319 221.541 78.8716C221.541 67.8082 215.293 59.7383 204.49 57.005V56.3542C213.861 52.7098 218.417 46.332 218.417 36.1797C218.417 21.0813 207.744 12.4909 188.61 12.4909H149.563V103.602ZM167.915 89.1541V64.9447H188.871C198.503 64.9447 202.798 68.5891 202.798 76.9192C202.798 85.3795 198.503 89.1541 188.871 89.1541H167.915ZM167.915 50.4971V26.9384H186.137C195.249 26.9384 199.674 30.8432 199.674 38.6527C199.674 46.5923 195.379 50.4971 186.137 50.4971H167.915Z"
      fill="#251405"
    />
    <path
      d="M262.954 104.513C281.827 104.513 293.411 95.5319 295.103 82.2557H277.662C276.491 87.7224 271.805 91.3668 263.215 91.3668C253.192 91.3668 247.465 85.5097 246.554 75.2272H295.364V70.151C295.364 48.9352 283.129 36.0495 262.954 36.0495C241.738 36.0495 228.202 49.4558 228.202 70.4113C228.202 91.2367 241.608 104.513 262.954 104.513ZM246.554 64.5542C247.596 54.532 253.323 49.1955 263.084 49.1955C271.805 49.1955 277.272 54.7923 277.923 64.5542H246.554Z"
      fill="#251405"
    />
    <path
      d="M325.047 103.602H335.85V90.5859H329.472C325.307 90.5859 322.964 88.243 322.964 84.078V9.8877H305.133V83.5573C305.133 96.5732 312.161 103.602 325.047 103.602Z"
      fill="#251405"
    />
    <path
      d="M369.744 104.513C386.925 104.513 396.817 97.4843 396.817 83.9478C396.817 72.2336 391.871 66.7669 377.554 64.2939L367.922 62.4717C361.024 61.3002 359.071 59.2177 359.071 55.4431C359.071 50.8876 362.325 48.4146 368.312 48.4146C374.56 48.4146 377.684 51.0177 378.595 57.1352H395.125C394.084 43.2082 383.931 36.0495 368.312 36.0495C351.912 36.0495 342.151 43.9892 342.151 56.4844C342.151 67.9383 348.398 73.9256 361.674 76.2685L370.916 77.9605C377.163 79.1319 379.766 80.824 379.766 85.1192C379.766 89.2843 375.862 92.0176 369.744 92.0176C362.195 92.0176 357.9 89.2843 356.858 81.9954H340.459C341.239 96.5732 351.652 104.513 369.744 104.513Z"
      fill="#251405"
    />
    <path
      d="M436.748 104.513C457.704 104.513 467.596 94.7509 467.596 74.3161V36.9606H449.634V77.3097C449.634 86.4208 445.339 91.1065 436.748 91.1065C428.548 91.1065 423.863 86.2907 423.863 77.3097V36.9606H406.031V74.3161C406.031 94.7509 415.923 104.513 436.748 104.513Z"
      fill="#251405"
    />
    <path
      d="M480.104 103.602H497.936V71.8431C497.936 57.6558 505.225 52.7098 516.418 52.7098H521.495V36.0495C508.218 36.0495 500.93 41.386 497.676 48.2844H497.155V36.9606H480.104V103.602Z"
      fill="#251405"
    />
    <path
      d="M545.952 104.513C555.063 104.513 562.742 100.738 567.298 93.8398H567.949V103.602H585V62.2114C585 45.0304 575.238 36.0495 556.755 36.0495C539.184 36.0495 527.99 44.5098 526.819 58.9574H544.26C544.911 52.9701 549.336 49.4558 556.235 49.4558C563.263 49.4558 567.038 52.5796 567.038 58.4368C567.038 61.8209 565.866 62.732 561.311 63.5129C549.597 65.0748 542.308 66.1161 537.101 67.9383C528.511 70.8018 524.216 76.7891 524.216 85.3795C524.216 97.3541 532.285 104.513 545.952 104.513ZM541.657 84.078C541.657 80.1732 543.479 77.7002 547.384 76.3986C551.289 74.9669 562.092 74.5764 567.038 73.1447V75.2272C567.038 84.5986 560.139 91.497 550.638 91.497C544.781 91.497 541.657 88.8938 541.657 84.078Z"
      fill="#251405"
    />
    <path
      d="M35.5 32.478H62.6067C68.1551 32.478 72.653 36.9759 72.653 42.5242C72.653 48.0726 68.1551 52.5705 62.6067 52.5705H35.5V32.478Z"
      fill="#FFBB54"
    />
    <path
      d="M35.5 60.478H62.6067C68.1551 60.478 72.653 64.9759 72.653 70.5242C72.653 76.0726 68.1551 80.5705 62.6067 80.5705H35.5V60.478Z"
      fill="#FFBB54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H64.9444C87.1483 0 105.148 17.9998 105.148 40.2037V64.9446C105.148 87.0663 91.9597 103.139 80.2236 113.093C74.2586 118.153 68.3379 121.928 63.9241 124.437C61.7068 125.697 59.8427 126.654 58.5069 127.306C57.8382 127.633 57.2999 127.884 56.9135 128.061C56.7202 128.149 56.5647 128.218 56.4498 128.269C56.3923 128.295 56.3449 128.315 56.308 128.332L56.2604 128.352L56.2352 128.363C56.2319 128.364 56.2288 128.366 52.5741 119.838C48.9194 128.366 48.9163 128.364 48.913 128.363L48.9056 128.36L48.8877 128.352L48.8402 128.332C48.8032 128.315 48.7559 128.295 48.6984 128.269C48.5834 128.218 48.4279 128.149 48.2347 128.061C47.8482 127.884 47.3099 127.633 46.6413 127.306C45.3054 126.654 43.4413 125.697 41.2241 124.437C36.8103 121.928 30.8895 118.153 24.9245 113.093C13.1884 103.139 0 87.0663 0 64.9446V0ZM52.5741 119.838L48.9194 128.366L52.5741 129.932L56.2288 128.366L52.5741 119.838ZM52.5741 109.504C53.2326 109.154 53.9636 108.755 54.7542 108.305C58.4584 106.2 63.3617 103.064 68.2208 98.9428C78.1329 90.5353 86.5926 79.1609 86.5926 64.9446V40.2037C86.5926 28.2478 76.9004 18.5556 64.9444 18.5556H18.5556V64.9446C18.5556 79.1609 27.0153 90.5353 36.9273 98.9428C41.7864 103.064 46.6897 106.2 50.394 108.305C51.1846 108.755 51.9156 109.154 52.5741 109.504ZM48.9029 111.318C48.9002 111.319 48.9006 111.319 48.9029 111.318Z"
      fill="#FFBB54"
    />
    <path
      d="M105.148 0H64.9444C87.1483 0 105.148 17.9998 105.148 40.2037V0Z"
      fill="#FFBB54"
    />
  </svg>
);

BelsuraLogo.displayName = 'BelsuraLogo';

export default BelsuraLogo;
