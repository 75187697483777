import {
  FETCH_VEHICLE_MAKES,
  FETCH_VEHICLE_MAKES_FAILED,
  FETCH_VEHICLE_MAKES_FULFILLED,
  VehicleMakesActionType,
} from './actions';
import { VehicleMakes } from './types';

export interface VehicleMakeState {
  fetchingVehicleMakes: boolean;
  fetchingVehicleMakesFailed: boolean;
  vehicleMakes: VehicleMakes[];
  error: unknown | null;
}

const initialState: VehicleMakeState = {
  fetchingVehicleMakes: false,
  fetchingVehicleMakesFailed: false,
  vehicleMakes: [],
  error: null,
};

const reducer = (
  state = initialState,
  action: VehicleMakesActionType,
): VehicleMakeState => {
  switch (action.type) {
    case FETCH_VEHICLE_MAKES:
      return {
        fetchingVehicleMakes: true,
        fetchingVehicleMakesFailed: false,
        vehicleMakes: [],
        error: null,
      };
    case FETCH_VEHICLE_MAKES_FULFILLED:
      return {
        fetchingVehicleMakes: false,
        fetchingVehicleMakesFailed: false,
        vehicleMakes: action.payload,
        error: null,
      };
    case FETCH_VEHICLE_MAKES_FAILED:
      return {
        fetchingVehicleMakes: false,
        fetchingVehicleMakesFailed: true,
        vehicleMakes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
