import { appendQueryParams } from '@scout24ch/fs24-utils';
import { AxiosResponse, isCancel } from 'axios';
import { InsuranceInquiry } from 'models/InsuranceInquiry';
import { InsuranceComparison } from '../state/InsuranceComparison/types';
import { captureException } from '../utils/sentry';
import { insuranceService } from './apiClients/insuranceService';

export const saveNewInsuranceInquiry = async (data, signal?: AbortSignal) => {
  try {
    const response: AxiosResponse = await insuranceService.post(
      '/insurance-inquiries',
      data,
      { signal },
    );

    return response;
  } catch (error) {
    captureException(error);
  }
};

export const getInsuranceInquiries = async (
  inquiryKey: string,
  signal?: AbortSignal,
) => {
  try {
    const response: AxiosResponse<InsuranceInquiry> =
      await insuranceService.get(`/insurance-inquiries/${inquiryKey}`, {
        signal,
      });

    return response.data;
  } catch (error) {
    if (isCancel(error)) {
      return;
    }

    captureException(error);
  }
};

export const getInsuranceComparison = async (
  inquiryKey: string,
  productBundleIds: number[],
): Promise<InsuranceComparison> => {
  try {
    const parameters = productBundleIds
      .map((id) => `productBundleIds=${id}`)
      .join('&');

    const response: AxiosResponse = await insuranceService.get(
      `/insurance-inquiries/${inquiryKey}/compare?${parameters}`,
    );

    return response.data;
  } catch (error) {
    captureException(error);
  }
};

export const createInquiryWithNewStartDate = async (
  inquiryKey: string,
  productBundleId: number,
  newInsuranceStartDate: string,
) => {
  const response: AxiosResponse = await insuranceService.post(
    `/insurance-inquiries/${inquiryKey}/product/${productBundleId}/new?insuranceStartDate=${newInsuranceStartDate}`,
  );

  return response.data;
};

export const subscribe = (inquiryKey: string, email: string) => {
  return insuranceService.post(
    `/insurance-inquiries/${inquiryKey}/subscribe`,
    email,
  );
};

export const shareComparison = (
  inquiryKey: string,
  emailAddress: string,
  premiumIds: number[],
  isDirectDeal: boolean,
) => {
  const url = appendQueryParams(
    `/insurance-inquiries/${inquiryKey}/compare/share`,
    {
      emailAddress,
      isDirectDeal,
    },
  );

  return insuranceService.post(url, premiumIds);
};

export const sharePremiumResults = (inquiryKey: string, email: string) => {
  const url = `/insurance-inquiries/${inquiryKey}/share?emailAddress=${encodeURIComponent(
    email,
  )}`;

  return insuranceService.post(url);
};
