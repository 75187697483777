import { noop } from '@scout24ch/fs24-utils';
import { useEffect, useRef, useState } from 'react';

interface State {
  visible: boolean;
}

const isClient = typeof window === 'object';
const isIE = () => 'ActiveXObject' in window;

const getDocumentHeight = () => {
  if (document == null) return 0;
  const body = document.getElementsByTagName('body');
  return body.length > 0 ? body[0].scrollHeight : 0;
};
const getBottom = (id: string) => {
  if (document == null) return 0;
  const element = document.getElementById(id);
  return element !== null ? element.offsetTop + element.clientHeight : 0;
};
const checkVisibility = (id: string) =>
  window.scrollY + window.innerHeight - getDocumentHeight() >
  getBottom(id) - getDocumentHeight();

const useElementVisibleFromBottom = (id: string): State => {
  const frame = useRef(0);
  const [state, setState] = useState<State>({
    visible: true,
  });

  useEffect(() => {
    if (isClient && !isIE()) {
      if (state.visible) {
        setState({
          visible: checkVisibility(id),
        });
      }

      const handler = () => {
        cancelAnimationFrame(frame.current);
        frame.current = requestAnimationFrame(() => {
          setState({
            visible: checkVisibility(id),
          });
        });
      };

      window.addEventListener('scroll', handler, {
        capture: false,
        passive: true,
      });

      return () => {
        cancelAnimationFrame(frame.current);
        window.removeEventListener('scroll', handler);
      };
    }
    return noop;
  }, [state.visible, id]);

  return state;
};

export { useElementVisibleFromBottom };
export type VisibleFromBottomState = State;
