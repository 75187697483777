import { PremiumSelection } from 'types/insurances';
import { UpsellingOption } from '../../types/upselling';
import { OfferRequestInput } from './types';

export const SEND_OFFER_REQUEST = 'SEND_OFFER_REQUEST' as const;
export const SEND_OFFER_REQUEST_FULFILLED =
  'SEND_OFFER_REQUEST_FULFILLED' as const;
export const SEND_OFFER_REQUEST_FAILED = 'SEND_OFFER_REQUEST_FAILED' as const;
export const UPDATE_OFFER_REQUEST = 'UPDATE_OFFER_REQUEST' as const;
export const OFFER_VALIDATION_CHANGED = 'OFFER_VALIDATION_CHANGED' as const;
export const TRY_SEND_OFFER_REQUEST = 'TRY_SEND_OFFER_REQUEST' as const;
export const VALIDATE_OFFER_INPUT = 'VALIDATE_OFFER_INPUT' as const;
export const RESUME_OFFER_REQUEST = 'RESUME_OFFER_REQUEST' as const;
export const SET_SELECTED_OFFERS = 'SET_SELECTED_OFFERS' as const;
export const RESET_OFFER_STATE = 'RESET_OFFER_STATE' as const;
export const SET_UPSELLING_OPTIONS = 'SET_UPSELLING_OPTIONS' as const;

export const sendOfferRequest = (responseId: number) => {
  return {
    type: SEND_OFFER_REQUEST,
    responseId,
  };
};

export const sendOfferRequestFailed = (error) => ({
  type: SEND_OFFER_REQUEST_FAILED,
  error,
});

export const sendOfferRequestFulfilled = (responseId: number) => ({
  type: SEND_OFFER_REQUEST_FULFILLED,
  responseId,
});

export const updateOfferRequest = (offerRequestInput: OfferRequestInput) => {
  return {
    type: UPDATE_OFFER_REQUEST,
    offerRequestInput,
  };
};

export const offerValidationChanged = (validation, scrollToValidation) => ({
  type: OFFER_VALIDATION_CHANGED,
  validation,
  scrollToValidation,
});

export const trySendOfferRequest = (responseId: number) => {
  return {
    type: TRY_SEND_OFFER_REQUEST,
    responseId,
  };
};

export const validateOfferInput = () => ({
  type: VALIDATE_OFFER_INPUT,
});

export const resumeOfferRequest = () => ({
  type: RESUME_OFFER_REQUEST,
});

export const updateSelectedOffers = (selectedOffers: PremiumSelection[]) => {
  return {
    type: SET_SELECTED_OFFERS,
    selectedOffers,
  };
};

export const resetOfferState = () => ({
  type: RESET_OFFER_STATE,
});

export const setUpSellingOptions = (upSellingOptions: UpsellingOption[]) => ({
  type: SET_UPSELLING_OPTIONS,
  upSellingOptions,
});
