import _cloneDeep from 'lodash/clone';
import cancellationOrSpecialConditionsEmpty from './cancellationOrSpecialConditionsEmpty.json';
import denialOrSpecialConditionsEmpty from './denialOrSpecialConditionsEmpty.json';
import additionalQuestions from './additionalQuestions.json';
import claimTypeEmpty from './claimTypeEmpty.json';
import claimAmountEmpty from './claimAmountEmpty.json';
import claimAmountGreaterThan from './claimAmountGreaterThan.json';
import claimCauseEmpty from './claimCauseEmpty.json';
import claimSelfInflictedEmpty from './claimSelfInflictedEmpty.json';
import claimCauserEmpty from './claimCauserEmpty.json';
import claimDateEmpty from './claimDateEmpty.json';
import claimDateInFuture from './claimDateInFuture.json';
import infringementReasonEmpty from './infringementReasonEmpty.json';
import infringementDateEmpty from './infringementDateEmpty.json';
import infringementDateInFuture from './infringementDateInFuture.json';
import suspensionPeriodEmpty from './suspensionPeriodEmpty.json';
import suspensionDateEmpty from './suspensionDateEmpty.json';
import suspensionDateInFuture from './suspensionDateInFuture.json';
import ageOfYoungestChildEmpty from './ageOfYoungestChildEmpty.json';
import dataProtectionAcceptedEmpty from './dataProtectionAcceptedEmpty.json';

const assignIndex = (fact, index) => {
  const name = fact.event.type.replace('{index}', index);
  const newType = {
    event: Object.assign(_cloneDeep(fact.event), { type: name }),
  };
  return Object.assign(_cloneDeep(fact), newType);
};

const claim = (index) => {
  const typeEmpty = assignIndex(claimTypeEmpty, index);
  const amountEmpty = assignIndex(claimAmountEmpty, index);
  const amountGreaterThan = assignIndex(claimAmountGreaterThan, index);
  const causeEmpty = assignIndex(claimCauseEmpty, index);
  const selfInflictedEmpty = assignIndex(claimSelfInflictedEmpty, index);
  const causerEmpty = assignIndex(claimCauserEmpty, index);
  const dateEmpty = assignIndex(claimDateEmpty, index);
  const dateInFuture = assignIndex(claimDateInFuture, index);
  return {
    typeEmpty,
    amountEmpty,
    amountGreaterThan,
    causeEmpty,
    selfInflictedEmpty,
    causerEmpty,
    dateEmpty,
    dateInFuture,
  };
};

const infringement = (index) => {
  const reasonEmpty = assignIndex(infringementReasonEmpty, index);
  const dateEmpty = assignIndex(infringementDateEmpty, index);
  const dateInFuture = assignIndex(infringementDateInFuture, index);
  return {
    reasonEmpty,
    dateEmpty,
    dateInFuture,
  };
};

const suspension = (index) => {
  const periodEmpty = assignIndex(suspensionPeriodEmpty, index);
  const dateEmpty = assignIndex(suspensionDateEmpty, index);
  const dateInFuture = assignIndex(suspensionDateInFuture, index);
  return {
    periodEmpty,
    dateEmpty,
    dateInFuture,
  };
};

export default {
  cancellationOrSpecialConditionsEmpty,
  denialOrSpecialConditionsEmpty,
  additionalQuestions,
  ageOfYoungestChildEmpty,
  dataProtectionAcceptedEmpty,
  claim,
  infringement,
  suspension,
};
