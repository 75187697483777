import { __TEST__ } from '@scout24ch/fs24-utils';

export async function fetchConfig(): Promise<Record<string, string>> {
  if (__TEST__) {
    return Promise.resolve(Object.create(process.env));
  }

  return import('@scout24ch/fs24-config').then(({ fetchConfig }) =>
    fetchConfig('/vehicle-insurance-frontend'),
  );
}
