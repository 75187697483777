import { NOT_PROVIDED } from '@scout24ch/fs24-gtm';
import {
  CATEGORY_INSURANCE_COMPARISON,
  ACTION_GOTO_DIRECT_DEAL,
} from 'utils/GTM/events';
import { Premium, ProductBundle } from 'types/insurances';
import { ID_PRODUCT_BUNDLE_ID, ID_MATCH } from './premiumConst';

export const premiumsFromIds = (premiums: Premium[], premiumIds: number[]) => {
  return premiums
    .filter((premium) =>
      premiumIds.some((id) => id === premium[ID_PRODUCT_BUNDLE_ID]),
    )
    .sort(
      (a, b) =>
        premiumIds.indexOf(a.productBundleId) -
        premiumIds.indexOf(b.productBundleId),
    );
};

export const premiumFromId = (premiums: Premium[], premiumId: number) => {
  return premiums.find(
    (premium) => premiumId === premium[ID_PRODUCT_BUNDLE_ID],
  );
};

export const createGoToDirectDealGtmTrackingData = (
  premium: ProductBundle | Premium,
) => ({
  eventCategory: CATEGORY_INSURANCE_COMPARISON,
  eventAction: ACTION_GOTO_DIRECT_DEAL,
  eventLabel: `Insurance: ${premium.insuranceName} # Product: ${premium.productName}`,
});

export const prependZeros = (num: number | string, totalLength: number) =>
  String(num).padStart(totalLength, '0');

export const getRoundedMatchingScoreValue = (premium: Premium) => {
  const match = Math.round(premium[ID_MATCH] * 100);
  return match < 0 ? 0 : match;
};

export const getPremiumOrderPosition = (
  premiums: Premium[],
  productBundleId: number,
): number | string => {
  const index = premiums.findIndex(
    (premium) => premium.productBundleId === productBundleId,
  );

  return index > -1 ? index + 1 : NOT_PROVIDED;
};

export function getPremiumDiscountPrice(priceTotal: number, discount: number) {
  const price = priceTotal - discount;

  if (price < 0) {
    return 0;
  }

  return price;
}
