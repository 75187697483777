import { UpsellingOption } from '../../types/upselling';
import {
  CROSSENGAGE_EMAIL_SENT,
  FETCH_UPSELLING_OPTIONS_FULFILLED,
} from './actions';

export interface DirectDealState {
  isCrossEngageEmailSent?: boolean;
  upSellingOptions?: UpsellingOption[];
}

const initialState: DirectDealState = {
  isCrossEngageEmailSent: false,
  upSellingOptions: [],
};

const directDealReducer = (
  state: DirectDealState = initialState,
  action,
): DirectDealState => {
  switch (action.type) {
    case CROSSENGAGE_EMAIL_SENT:
      return {
        ...state,
        isCrossEngageEmailSent: true,
      };
    case FETCH_UPSELLING_OPTIONS_FULFILLED:
      return {
        ...state,
        upSellingOptions: action.payload,
        isCrossEngageEmailSent: false,
      };
    default:
      return state;
  }
};

export default directDealReducer;
