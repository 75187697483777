export const LOAD_INSURANCE_PROVIDERS = 'LOAD_INSURANCE_PROVIDERS';
export const LOAD_INSURANCE_PROVIDERS_FULFILLED =
  'LOAD_INSURANCE_PROVIDERS_FULFILLED';
export const LOAD_INSURANCE_PROVIDERS_FAILED =
  'LOAD_INSURANCE_PROVIDERS_FAILED';

export const loadInsuranceProviders = () => ({
  type: LOAD_INSURANCE_PROVIDERS,
});

export const loadInsurancesFullfilled = (payload) => ({
  type: LOAD_INSURANCE_PROVIDERS_FULFILLED,
  payload,
});

export const loadInsurancesFailed = (payload) => ({
  type: LOAD_INSURANCE_PROVIDERS_FAILED,
  payload,
});
