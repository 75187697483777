import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOnMount } from '@scout24ch/fs24-hooks';
import { getCanHide } from 'state/LoadingScreen/selectors';
import {
  startHideLoadingScreen,
  startHideLoadingScreenDelayed,
  startShowLoadingScreen,
} from 'state/LoadingScreen/actions';

export interface LoadingIndicatorHolderProps {
  delayed?: boolean;
  isLogoLoader?: boolean;
  text?: string;
}

export function LoadingIndicatorHolder(
  props: LoadingIndicatorHolderProps,
): React.ReactElement {
  const { delayed, isLogoLoader, text } = props;

  const dispatch = useDispatch();
  const canHide = useSelector(getCanHide);

  useOnMount(() => {
    dispatch(startShowLoadingScreen(text, isLogoLoader));

    return () => {
      hide();
    };
  });

  function hide() {
    if (isLogoLoader) {
      return;
    }

    if (delayed) {
      dispatch(startHideLoadingScreenDelayed(500));
    } else if (canHide) {
      dispatch(startHideLoadingScreen());
    }
  }

  return null;
}
