import { VehicleMakes } from './types';

export const FETCH_VEHICLE_MAKES = 'FETCH_VEHICLE_MAKES' as const;
export const FETCH_VEHICLE_MAKES_FULFILLED =
  'FETCH_VEHICLE_MAKES_FULFILLED' as const;
export const FETCH_VEHICLE_MAKES_FAILED = 'FETCH_VEHICLE_MAKES_FAILED' as const;

export const startFetchVehicleMakes = () => ({ type: FETCH_VEHICLE_MAKES });

export const fetchVehicleMakesFulfilled = (payload: VehicleMakes[]) => ({
  type: FETCH_VEHICLE_MAKES_FULFILLED,
  payload,
});

export const fetchVehicleMakesFailed = (payload: unknown) => ({
  type: FETCH_VEHICLE_MAKES_FAILED,
  payload,
});

export type VehicleMakesActionType = ReturnType<
  | typeof startFetchVehicleMakes
  | typeof fetchVehicleMakesFulfilled
  | typeof fetchVehicleMakesFailed
>;
