export const SELECT_PREMIUM_ID = 'SELECT_PREMIUM_ID';
export const DESELECT_PREMIUM_ID = 'DESELECT_PREMIUM_ID';
export const CLEAR_SELECTED_PREMIUM_IDS = 'CLEAR_SELECTED_PREMIUM_IDS';

export const selectPremiumId = (premiumId: number) => {
  return {
    type: SELECT_PREMIUM_ID,
    premiumId,
  } as const;
};

export const deselectPremiumId = (premiumId: number) => {
  return {
    type: DESELECT_PREMIUM_ID,
    premiumId,
  } as const;
};

export const clearSelectedPremiumIds = () => {
  return { type: CLEAR_SELECTED_PREMIUM_IDS } as const;
};
