import { isNil } from 'utils';
import { DriverDetails } from '../../state/InquiryInput/types';

export function makeDriverDetails(): DriverDetails {
  return {
    numberOfChildren: null,
    ageOfTheYoungestChild: null,
    otherDrivers: null,
    driversOutsideTheHousehold: null,
    driversWithLearningOrTrialLicense: null,
    driversUnderTheAgeOf25: null,
  };
}

export function toJSON(details: DriverDetails): DriverDetails {
  if (isNil(details)) {
    return details;
  }

  return {
    numberOfChildren: details.numberOfChildren,
    ageOfTheYoungestChild: details.ageOfTheYoungestChild,
    otherDrivers: details.otherDrivers,
    driversOutsideTheHousehold: details.driversOutsideTheHousehold,
    driversWithLearningOrTrialLicense:
      details.driversWithLearningOrTrialLicense,
    driversUnderTheAgeOf25: details.driversUnderTheAgeOf25,
  };
}
