export const PARAM_VEHICLE_ID = 'vehicleId';
export const PARAM_INQUIRY_KEY = 'inquiryKey';
export const PARAM_STEP = 'step';
export const PARAM_ID = 'id';
export const PARAM_IDS = 'ids';
export const PARAM_MAKE = 'make';
export const PARAM_SEARCH_TEST = 'searchType';
export const PARAM_DIRECT_DEAL = 'directDeal';
export const PARAM_ADJUST = 'adjust';
export const PARAM_UTM_SOURCE = 'utm_source';
export const PARAM_UTM_MEDIUM = 'utm_medium';
export const PARAM_UTM_CONTENT = 'utm_content';
export const PARAM_USER_ID = 'userId';
