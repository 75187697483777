// @ts-ignore
import { Engine } from 'json-rules-engine';
import {
  isEmpty,
  isDateNotValid,
  isDateGreater,
  isDateSmaller,
  isEmptyOrNone,
} from 'utils/operators';

import {
  ValidationSuccessFunction,
  ValidationFailureFunction,
  ValidationError,
} from 'utils/validation/types';
import InsuranceOptions from '../types';
import rules from './rules';

const engine = new Engine();
engine.addOperator('empty', isEmpty);
engine.addOperator('notDate', isDateNotValid);
engine.addOperator('greaterDate', isDateGreater);
engine.addOperator('lessDate', isDateSmaller);
engine.addOperator('emptyOrNone', isEmptyOrNone);

engine.addRule(rules.partialCoverInsuranceCostSharingEmpty);
engine.addRule(rules.freeGarageChoiceEmpty);
engine.addRule(rules.fullyComprehensiveInsuranceCostSharingEmpty);

engine.addRule(rules.isBonusProtectionEmpty);

engine.addRule(rules.carriedThingsInsuranceSumEmpty);
engine.addRule(rules.isParkingDamageEmpty);
engine.addRule(rules.isParkingDamageSumUnlimitedEmpty);
engine.addRule(rules.hasExistingParkingDamageEmpty);

engine.addRule(rules.isAccidentInsuranceEmpty);
engine.addRule(rules.accidentInsuredPersonsEmpty);
engine.addRule(rules.accidentInsuranceSumPayableAtDeathEmpty);
engine.addRule(rules.accidentInsuranceSumPayableAtDisabilityEmpty);
engine.addRule(rules.accidentInsuranceDailyAllowanceEmpty);

engine.addRule(rules.currentInsuranceCompanyEmpty);
engine.addRule(rules.currentInsuranceCompanyNotSelected);

const validateInsuranceOptions = (
  insuranceOptions: InsuranceOptions,
  vehicle: any,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => {
  if (vehicle.dateOfFirstRegistration) {
    engine.addFact('dateOfFirstRegistration', vehicle.dateOfFirstRegistration);
  }
  return engine
    .run(insuranceOptions)
    .then((validationErrors: ValidationError[]) => {
      if (validationErrors.length > 0) {
        return failure(validationErrors);
      }
      return success();
    });
};

export default validateInsuranceOptions;
