// @ts-ignore
import { Engine } from 'json-rules-engine';
import moment from 'moment';
import {
  isEmpty,
  isNotEmpty,
  isDateNotValid,
  isDateGreater,
  isDateLess18YearsSmaller,
  isDateLess16YearsSmaller,
  isDateSmaller,
  isEmailInvalid,
} from 'utils/operators';

import {
  ValidationSuccessFunction,
  ValidationFailureFunction,
  ValidationError,
} from 'utils/validation/types';
import rules from './rules';
import motoRules from './motoRules';
import {
  InsuranceOptions,
  Person,
} from '../../../../../state/InquiryInput/types';
import { validateStartInsuranceDate } from './validateStartInsuranceDate';

export const motoEngine = new Engine();
motoEngine.addOperator('empty', isEmpty);
motoEngine.addOperator('notEmpty', isNotEmpty);
motoEngine.addOperator('notDate', isDateNotValid);
motoEngine.addOperator('greaterDate', isDateGreater);
motoEngine.addOperator('lessDate', isDateLess18YearsSmaller);
motoEngine.addOperator('lessDate16', isDateLess16YearsSmaller);
motoEngine.addOperator('smallerDate', isDateSmaller);
motoEngine.addOperator('emailInvalid', isEmailInvalid);

motoEngine.addFact('today', new Date());
motoEngine.addFact('todayLess16Years', moment().add(-16, 'y').toDate());
motoEngine.addFact('todayLess18Years', moment().add(-18, 'y').toDate());
motoEngine.addFact('todayLess100Years', moment().add(-100, 'y').toDate());

motoEngine.addRule(rules.genderEmpty);

motoEngine.addRule(rules.dateOfBirthInvalid);
motoEngine.addRule(rules.dateOfBirthLesserThan);

motoEngine.addRule(motoRules.hasAutoDrivingLicenseEmpty);
motoEngine.addRule(motoRules.dateOfDrivingLicenseGreaterThan);
motoEngine.addRule(motoRules.dateOfDrivingLicenseInvalid);
motoEngine.addRule(motoRules.dateOfDrivingLicenseLessThan);
motoEngine.addRule(motoRules.dateOfMotoDrivingLicenseInvalid);
motoEngine.addRule(motoRules.dateOfMotoDrivingLicenseGreaterThan);
motoEngine.addRule(motoRules.dateOfMotoDrivingLicenseLessThan);

motoEngine.addRule(rules.postCodeInvalid);

motoEngine.addRule(rules.nationalityEmpty);

motoEngine.addRule(rules.residencePermitEmpty);
motoEngine.addRule(rules.residencePermitSinceInvalid);
motoEngine.addRule(rules.residencePermitSinceGreaterThan);
motoEngine.addRule(rules.residencePermitSinceLessThan);
motoEngine.addRule(rules.mainDriverEmailMandatoryInvalid);
motoEngine.addRule(rules.policyHolderEmailInvalid);

motoEngine.addRule(rules.isMainDriverEmpty);
motoEngine.addRule(rules.driverDateOfBirthInvalid);
motoEngine.addRule(rules.driverDateOfBirthLesserThan);
motoEngine.addRule(rules.driverGenderEmpty);
motoEngine.addRule(rules.driverPostCodeInvalid);
motoEngine.addRule(rules.driverNationalityEmpty);
motoEngine.addRule(rules.driverResidencePermitEmpty);
motoEngine.addRule(rules.driverResidencePermitSinceInvalid);
motoEngine.addRule(rules.driverResidencePermitSinceGreaterThan);
motoEngine.addRule(rules.driverResidencePermitSinceLessThan);

motoEngine.addRule(rules.otherDriversEmpty);
motoEngine.addRule(rules.driversOutsideTheHouseholdEmpty);
motoEngine.addRule(rules.driversWithLearningOrTrialLicenseEmpty);
motoEngine.addRule(rules.driversUnderTheAgeOf25Empty);
motoEngine.addRule(rules.cityInvalid);
motoEngine.addRule(rules.cantonInvalid);

const validateDriver = (
  mainDriver: Person,
  policyHolder: Person,
  insuranceOptions: InsuranceOptions,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => {
  return motoEngine
    .run({
      mainDriver,
      policyHolder,
    })
    .then((validationErrors: ValidationError[]) => {
      const insuranceDateErrors = validateStartInsuranceDate(
        insuranceOptions.insuranceStartDate,
      );

      const errors = [...validationErrors, ...insuranceDateErrors];

      if (errors.length > 0) {
        return failure(errors);
      }

      return success();
    });
};

export default validateDriver;
