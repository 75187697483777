import { Action } from 'redux';

export enum PremiumLoaderActionTypes {
  StopLoadingPremiumsDelayed = 'STOP_LOADING_PREMIUMS_DELAYED',
  StartPollingPremiums = 'START_POLLING_PREMIUMS',
  StopLoadingPremiums = 'STOP_LOADING_PREMIUMS',
}

export interface StartPollingPremiumsAction
  extends Action<PremiumLoaderActionTypes.StartPollingPremiums> {
  type: PremiumLoaderActionTypes.StartPollingPremiums;
  inquiryKey: string;
  pollingInterval: number;
}

export interface StopLoadingPremiumsAction
  extends Action<PremiumLoaderActionTypes.StopLoadingPremiumsDelayed> {
  type: PremiumLoaderActionTypes.StopLoadingPremiumsDelayed;
  pollingTimeout: number;
}

export interface ProcessResultParams {
  results: any[];
  completed: boolean;
  completedInsurances: number;
  totalInsurances: number;
}
