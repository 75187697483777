import { PersonalDetails } from 'containers/DirectDealPage/model/DirectDealInput';

export interface LastOffer {
  inquiryKey: string;
  mainDriverPersonalDetails: PersonalDetails;
  insuranceHolderPersonalDetails: PersonalDetails;
}

export function makeLastOffer(
  offerRequestInput: any,
  inquiryKey: string,
): LastOffer {
  return {
    inquiryKey,
    mainDriverPersonalDetails: offerRequestInput.mainDriverPersonalDetails,
    insuranceHolderPersonalDetails:
      offerRequestInput.insuranceHolderPersonalDetails,
  };
}
