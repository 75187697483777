import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLoadingScreenText,
  getLoadingScreenVisible,
  getLogoLoader,
} from 'state/LoadingScreen/selectors';
import { LoadingIndicator } from './LoadingIndicator';

export function LoadingScreen() {
  const loadingScreenVisible = useSelector(getLoadingScreenVisible);
  const loadingScreenText = useSelector(getLoadingScreenText);
  const isLogoLoader = useSelector(getLogoLoader);

  if (!loadingScreenVisible) {
    return null;
  }

  return (
    <LoadingIndicator text={loadingScreenText} isLogoLoader={isLogoLoader} />
  );
}
