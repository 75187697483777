import moment from 'moment';
import { DATE_FORMAT_DAY } from 'utils/dateformat-const';

function getValidDateOrTomorrow(startDate: string) {
  let formattedStartDate = moment(startDate, 'YYYY-MM-DD').format(
    DATE_FORMAT_DAY,
  );
  if (formattedStartDate <= moment().format(DATE_FORMAT_DAY)) {
    formattedStartDate = moment().add(1, 'd').format(DATE_FORMAT_DAY);
  }

  return formattedStartDate;
}

function getDaysDiff(endDate: Date, startDate?: Date): number {
  if (!startDate) {
    startDate = new Date();
    startDate.setUTCHours(0, 0, 0, 0);
  }

  const diffInMilliseconds = endDate.getTime() - startDate.getTime();

  return Math.round(diffInMilliseconds / (1000 * 3600 * 24));
}

export { getValidDateOrTomorrow, getDaysDiff };
