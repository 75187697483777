import accidentInsuranceDailyAllowanceEmpty from './accidentInsuranceDailyAllowanceEmpty.json';
import accidentInsuranceSumPayableAtDeathEmpty from './accidentInsuranceSumPayableAtDeathEmpty.json';
import accidentInsuranceSumPayableAtDisabilityEmpty from './accidentInsuranceSumPayableAtDisabilityEmpty.json';
import accidentInsuredPersonsEmpty from './accidentInsuredPersonsEmpty.json';
import carriedThingsInsuranceSumEmpty from './carriedThingsInsuranceSumEmpty.json';
import currentInsuranceCompanyEmpty from './currentInsuranceCompanyEmpty.json';
import currentInsuranceCompanyNotSelected from './currentInsuranceCompanyNotSelected.json';
import freeGarageChoiceEmpty from './freeGarageChoiceEmpty.json';
import fullyComprehensiveInsuranceCostSharingEmpty from './fullyComprehensiveInsuranceCostSharingEmpty.json';
import hasExistingParkingDamageEmpty from './hasExistingParkingDamageEmpty.json';
import isAccidentInsuranceEmpty from './isAccidentInsuranceEmpty.json';
import isBonusProtectionEmpty from './isBonusProtectionEmpty.json';
import isParkingDamageEmpty from './isParkingDamageEmpty.json';
import isParkingDamageSumUnlimitedEmpty from './isParkingDamageSumUnlimitedEmpty.json';
import partialCoverInsuranceCostSharingEmpty from './partialCoverInsuranceCostSharingEmpty.json';

export default {
  partialCoverInsuranceCostSharingEmpty,
  fullyComprehensiveInsuranceCostSharingEmpty,
  isBonusProtectionEmpty,
  carriedThingsInsuranceSumEmpty,
  isParkingDamageEmpty,
  isParkingDamageSumUnlimitedEmpty,
  hasExistingParkingDamageEmpty,
  isAccidentInsuranceEmpty,
  accidentInsuredPersonsEmpty,
  accidentInsuranceSumPayableAtDeathEmpty,
  accidentInsuranceSumPayableAtDisabilityEmpty,
  accidentInsuranceDailyAllowanceEmpty,
  currentInsuranceCompanyEmpty,
  freeGarageChoiceEmpty,
  currentInsuranceCompanyNotSelected,
};
