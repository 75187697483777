import _findLast from 'lodash/findLast';
import _get from 'lodash/get';
import { isNil } from 'utils';
import hasValidationErrors from './validation/hasErrors';

export interface Validation {
  type: string;
  params?: { prefix?: string; message?: string };
}

export default class EvaluatedValidation {
  constructor(
    private validation: ReadonlyArray<Validation>,
    private prefix: string,
  ) {}

  key(key: string, prefix = this.prefix): string | undefined {
    const element = this.findElementWithKey(key);
    if (!isNil(element)) {
      const actualPrefix = _get(element.params, 'prefix') || prefix;
      return `${actualPrefix}:${
        _get(element.params, 'message') || element.type
      }`;
    }
    return undefined;
  }

  isInvalid(key: string): boolean {
    return !isNil(this.findElementWithKey(key));
  }

  findElementWithKey(key: string): Validation {
    return _findLast(this.validation, (p) => p.type.startsWith(`${key}-`));
  }

  get hasErrors(): boolean {
    return hasValidationErrors(this.validation);
  }
}
