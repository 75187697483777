import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { showAlert } from 'components/Alert/state/actions';
import { ERROR, SUCCESS } from 'components/Alert/state/types';
import AlertMessage from 'components/Alert/AlertMessage';
import { NS_PREMIUM_COMMON, NS_REQUEST_OFFER } from 'utils/namespace-const';
import {
  sendEmailFailed,
  sendEmailFulfilled,
  SEND_EMAIL_FAILED,
  SEND_EMAIL_FULFILLED,
  SHARE_PREMIUM_DETAILS,
  SHARE_PREMIUM_RESULTS,
} from './actions';
import {
  shareComparison,
  sharePremiumResults,
} from '../../../../api/insuranceInquiries';

const ALERT_SUCCESS = AlertMessage.createTranslatedMessage(
  `${NS_REQUEST_OFFER}.alert.success-share`,
);
const ALERT_ERROR = AlertMessage.createTranslatedMessage(
  `${NS_PREMIUM_COMMON}.alert.error`,
);

export const sharePremiumResultsEpic = (action$) =>
  action$.pipe(
    ofType(SHARE_PREMIUM_RESULTS),
    mergeMap(({ inquiryKey, emailAddress }) =>
      from(sharePremiumResults(inquiryKey, emailAddress)).pipe(
        map(() => sendEmailFulfilled(emailAddress)),
        catchError((e) => of(sendEmailFailed(e))),
      ),
    ),
  );

export const sharePremiumDetailsEpic = (action$) =>
  action$.pipe(
    ofType(SHARE_PREMIUM_DETAILS),
    mergeMap(({ inquiryKey, premiumIds, emailAddress, isDirectDeal }) =>
      from(
        shareComparison(inquiryKey, emailAddress, premiumIds, isDirectDeal),
      ).pipe(
        map(() => sendEmailFulfilled(emailAddress)),
        catchError((e) => of(sendEmailFailed(e))),
      ),
    ),
  );

export const sendEmailFulFilledEpic = (action$, state$) =>
  action$.pipe(
    ofType(SEND_EMAIL_FULFILLED),
    map(() =>
      showAlert(
        SUCCESS,
        ALERT_SUCCESS,
        AlertMessage.createEmailMessage(state$.value.share.email),
      ),
    ),
  );

export const sendEmailFailedEpic = (action$) =>
  action$.pipe(
    ofType(SEND_EMAIL_FAILED),
    map(() => showAlert(ERROR, ALERT_ERROR)),
  );
