import { Engine } from 'json-rules-engine';
import {
  isEmpty,
  isNotEmpty,
  isEmailInvalid,
  isPhoneInvalid,
  isSwissMobileNumberInvalid,
} from 'utils/operators';
import { OfferPageState } from '../../../../../state/RequestOffer/types';
import {
  ValidationFailureFunction,
  ValidationSuccessFunction,
} from '../../../../../utils/validation/types';
import { ID_SELECTED_OFFERS } from '../const';

import rules from './rules';

export const engine = new Engine();
engine.addOperator('empty', isEmpty);
engine.addOperator('notEmpty', isNotEmpty);
engine.addOperator('emailInvalid', isEmailInvalid);
engine.addOperator('phoneInvalid', isPhoneInvalid);
engine.addOperator('swissMobilePhoneInvalid', isSwissMobileNumberInvalid);

engine.addRule(rules.firstNameEmpty);
engine.addRule(rules.nameEmpty);
engine.addRule(rules.streetEmpty);
engine.addRule(rules.cityEmpty);
engine.addRule(rules.privatePhoneEmpty);
engine.addRule(rules.privatePhoneInvalid);
engine.addRule(rules.emailEmpty);
engine.addRule(rules.emailInvalid);
engine.addRule(rules.houseNumberToLong);
engine.addRule(rules.holderFirstNameEmpty);
engine.addRule(rules.holderNameEmpty);
engine.addRule(rules.holderStreetEmpty);
engine.addRule(rules.holderCityEmpty);
engine.addRule(rules.holderPrivatePhoneEmpty);
engine.addRule(rules.holderPrivatePhoneInvalid);
engine.addRule(rules.holderEmailEmpty);
engine.addRule(rules.holderEmailInvalid);
engine.addRule(rules.holderHouseNumberToLong);
engine.addRule(rules.cityInvalid);
engine.addRule(rules.cantonInvalid);

const validateOffer = (
  offerRequest: OfferPageState,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => {
  return engine.run(offerRequest?.offerRequestInput ?? {}).then((errors) => {
    if (offerRequest.selectedOffers.length > 1) {
      const isSelected = offerRequest.selectedOffers.find(
        (offer) => offer.isSelected,
      );
      if (!isSelected) {
        errors.push({
          type: `${ID_SELECTED_OFFERS}-empty`,
        });
      }
    }

    if (errors.length > 0) {
      return failure(errors);
    }

    return success(offerRequest.offerRequestInput);
  });
};

export default validateOffer;
