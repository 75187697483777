import { Flex, Spinner, Text, theme } from '@scout24ch/fs24-design-system';
import React from 'react';
import { useTranslation } from 'hooks';
import { LogoLoader } from './LogoLoader';

export interface LoadingIndicatorProps {
  text?: string;
  isLogoLoader?: boolean;
}

export function LoadingIndicator(
  props: LoadingIndicatorProps,
): React.ReactElement {
  const { text, isLogoLoader } = props;
  const { t } = useTranslation();

  return isLogoLoader ? (
    <LogoLoader />
  ) : (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      w="full"
      h="full"
      zIndex="modal"
      backgroundColor={theme.colors.whiteAlpha[900]}
    >
      <Text fontSize="2xl" mb={8}>
        {t(text)}
      </Text>
      <Spinner />
    </Flex>
  );
}
