import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import {
  LOAD_INSURANCE_PROVIDERS,
  loadInsurancesFailed,
  loadInsurancesFullfilled,
} from './actions';
import { fetchLookupProviders } from '../../api/lookups';

const loadInsurancesEpic = (action$) =>
  action$.pipe(
    ofType(LOAD_INSURANCE_PROVIDERS),
    mergeMap(() =>
      from(fetchLookupProviders()).pipe(
        map((response) => loadInsurancesFullfilled(response)),
        catchError((e) => of(loadInsurancesFailed(e))),
      ),
    ),
  );

export default loadInsurancesEpic;
