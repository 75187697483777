import { Step } from 'types/funnel';

export const INQUIRY_INPUT_CHANGED = 'INQUIRY_INPUT_CHANGED';
export const REQUEST_CHANGE_STEP = 'REQUEST_CHANGE_STEP';
export const INQUIRY_STEP_CHANGED = 'INQUIRY_STEP_CHANGED';
export const VALIDATION_CHANGED = 'VALIDATION_CHANGED';
export const VALIDATE_INPUT = 'VALIDATE_INPUT';
export const SAVE_LONG_INQUIRY = 'SAVE_LONG_INQUIRY';
export const CREATE_LONG_INQUIRY = 'CREATE_LONG_INQUIRY';
export const LOAD_LONG_INQUIRY = 'LOAD_LONG_INQUIRY';
export const RESUME_LONG_INQUIRY = 'RESUME_LONG_INQUIRY';
export const CHANGE_STEP_WITH_VALIDATION = 'CHANGE_STEP_WITH_VALIDATION';
export const INQUIRY_SEARCH_TYPE_CHANGED = 'INQUIRY_SEARCH_TYPE_CHANGED';

export const inquiryInputChanged = (input, nameOverride?: string) => {
  return {
    type: INQUIRY_INPUT_CHANGED,
    input,
    nameOverride,
  };
};

export const requestChangeStep = (step: Step, currentStep?: Step) => ({
  type: REQUEST_CHANGE_STEP,
  step,
  currentStep,
});

export const changeStep = (
  step,
  isStepVehicleValid,
  isStepDriverValid,
  isStepInsuranceOptionsValid,
  isStepIncidentsValid,
) => ({
  type: INQUIRY_STEP_CHANGED,
  step,
  isStepVehicleValid,
  isStepDriverValid,
  isStepInsuranceOptionsValid,
  isStepIncidentsValid,
});

export const changeStepWithValidation = (step, currentStep) => ({
  type: CHANGE_STEP_WITH_VALIDATION,
  step,
  currentStep,
});

export const validateInput = () => ({
  type: VALIDATE_INPUT,
});

export const validationChanged = (validation, scrollToValidation) => ({
  type: VALIDATION_CHANGED,
  validation,
  scrollToValidation,
});

export const saveLongInquiry = (fromQuickAdjust = false) => ({
  type: SAVE_LONG_INQUIRY,
  fromQuickAdjust,
});

export const createLongInquiry = (keyFromParam?: string) => ({
  type: CREATE_LONG_INQUIRY,
  keyFromParam,
});

export const loadLongInquiry = (inputs) => ({
  type: LOAD_LONG_INQUIRY,
  inputs,
});

export const resumeLongInquiry = (inputs, fromPreviousInquiry) => ({
  type: RESUME_LONG_INQUIRY,
  inputs,
  fromPreviousInquiry,
});

export const changeSearchType = (searchType: string) => {
  return {
    type: INQUIRY_SEARCH_TYPE_CHANGED,
    payload: searchType,
  };
};
