export const SUBSCRIBE = 'SUBSCRIBE';

export interface NoResultsSendEmailAction {
  type: string;
  inquiryKey: string;
  email: string;
}

export const onSubscribe = (inquiryKey: string, email: string) => ({
  type: SUBSCRIBE,
  inquiryKey,
  email,
});
