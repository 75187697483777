// import { useTrackSplitFeature } from 'hooks/useTrackSplitFeature';
import React, { memo } from 'react';

export const SplitTracking = memo(function SplitTracking(props: {
  children: React.ReactNode;
}): JSX.Element {
  // useTrackSplitFeature([]);

  return <>{props.children}</>;
});
