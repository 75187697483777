import { NS_GETPREMIUM } from 'utils/namespace-const';
import { InsuranceCompanies } from 'types/insurances';
import { IS_AUTO_INSURANCE } from '../const';
import { StaticTextKey } from './keys';
import autoText from './autoText';
import motoText from './motoText';

const staticText = IS_AUTO_INSURANCE ? autoText : motoText;

export function getStaticText(key: StaticTextKey): string {
  return staticText.get(key);
}

export function getInsuranceAdditionalNote(insuranceName: string): string {
  if (insuranceName?.toLowerCase() === InsuranceCompanies.GENERALI) {
    return `${NS_GETPREMIUM}.insurance-${InsuranceCompanies.GENERALI}.note`;
  }
  return '';
}
