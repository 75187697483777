import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export function WefoxLogo(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1372 853"
      className={`a-icon a-icon--tcs-logo ${props.className || ''}`}
      {...props}
    >
      <path
        d="M846.82 368.7c-40.01 0-71.36 31.59-71.36 71.92s31.34 71.92 71.36 71.92c40.01 0 71.36-31.59 71.36-71.92s-31.35-71.92-71.36-71.92zm0 114.05c-22.94 0-39.58-17.73-39.58-42.17 0-24.43 16.65-42.17 39.58-42.17 22.78 0 39.31 17.73 39.31 42.17s-16.53 42.17-39.31 42.17zM526.11 370.37L490 494.56c-3.56 12.23-12.51 17.93-23.11 17.93-11.11 0-19.43-7.21-22.92-18.02l-22.95-71.28-22.95 71.28c-3.49 10.82-11.81 18.02-22.92 18.02-10.61 0-19.56-5.7-23.11-17.93l-36.11-124.18h34.97l24.44 98.93 23.42-75.79c5.19-16.81 11.08-20.99 22.26-20.99 11.18 0 17.07 4.18 22.26 20.99l23.42 75.79 24.44-98.93h34.97zm43.42 54.02c5.05-15.52 18.63-26 34.15-26 17.66 0 29.3 9.12 32.99 26h-67.14zm101.88 25.42v-9.31c-1.75-43.66-28.33-71.8-67.53-71.8-39.59 0-68.3 30.66-68.3 71.8s30.47 71.99 70.83 71.99c21.73 0 42.69-8.73 56.47-25.03l-21.35-22.12c-9.51 11.45-21.54 17.46-35.12 17.46-19.4 0-34.54-13.39-37.84-32.99h102.84zm65.21-84.51v5.09h31.78v28.85h-31.78v111.7h-32.4v-111.7h-24.53v-28.85h24.53v-9.1c0-31.63 24.99-48.75 48.44-48.75 12.03 0 24.07 3.55 35.02 10.34l-13.73 25.76c-5.09-3.55-11.42-6.33-16.82-6.33-6.16 0-20.51 2.63-20.51 22.99zm255.54 87.62c-9.4 5.24-31.91 32.84-34.29 58.02H925.2c2.11-32.74 26.54-66.2 40.32-74-13.78-8.29-34.4-37.14-37.66-66.56h33.63c.42 22.55 19.86 43.85 30.66 50.67 10.8-6.82 30.24-28.12 30.66-50.67h33.63c-3.26 29.43-23.88 58.27-37.66 66.56 13.78 7.8 38.22 41.26 40.32 74h-32.67c-2.35-25.18-24.86-52.77-34.27-58.02z"
        fill="#501e96"
      />
    </svg>
  );
}

export default WefoxLogo;
