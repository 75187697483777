export const ID_MATCH = 'matchingScore';
export const ID_PRICE = 'premiumTotalForSorting';
export const ID_RATING = 'customerRating';
export const ID_CLAIMS_HANDLING_RATING = 'claimsHandlingRating';
export const ID_COST_BENEFIT_RATIO_RATING = 'costBenefitRatioRating';
export const ID_RECOMMENDATION_RATING = 'recommendationRating';
export const ID_INSURANCE = 'insuranceName';
export const ID_PRODUCT = 'productName';
export const ID_PRODUCT_BUNDLE_ID = 'productBundleId';
export const ID_OFFER_REQUESTED = 'offerRequested';
export const ID_RESPONSE = 'responseId';
export const ID_SERVICE_AND_QUALITY_RATING = 'serviceAndQualityRating';
export const ID_COMMUNICATION_RATING = 'communicationRating';

export const DEVIATION_MATCH = 0;
export const WARNING_DIFFERS = 1;
export const WARNING_INCLUDED = 2;
export const DEVIATION_ERROR = 3;
export const WARNING_AD_HOC = 4;

export enum PRODUCT_BUNDLE_IDS {
  smileBudget = 28,
  smileClever = 29,
  smilePremium = 30,
}

export type SortByNameOptions =
  | 'matchingScore'
  | 'premiumTotalForSorting'
  | 'customerRating';

// Order the items in the way it should be ordered
export const SORT_ITEMS: {
  name: SortByNameOptions;
  orderBy: 'asc' | 'desc';
}[] = [
  {
    name: ID_MATCH,
    orderBy: 'desc',
  },
  {
    name: ID_PRICE,
    orderBy: 'asc',
  },
  {
    name: ID_RATING,
    orderBy: 'desc',
  },
];

export const DEFAULT_SORT_BY = ID_MATCH;
