import { InquiryInputState, Vehicle } from '../InquiryInput/types';
import { RootState } from '../reducers';

export const getVehicleData = (state: RootState): Vehicle => {
  return state.vehicleData.vehicle;
};

export const getInquiry = (state: RootState): InquiryInputState => {
  return state.inquiryInput;
};

export const getInquiryInputs = (state: RootState) => {
  return getInquiry(state).inputs;
};

export const getInquiryValidation = (state: RootState) => {
  return getInquiry(state).validation;
};

export const getIsInquiryPageValid = (state: RootState) => {
  return getInquiry(state).isInquiryPageValid;
};

export const getEuroTaxId = (state: RootState) => {
  return state.vehicleData.vehicle?.eurotaxId;
};

export const getOfferValidation = (state: RootState) => {
  return state.requestOffer.validation;
};

export const getOfferInputs = (state: RootState) => {
  return state.requestOffer.offerRequestInput;
};

export const getPreviousOfferRequest = (state: RootState) => {
  return state.requestOffer.previousOfferRequest;
};
