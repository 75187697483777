import { formatDate } from 'utils/dateformat-const';
import { InsuranceOptions } from '../../state/InquiryInput/types';

export function makeInsuranceOptions(): InsuranceOptions {
  return {
    isLiabilityInsurance: null,
    isPartialCoverInsurance: null,
    partialCoverInsuranceCostSharing: null,
    isFullyComprehensiveInsurance: null,
    fullyComprehensiveInsuranceCostSharing: null,
    isAccidentInsurance: null,
    accidentInsuredPersons: null,
    isAccidentInsuranceMedicalExpenses: null,
    accidentInsuranceSumPayableAtDeath: null,
    accidentInsuranceSumPayableAtDisability: null,
    accidentInsuranceDailyAllowance: null,
    carriedThingsInsuranceSum: null,
    isParkingDamage: null,
    isParkingDamageSumUnlimited: null,
    hasExistingParkingDamage: null,
    isBonusProtection: null,
    insuranceStartDate: null,
    currentInsuranceCompany: null,
    grosslyNegligent: true,
    hasProtectiveClothing: null,
    hasSuspensionRenunciation: null,
    freeGarageChoice: null,
  };
}

export function clearAccidentInsuranceOptions(options: InsuranceOptions): void {
  options.accidentInsuranceSumPayableAtDeath = null;
  options.accidentInsuranceSumPayableAtDisability = null;
  options.accidentInsuranceDailyAllowance = null;
}

export function clearCarriedThingsInsuranceSum(
  options: InsuranceOptions,
): void {
  options.carriedThingsInsuranceSum = null;
}

export function toJSON(options: InsuranceOptions): InsuranceOptions {
  if (options === null || options === undefined) {
    return options;
  }

  return {
    isLiabilityInsurance: options.isLiabilityInsurance,
    isPartialCoverInsurance: options.isPartialCoverInsurance,
    partialCoverInsuranceCostSharing: options.partialCoverInsuranceCostSharing,
    isFullyComprehensiveInsurance: options.isFullyComprehensiveInsurance,
    fullyComprehensiveInsuranceCostSharing:
      options.fullyComprehensiveInsuranceCostSharing,
    isAccidentInsurance: options.isAccidentInsurance,
    accidentInsuredPersons: options.accidentInsuredPersons,
    isAccidentInsuranceMedicalExpenses:
      options.isAccidentInsuranceMedicalExpenses,
    accidentInsuranceSumPayableAtDeath:
      options.accidentInsuranceSumPayableAtDeath,
    accidentInsuranceSumPayableAtDisability:
      options.accidentInsuranceSumPayableAtDisability,
    accidentInsuranceDailyAllowance: options.accidentInsuranceDailyAllowance,
    carriedThingsInsuranceSum: options.carriedThingsInsuranceSum,
    isParkingDamage: options.isParkingDamage,
    isParkingDamageSumUnlimited: options.isParkingDamageSumUnlimited,
    hasExistingParkingDamage: options.hasExistingParkingDamage,
    isBonusProtection: options.isBonusProtection,
    insuranceStartDate: formatDate(options.insuranceStartDate),
    currentInsuranceCompany: options.currentInsuranceCompany,
    grosslyNegligent: options.grosslyNegligent,
    hasProtectiveClothing: options.hasProtectiveClothing,
    hasSuspensionRenunciation: options.hasSuspensionRenunciation,
    freeGarageChoice: options.freeGarageChoice,
  };
}
