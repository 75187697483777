import { DEFAULT_LOOKUP_VALUES } from 'utils/const';
import { LookupItem } from '../../utils/types';
import { FETCH_LOOK_UP_DATA_FULFILLED } from './actions';

export type LookupData = Record<string, LookupItem[]>;

export interface LookUpDataState {
  lookUpData: LookupData;
}

export const initialState: LookUpDataState = {
  lookUpData: DEFAULT_LOOKUP_VALUES,
};

const reducer = (
  state: LookUpDataState = initialState,
  action,
): LookUpDataState => {
  switch (action.type) {
    case FETCH_LOOK_UP_DATA_FULFILLED:
      return {
        lookUpData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
