import { combineReducers } from 'redux';
import insuranceComparisonReducer from 'state/InsuranceComparison/reducer';
import premiumLoaderReducer from 'components/PremiumLoader/state/reducer';
import requestOfferReducer from 'state/RequestOffer/reducer';
import quickAdjustActionsReducer from 'state/QuickAdjustPremiums/reducer';
import mobileFullscreenReducer from 'state/MobileFullscreen/reducer';
import premiumListReducer from 'state/PremiumList/reducer';
import vehicleMakesReducer from 'state/VehicleMakes/reducer';
import loadingScreenReducer from 'state/LoadingScreen/reducer';
import inquiryInputReducer from 'state/InquiryInput/reducer';
import insuranceProvidersReducer from 'state/InsuranceProviders/reducer';
import vehicleDataReducer from 'state/VehicleData/reducer';
import {
  saveInsuranceInquiryReducer,
  fetchInsuranceInquiryReducer,
} from 'state/InsuranceInquiry/reducer';
import fetchLookUpDataReducer from 'state/LookUpData/reducer';
import errorReducer from 'state/Error/reducer';
import adjustingDataReducer from 'state/AdjustingData/reducer';
import featureFlagReducer from 'state/FeatureFlags/reducer';
import directDealReducer from 'state/DirectDeal/reducer';
import alertReducer from 'components/Alert/state/reducer';
import emailReducer from 'components/ShareButton/state/Email/reducer';

const rootReducer = combineReducers({
  alert: alertReducer,
  errors: errorReducer,
  fetchLookUpData: fetchLookUpDataReducer,
  fetchInsuranceInquiry: fetchInsuranceInquiryReducer,
  loadingScreen: loadingScreenReducer,
  insuranceComparison: insuranceComparisonReducer,
  insuranceProviders: insuranceProvidersReducer,
  mobileFullscreen: mobileFullscreenReducer,
  premiumLoader: premiumLoaderReducer,
  requestOffer: requestOfferReducer,
  share: emailReducer,
  saveInsuranceInquiry: saveInsuranceInquiryReducer,
  vehicleData: vehicleDataReducer,
  vehicleMakes: vehicleMakesReducer,
  inquiryInput: inquiryInputReducer,
  adjustingData: adjustingDataReducer,
  featureFlags: featureFlagReducer,
  directDeal: directDealReducer,
  premiumList: premiumListReducer,
  quickAdjust: quickAdjustActionsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
