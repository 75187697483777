// @ts-ignore
import { Engine } from 'json-rules-engine';
import {
  isEmpty,
  isDateNotValid,
  isDateGreater,
  isDateSmaller,
  isEmptyOrNone,
} from 'utils/operators';
import {
  ValidationSuccessFunction,
  ValidationFailureFunction,
  ValidationError,
} from 'utils/validation/types';
import InsuranceOptions from '../types';
import rules from './rules';
import motoRules from './motoRules';

const motoEngine = new Engine();
motoEngine.addOperator('empty', isEmpty);
motoEngine.addOperator('notDate', isDateNotValid);
motoEngine.addOperator('greaterDate', isDateGreater);
motoEngine.addOperator('lessDate', isDateSmaller);
motoEngine.addOperator('emptyOrNone', isEmptyOrNone);

motoEngine.addRule(rules.partialCoverInsuranceCostSharingEmpty);
motoEngine.addRule(rules.fullyComprehensiveInsuranceCostSharingEmpty);

motoEngine.addRule(rules.isBonusProtectionEmpty);

motoEngine.addRule(motoRules.hasProtectiveClothingEmpty);
motoEngine.addRule(motoRules.hasSuspensionRenunciationEmpty);

motoEngine.addRule(rules.carriedThingsInsuranceSumEmpty);
motoEngine.addRule(rules.isParkingDamageEmpty);
motoEngine.addRule(rules.isParkingDamageSumUnlimitedEmpty);

motoEngine.addRule(rules.isAccidentInsuranceEmpty);
motoEngine.addRule(rules.accidentInsuredPersonsEmpty);
motoEngine.addRule(rules.accidentInsuranceSumPayableAtDeathEmpty);
motoEngine.addRule(rules.accidentInsuranceSumPayableAtDisabilityEmpty);
motoEngine.addRule(rules.accidentInsuranceDailyAllowanceEmpty);

motoEngine.addRule(rules.currentInsuranceCompanyEmpty);
motoEngine.addRule(rules.currentInsuranceCompanyNotSelected);

const validateInsuranceOptions = (
  insuranceOptions: InsuranceOptions,
  vehicle: any,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => {
  if (vehicle.dateOfFirstRegistration) {
    motoEngine.addFact(
      'dateOfFirstRegistration',
      vehicle.dateOfFirstRegistration,
    );
  }
  return motoEngine
    .run(insuranceOptions)
    .then((validationErrors: ValidationError[]) => {
      if (validationErrors.length > 0) {
        return failure(validationErrors);
      }
      return success();
    });
};

export default validateInsuranceOptions;
