import { i18n, InitOptions } from 'i18next';
import { AppContext } from 'next/app';
import { WithRouterProps } from 'next/dist/client/with-router';

export interface AppWithTranslationContext extends AppContext {
  ctx: AppContext['ctx'] & {
    req: {
      i18n: i18n;
    };
  };
}

export interface AppWithTranslationProps extends WithRouterProps {
  locale: string;
  initialI18nStore: any;
  i18nServerInstance: any;
}

// Copied from https://github.com/isaachinman/next-i18next/blob/master/src/utils/lngs-to-load.ts
export function getLanguagesToLoad(
  initialLng: string | null,
  fallbackLng: InitOptions['fallbackLng'],
  otherLanguages: string[],
): string[] {
  const languages = new Set<string>();

  if (initialLng) {
    languages.add(initialLng);
  }

  if (fallbackLng) {
    if (typeof fallbackLng === 'string' && fallbackLng !== initialLng) {
      languages.add(fallbackLng);
    }

    if (Array.isArray(fallbackLng)) {
      fallbackLng.forEach((l) => languages.add(l));
    } else if (initialLng && typeof fallbackLng !== 'string') {
      if (typeof fallbackLng[initialLng] === 'string') {
        languages.add(`${fallbackLng[initialLng]}`);
      } else if (Array.isArray(fallbackLng[initialLng])) {
        fallbackLng[initialLng].forEach((l: string) => languages.add(l));
      }
    }
  }

  if (initialLng && initialLng.includes('-') && Array.isArray(otherLanguages)) {
    const [languageFromLocale] = initialLng.split('-');
    otherLanguages.forEach((otherLanguage) => {
      if (otherLanguage === languageFromLocale) {
        languages.add(otherLanguage);
      }
    });
  }

  return Array.from(languages);
}
