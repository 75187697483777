/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/prefer-default-export

export function getGlobal(): any {
  return typeof global !== 'undefined'
    ? global
    : typeof window !== 'undefined'
      ? window
      : {
          location: Location,
        };
}

export interface BrowserDetails {
  language: string;
  userLanguage: any;
  systemLanguage: any;
}

export function getBrowserDetails(): BrowserDetails {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const { language, userLanguage, systemLanguage } = getGlobal().navigator;
  return {
    language,
    userLanguage,
    systemLanguage,
  };
}
