import axios, { AxiosRequestConfig } from 'axios';
import { APPLICATION_HOST } from '../../utils/const';
import { cloudFlareInterceptor } from '../utils/cloudFlareInterceptor';

export const apiBaseURL =
  process.env.NODE_ENV === 'production'
    ? `${APPLICATION_HOST ?? ''}/api/vehicle-service/api/v1`
    : process.env.VEHICLE_API_URL;

const config: AxiosRequestConfig = {
  baseURL: apiBaseURL,
};

if (process.env.NODE_ENV === 'production') {
  config.withCredentials = true;
}

export const insuranceService = axios.create(config);

insuranceService.interceptors.request.use(cloudFlareInterceptor);
