/**
 * This file was autogenerated on 2020-06-12T12:03:44.382Z
 */

export * from './AllianzLogo';
export * from './AxaLogo';
export * from './BaloiseLogo';
export * from './SimpegoLogo';
export * from './ElviaLogo';
export * from './GeneraliLogo';
export * from './HelvetiaLogo';
export * from './MobiLogoDe';
export * from './MobiLogoFr';
export * from './MobiLogoIt';
export * from './SmileLogo';
export * from './ZurichLogo';
export * from './WefoxLogo';
export * from './TcsLogo';
export * from './BelsuraLogo';
export * from './VaudoiseLogo';
