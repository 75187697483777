/* eslint-disable no-param-reassign */
import { isNil } from 'utils';
import { INSURANCE_TYPE } from 'utils/const';
import PARTNER_TYPE from 'models/InsuranceInquiry/partnerType';
import { InsuranceType } from 'utils/types';
import { toJSON as incidentsToJSON } from './Incidents';
import { toJSON as individualToJSON } from './Individual';
import { toJSON as insuranceOptionsToJSON } from './InsuranceOptions';
import { toJSON as usageDetailsToJSON } from './UsageDetails';
import {
  Incidents,
  InsuranceOptions,
  Person,
  UsageDetails,
} from '../../state/InquiryInput/types';

export const FLOW_TYPE_LONG = 'LONG';

export interface InsuranceInquiry {
  id: number;
  key: string;
  language: { key: number; name: string; displayName: string };
  originalInquiryKey: string;
  isQuickAdjust: boolean;
  as24UserId: string;
  flowType: 'LONG';
  insuranceOptions: InsuranceOptions;
  usageDetails: UsageDetails;
  mainDriver: Person;
  policyHolder: Person;
  incidents: Incidents;
  vehicle: any;
  gaClientId: string;
  insuranceType: InsuranceType;
}

function setIsFullyComprehensiveInsurance(
  policyInput,
  isFullyComprehensiveInsurance: boolean,
) {
  if (isFullyComprehensiveInsurance) {
    return {
      ...policyInput,
      isFullyComprehensiveInsurance,
      isPartialCoverInsurance: true,
    };
  }

  return {
    ...policyInput,
    isFullyComprehensiveInsurance,
  };
}

function setLeasing(policyInput, leasing: boolean) {
  if (leasing) {
    return setIsFullyComprehensiveInsurance(policyInput, leasing);
  }

  return policyInput;
}

export function setDriver(inquiryInput: InsuranceInquiry, driver) {
  if (driver) {
    return {
      ...inquiryInput,
      driver,
      insuranceOptions: setLeasing(
        inquiryInput.insuranceOptions,
        driver.leasing,
      ),
    };
  }

  return {
    ...inquiryInput,
    driver,
  };
}

export function makeInsuranceInquiry(): Partial<InsuranceInquiry> {
  return {};
}

export function setUsageDetails(
  inquiry: Partial<InsuranceInquiry>,
  usageDetails: UsageDetails,
): Partial<InsuranceInquiry> {
  if (!isNil(usageDetails)) {
    const next = {
      ...inquiry,
      usageDetails,
    };

    if (usageDetails.leasing) {
      next.insuranceOptions = {
        ...next.insuranceOptions,
        isPartialCoverInsurance: true,
        isFullyComprehensiveInsurance: true,
      };
    }

    return next;
  }

  return inquiry;
}

export function clearPolicyHolder(inquiry: InsuranceInquiry): void {
  delete inquiry.policyHolder;
}

export function clearOriginalInquiryKey(inquiry: InsuranceInquiry): void {
  delete inquiry.originalInquiryKey;
}

export function clearKey(inquiry: InsuranceInquiry): void {
  delete inquiry.key;
}

export function toJSON(inquiry: InsuranceInquiry) {
  if (isNil(inquiry)) {
    return inquiry;
  }

  return {
    key: inquiry.key,
    language: inquiry.language,
    vehicle: {
      ...inquiry.vehicle,
      licensePlateNumber: inquiry.vehicle?.licensePlateNumber?.replace(
        /\s+/g,
        '',
      ),
    },
    usageDetails: usageDetailsToJSON(inquiry.usageDetails),
    mainDriver: individualToJSON(inquiry.mainDriver),
    policyHolder: individualToJSON(inquiry.policyHolder),
    insuranceOptions: insuranceOptionsToJSON(inquiry.insuranceOptions),
    incidents: incidentsToJSON(inquiry.incidents),
    originalInquiryKey: inquiry.originalInquiryKey,
    isQuickAdjust: inquiry.isQuickAdjust,
    flowType: FLOW_TYPE_LONG,
    insuranceType: INSURANCE_TYPE,
    gaClientId: inquiry.gaClientId,
    as24UserId: inquiry.as24UserId,
    partnerType: PARTNER_TYPE,
  };
}
