import { ReactNode, useCallback } from 'react';
import { FooterWidget, HeaderWidget } from '@scout24ch/fs24-widgets';
import { SingletonRouter, withRouter } from 'next/router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fs24 } from '@scout24ch/fs24-design-system';
import { useAuth0 } from '@scout24ch/fs24-auth-react';
import { updateLanguagePath } from 'utils/navigationUtil';
import { getLanguages } from 'utils/language';
import { CMS_URL } from 'utils/const';
import { LoadingScreen } from 'components/Loader';

interface AppProps {
  router: SingletonRouter;
  loadingScreenVisible?: boolean;
  children: ReactNode;
  mobileFullscreen?: boolean;
  modalOpen?: boolean;
}

function App(props: AppProps) {
  const {
    router,
    loadingScreenVisible,
    children,
    mobileFullscreen,
    modalOpen,
  } = props;
  const { i18n } = useTranslation();

  const updateLanguage = (targetLanguageCode = 'de') => {
    updateLanguagePath(router, targetLanguageCode);
    i18n.changeLanguage(targetLanguageCode);
  };

  const isModalOpenAndMobile = mobileFullscreen && modalOpen;

  const { user, loginWithRedirect, logout, getAccessTokenSilently } =
    useAuth0();

  const login = useCallback(() => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
  }, [loginWithRedirect]);

  const signup = useCallback(() => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
      authorizationParams: { screen_hint: 'signup' },
    });
  }, [loginWithRedirect]);

  const logoutTo = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const noScrollStyle = {
    overflow: 'hidden',
    position: 'fixed',
    inset: 0,
  };

  return (
    <fs24.div sx={loadingScreenVisible ? noScrollStyle : undefined}>
      {!isModalOpenAndMobile && (
        <HeaderWidget
          logoHref={`${CMS_URL}/${i18n.language}`}
          availableLocales={getLanguages()}
          onLanguageClick={(languageItem) => {
            updateLanguage(languageItem.shortCode);
          }}
          user={user}
          getAccessToken={getAccessTokenSilently}
          onLogin={login}
          onSignup={signup}
          onLogout={logoutTo}
        />
      )}
      {children}
      {!loadingScreenVisible && !isModalOpenAndMobile && <FooterWidget />}
      <div id="adorner-portal-container" />
      <LoadingScreen />
    </fs24.div>
  );
}

function mapStateToProps({ loadingScreen, mobileFullscreen }) {
  return {
    ...loadingScreen,
    mobileFullscreen: mobileFullscreen.mobileFullscreen,
    modalOpen: mobileFullscreen.modalOpen,
  };
}

const connectRedux = connect(mapStateToProps);

export default withRouter(connectRedux(App));
