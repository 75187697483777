// @ts-ignore
import { Engine } from 'json-rules-engine';
import moment from 'moment';
import {
  isEmpty,
  isNotEmpty,
  isDateNotValid,
  isDateGreater,
  isDateLess18YearsSmaller,
  isDateSmaller,
  isEmailInvalid,
} from 'utils/operators';

import {
  ValidationSuccessFunction,
  ValidationError,
  ValidationFailureFunction,
} from 'utils/validation/types';
import rules from './rules';
import {
  InsuranceOptions,
  Person,
} from '../../../../../state/InquiryInput/types';
import { validateStartInsuranceDate } from './validateStartInsuranceDate';

const engine = new Engine();
engine.addOperator('empty', isEmpty);
engine.addOperator('notEmpty', isNotEmpty);
engine.addOperator('notDate', isDateNotValid);
engine.addOperator('greaterDate', isDateGreater);
engine.addOperator('lessDate', isDateLess18YearsSmaller);
engine.addOperator('smallerDate', isDateSmaller);
engine.addOperator('emailInvalid', isEmailInvalid);

engine.addFact('today', new Date());
engine.addFact('todayLess18Years', moment().add(-18, 'y').toDate());
engine.addFact('todayLess100Years', moment().add(-100, 'y').toDate());

engine.addRule(rules.genderEmpty);
engine.addRule(rules.dateOfBirthInvalid);
engine.addRule(rules.dateOfBirthGreaterThan);
engine.addRule(rules.dateOfBirthLesserThan);
engine.addRule(rules.dateOfDrivingLicenseInvalid);
engine.addRule(rules.dateOfDrivingLicenseGreaterThan);
engine.addRule(rules.dateOfDrivingLicenseLessThan);
engine.addRule(rules.postCodeInvalid);
engine.addRule(rules.nationalityEmpty);
engine.addRule(rules.residencePermitEmpty);
engine.addRule(rules.residencePermitSinceInvalid);
engine.addRule(rules.residencePermitSinceGreaterThan);
engine.addRule(rules.residencePermitSinceLessThan);
engine.addRule(rules.mainDriverEmailMandatoryInvalid);
engine.addRule(rules.policyHolderEmailInvalid);

engine.addRule(rules.isMainDriverEmpty);
engine.addRule(rules.driverDateOfBirthInvalid);
engine.addRule(rules.driverDateOfBirthGreaterThan);
engine.addRule(rules.driverDateOfBirthLesserThan);
engine.addRule(rules.driverGenderEmpty);
engine.addRule(rules.driverPostCodeInvalid);
engine.addRule(rules.driverNationalityEmpty);
engine.addRule(rules.driverResidencePermitEmpty);
engine.addRule(rules.driverResidencePermitSinceInvalid);
engine.addRule(rules.driverResidencePermitSinceGreaterThan);
engine.addRule(rules.driverResidencePermitSinceLessThan);

engine.addRule(rules.otherDriversEmpty);
engine.addRule(rules.driversOutsideTheHouseholdEmpty);
engine.addRule(rules.driversWithLearningOrTrialLicenseEmpty);
engine.addRule(rules.driversUnderTheAgeOf25Empty);
engine.addRule(rules.cityInvalid);
engine.addRule(rules.cantonInvalid);

const validateDriver = (
  mainDriver: Person,
  policyHolder: Person,
  insuranceOptions: InsuranceOptions,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => {
  if (!policyHolder) {
    policyHolder = {} as Person;
  }

  return engine
    .run({
      mainDriver,
      policyHolder,
    })
    .then((validationErrors: ValidationError[]) => {
      const insuranceDateErrors = validateStartInsuranceDate(
        insuranceOptions.insuranceStartDate,
      );

      const errors = [...validationErrors, ...insuranceDateErrors];

      if (errors.length > 0) {
        return failure(errors);
      }
      return success();
    });
};

export default validateDriver;
