import moment, { utc } from 'moment';

export const DATE_FORMAT_DAY = 'YYYY-MM-DD';
const DATE_FORMAT_DAY_DISPLAY = 'DD.MM.YYYY';
const DATE_FORMAT_DAY_DISPLAY_SINGLE_DIGIT = 'D.M.YYYY';
const DATE_FORMAT_MONTH_YEAR = 'MM.YYYY';
const DATE_FORMAT_DAY_BACKEND = moment.ISO_8601;

export const convertDateValueToString = (value: string | Date) => {
  if (value == null) {
    return '';
  }
  if (typeof value !== 'string') {
    return value.toISOString().split('T')[0];
  }
  return value.split('T')[0];
};

export const isDateValid = (date: string) => {
  return moment(new Date(date), DATE_FORMAT_DAY_DISPLAY, true).isValid();
};

export const isValidDateInput = (value) => {
  return !Number.isNaN(Number(value.replace(/-/g, '')));
};

export const asDisplayDate = (value) =>
  moment(value).format(DATE_FORMAT_DAY_DISPLAY);

export const asMonthYear = (value) =>
  moment(value).format(DATE_FORMAT_MONTH_YEAR);

const convertToDate = (value, format) => {
  const convertedDate = utc(value, format, true);
  if (convertedDate.isValid()) {
    return convertedDate.toDate();
  }
  return null;
};

const convertToDateWithFormats = (value, ...formats) =>
  formats.map((format) => convertToDate(value, format)).find((s) => s !== null);

export const fromInput = (value) => {
  const result = convertToDateWithFormats(
    value,
    DATE_FORMAT_DAY_DISPLAY,
    DATE_FORMAT_DAY_DISPLAY_SINGLE_DIGIT,
    DATE_FORMAT_DAY_BACKEND,
    DATE_FORMAT_DAY,
  );

  return result !== undefined ? result : value;
};

export const formatDate = (date) =>
  date ? utc(fromInput(date)).format(DATE_FORMAT_DAY) : null;

export function isCorrectDateFormat(value: unknown): boolean {
  if (typeof value !== 'string') {
    return false;
  }

  return /\d{4}-\d{2}-\d{2}/.test(value);
}
