export const FETCH_VEHICLE_FULFILLED = 'FETCH_VEHICLE_FULFILLED';
export const FETCH_VEHICLE_FAILED = 'FETCH_VEHICLE_FAILED';
export const CLEAR_VEHICLE = 'CLEAR_VEHICLE';

export const fetchVehicleFulfilled = (payload) => ({
  type: FETCH_VEHICLE_FULFILLED,
  payload,
});

export const fetchVehicleFailed = (payload) => ({
  type: FETCH_VEHICLE_FAILED,
  payload,
});

export const clearVehicle = () => ({
  type: CLEAR_VEHICLE,
});
