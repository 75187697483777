import {
  InsuranceOptions,
  Person,
} from '../../../../../state/InquiryInput/types';
import {
  ValidationFailureFunction,
  ValidationSuccessFunction,
} from '../../../../../utils/validation/types';
import MotoDriverValidation from './motoDriverValidation';
import DriverValidation from './ValidateDriver';

type ValidationFunction = (
  mainDriver: Person,
  policyHolder: Person,
  insuranceOptions: InsuranceOptions,
  success: ValidationSuccessFunction,
  failure: ValidationFailureFunction,
) => void;

const createDriverValidation = (
  isAutoInsurance: boolean,
): ValidationFunction =>
  isAutoInsurance ? DriverValidation : MotoDriverValidation;

export default createDriverValidation;
