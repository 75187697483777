import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  fetchVehicleMakesFailed,
  fetchVehicleMakesFulfilled,
  FETCH_VEHICLE_MAKES,
} from './actions';
import { getVehicleMakes } from '../../api/vehicles';

export const fetchVehicleMakesEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_VEHICLE_MAKES),
    mergeMap(() =>
      from(getVehicleMakes()).pipe(
        map(fetchVehicleMakesFulfilled),
        catchError((e) => of(fetchVehicleMakesFailed(e))),
      ),
    ),
  );
